.tagSelectorContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.tagSelectorContainer .tagBlock {
    flex: 0 0 auto;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Filson Pro Regular';
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
:global(.tagSelectorContainer .tagBlock.selected) {
    border: 1px solid rgb(70, 29, 88);
    background-color: rgb(70, 29, 88);
    color: white;
}
:global(.dark .tagSelectorContainer .tagBlock.selected) {
    background-color: #253DE4;
}