.topContainer {
    background-image: url("./imgs/top_bg.svg");
    height: 380px;
    width: 100%;
    background-size: cover;
}


@media only screen and (max-width: 600px) {
    .topContainer {
        background-image: url("./imgs/top_mobile_bg.svg");
        height: 457px;
        width: 343px;
    }
}

@media only screen and (max-width: 425px) {
    .topContainer {
        background-image: url("./imgs/top_mobile_bg.svg");
        height: 457px;
        width: 343px;
        background-size: cover;
    }
}