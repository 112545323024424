.pageTop {
  background-image: url('./imgs/hero_bg.svg');
  background-size: cover;
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: space-between;
  padding: 0 175px;
}

/* TOP LEFT HERO STYLES */

.topLeft {
  position: relative;
  top: -75px;
}

.topLeftConfirmation {
  position: relative;
  top: -50px;
  width: 100%;
}

.topLeft > .goBack {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin-bottom: 34px;
}

.topLeft > .goBack > img {
  position: absolute;
  top: -2px;
  left: -30px;
}

.topLeft > h1 {
  font-size: 70px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  color: #fffdfb;
  margin: 0;
  margin-bottom: 20px;
}

.topLeft > p {
  width: 70%;
  font-size: 24px;
  margin: 0;
  font-family: 'Filson Pro Regular';
  line-height: 1.17;
  color: #ffc0e5;
}

.topLeftConfirmation > div {
  display: flex;
  align-items: center;
}

.topLeftConfirmation > div > .heroButtonContainer {
  width: 130px;
  height: 48px;
  margin-top: 34px;
}

.topLeftConfirmation > div > p {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin: 0;
  position: relative;
  left: 36px;
  top: 20px;
  cursor: pointer;
}

/* TOP RIGHT HERO STYLES */

.topRight > img {
  height: 500px;
  position: relative;
  top: 2px;
}

.topRightConfirmation {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.topRightConfirmation > img {
  height: 350px;
}

/* BOTTOM STYLES */

.pageBottom {
  height: 80vh;
  background-image: url('./imgs/body_bg.svg');
  background-size: cover;
  margin-bottom: -200px;
  display: flex;
  padding: 0 172px;
}

.pageBottom > div {
  width: 50%;
  margin-top: 65px;
}

/* CONTENT STYLES */

.loyaltyContent > h3 {
  font-family: 'Congenial Black';
  font-size: 42px;
  line-height: 1.14;
  color: #461d58;
  margin: 0;
  margin-bottom: 16px;
}

.loyaltyContent > p, .loyaltyContent > div > p:last-of-type {
  font-family: 'Filson Pro Regular';
  font-size: 18px;
  line-height: 1.33;
  color: #966826;
}

.loyaltyContent > div > p:first-of-type {
  font-family: 'Filson Pro Bold';
  font-size: 24px;
  line-height: 1.17;
  color: #461d58;
  margin-bottom: 5px;
}

.loyaltyContent > div > p:last-of-type {
  margin: 0;
}

.actionsContainer {
  display: flex;
  align-items: center;
  margin-top: 27px;
}

.customButtonContainer {
  width: 100px !important;
  height: 44px !important;
  margin-right: 24px;
}

.actionsContainer > a {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  line-height: 1.33;
  color: #461d58;
  text-decoration: none;
}

@media (max-width: 1400px) {
  .pageTop {
    padding: 0 120px;
  }
}

@media (max-width: 1150px) {
  .pageTop {
    padding: 0 70px;
  }
  .pageBottom {
    padding: 0 50px;
  }
}

@media (max-width: 1000px) {
  .pageTop {
    padding: 0 50px;
  }
}

@media(max-height: 720px) {
  .pageBottom {
    margin-bottom: 0;
  }
}

@media (max-width: 910px) {
  .pageTop {
    position: relative;
    top: -25px;
    padding: 0 26px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
  }

  /* TOP LEFT SYLES */
  .topLeft {
    top: 25px;
  }

  .topLeft > h1 {
    font-size: 50px;
  }

  /* TOP RIGHT STYLES */
  .topRight > img {
    height: 400px;
  }
  .topRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  /* BOTTOM STYLES */
  .pageBottom {
    height: 80vh;
    flex-direction: column;
    padding: 26px 28px;
  }
  
  .pageBottom > div {
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 350px) {
  .pageTop {
    top: -38px;
  }
}