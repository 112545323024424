.pageWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 100px;
}

.pageContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

.pageContainer > h1, .pageContainer > p {

}

.pageContainer > h1 {
  font-size: 42px;
  font-family: 'Congenial Black';
  margin: 0;
  margin-bottom: 20px;
}

.pageContainer > p {
  font-family: 'Filson Pro Bold'
}

.loaderWrapper {
  height: 50vh;
  width: 100%;
}

.errorContainer {
  background-color: #fffdfb;
  border-radius: 20px;
  box-shadow:  10px 10px 0 1px #bc945a;
  border: 1px solid #ba935a;
  width: 100%;
  display: block !important;
  margin: 20px 0;
  padding: 30px;
  padding-bottom: 70px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.errorContainer > p {
  color: #ba935a;
  margin: 0;
  margin-bottom: 5px;
}

.errorContainer > p:first-of-type {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
}

.errorContainer > p:last-of-type {
  font-family: 'Filson Pro Regular';
  line-height: 24px;
}

.errorContainer > span {
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: #ce4396;
  text-decoration: underline;
  font-family: 'Filson Pro Bold';
}

@media (max-width: 1000px) {
  .pageContainer {
    width: 90%;
  }

  .pageContainer > h1 {
    font-size: 36px;
  }
}

@media (max-width: 500px) {
  .errorContainer > p:last-of-type {
    font-family: 'Filson Pro Regular';
    line-height: 24px;
    width: 80%;
  }
}

.seeMoreContainer {
  display: flex;
  justify-content: center;
}

.seeMoreButton {
  padding: 0 2rem !important;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.paginationContainer button {
  width: auto;
  padding: 0;
  background: transparent;

  color: #983992;

  font-family: 'Filson Pro Bold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.paginationContainer button.activePaginationButton {
  color: #B4A5BB !important;
  cursor: pointer;
}
