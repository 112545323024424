.loaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderWrapper > img {
  height: 50px;
  animation: rotate 30s infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(3600deg);
  }
}

@media (max-width: 500px) {
  .loaderWrapper {
    height: 50%;
  }
}