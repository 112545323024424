.deliveryLocationsContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.footerDeliveryLocationsContainer {
  padding: 10px 35px;
}

.deliveryLocationsContainer:hover, .deliveryLocationsSelected {
  /* background-color: #F7EFE4 !important; */
}

.deliveryLocationsContainer > img  {
  position: relative;
  height: 23px;
  right: 15px;
  top:0;
  visibility: hidden;
}

.deliveryLocationsContainer:hover > img {
  visibility: visible;
}

.deliveryLocationsContainer > div > p, .deliveryLocationsContainer > div > span {
  margin: 0;
  font-family: 'Filson Pro Regular';
}

.deliveryLocationsContainer > div > p {
  font-size: 16px;
  /* color: #461d58 */
}

.deliveryLocationsContainer > div > span {
  /* color: #966826; */
  font-size: 12px;
}