.modalWrapper {
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.4)
}

.modalContainer {
  width: 500px;
  position: relative;
  background-color: #FFF;
  overflow: scroll;
}

.modalContainer > img {
  cursor: pointer;;
  width: 100%;
}

.modalContent {
  padding: 10px 20px;
}

.detailsContainer {
  display: flex;
  justify-content: space-evenly;
}

.detailsContainer > div {
  margin-right: 30px;
}

.detailsContainer > div, .FAQContainer {
  border-top: 1px solid #E5E5E5;
  width: 50%;
  height: 150px;
}

.FAQContainer {
  width: 47%;
}

.detailsContainer > div:last-child {
  margin-right: 0px;
}
.productContainer, .detailsContainer, .FAQContainer {
  height: 100px;
}

.orderContainer, .quantityContainer, .orderButton {
  display: flex;
}

.quantityContainer {
  margin-left: 25px;
  font-size: 12px;
}

.quantityContainer > p:first-child {
  margin-right: 30px;
  font-weight: bold;
} 

.orderContainer {
  border-top: 1px solid #E5E5E5; 
  background-color: white;
  z-index: 999999999;
  width: 100%;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

.orderButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #FFF;
  font-weight: bold;
  background-color: #412C8A;
  font-size: 12px;
  height: 30px;
  width: 200px;
  margin-right: 25px;
};
