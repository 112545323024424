.headerLi::marker {
  font-size: 2em;
}

.underlineText {
  text-decoration: underline;
}

.table_header {
  display: flex;
}

.table_header_item:first-child {
  width: 30%;
}

.table_header_item {
  border: 1px solid black;
  padding: 0.5em;
  width: 70%;
}

.table_body_item:first-child {
  width: 30%;
}

.table_body {
  display: flex;
}

.table_body_item {
  border: 1px solid black;
  padding: 0.5em;
  width: 70%;
}

.pageWrapper {
  display: flex;
  justify-content: center;
}

.pageContainer {
  width: 55%;
  z-index: 1;
  /* color: #fffdfb; */
  margin-top: 40px;
}

.pageSection {
  margin-bottom: 60px;
}

.pageSection>h1,
.pageSection>h3 {
  font-family: 'Congenial Black';
  margin-bottom: 20px;
}

.pageSection>h1 {
  font-size: 72px;
}

.pageSection>h3 {
  font-size: 35px;
}

.pageSection>p,
.pageSection>li {
  font-size: 23px;
  line-height: 30px;
  margin: 0;
  font-family: 'Filson Pro Regular'
}

.pageSection>ol>li>h1,
.pageSection>ol>li>h3 {
  line-height: 55px !important;
}

.pageSection>ol>li>h1 {
  font-weight: bold;
  margin-top: 10px !important;
}

.pageSection>ol>li>h3 {
  font-weight: 600;
}

.pageSection>ol>li>ul {
  margin-bottom: 10px;
}

.pageSection>ol>li>ul>li {
  list-style-type: circle !important;
  margin-left: 55px !important;
  display: list-item !important;
}

.pageSection>ol>li>table>tbody>tr>td {
  border: 1px solid #7F6E89 !important;
}

.pageSection>ol>li>table>tbody>tr>td>p {
  font-family: 'Filson Pro Regular' !important;
  color: #461D58 !important;
}

@media (max-width: 1200px) {
  .pageContainer {
    width: 80%;
  }
}

@media (max-width: 700px) {

  .pageContainer {
    width: 90%;
  }

  .pageSection>h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .pageSection>p,
  .pageSection>li {
    font-size: 20px;
    line-height: 25px;
  }

}
