.pageWrapper {
  display: flex;
  justify-content: center;
}

.pageContainer {
  width: 55%;
  z-index: 1;
  margin-top: 40px;
}

.pageSection {
  margin-bottom: 60px;
}

.pageSection > h1, .pageSection > h3 {
  font-family: 'Congenial Black';
  margin-bottom: 20px;
}

.pageSection > h1 {
  font-size: 72px;
}

.pageSection > h3 {
  font-size: 35px;
}

.pageSection > p {
  font-size: 23px;
  line-height: 30px;
  margin: 0;
  font-family: 'Filson Pro Regular'
}


.pageSection > p > p {
  margin-bottom: 20px;
}
.pageSection > p > p > a {
  color: #461D58;
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .pageContainer {
    width: 80%;
  }
}



@media (max-width: 700px) {

  .pageContainer {
    width: 90%;
  }

  .pageSection > h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .pageSection > p {
    font-size: 20px;
    line-height: 25px;;
  }

}
