.modalWrapper {
  /* z-index: 99999999999999;
  margin: 0;
  padding: 0;
  background-color: rgba(26,0,3,0.5);
  position: fixed;
  height: 100%;
  width: 100%; */
}

.modalContainer {
  /* position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* background-color: #fffdfb;
  box-shadow:  8px 5px 0 1px #bc945a; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.modalContainer > div {
  /* max-width: 100%;
  width: 100%; */
}

.modalContainer > a {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.defaultModal {
  padding: 20px 20px 20px 20px;
}

.defaultModal > h3 {
  font-family: 'Filson Pro Bold';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.defaultModal > p {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 16px;
  line-height: 20px;
}

.defaultModal > div {
  width: 100%;
  /* display: flex;
  justify-content: flex-end;
  position: relative;
  right: -65px; */
}

.defaultModal > div > button {
  margin-top: 50px;
  margin-left: 10px;
  width: auto !important;
  padding: 10px 14px;
  font-size: 16px;
  font-family: 'Filson Pro Bold';
  line-height: 20px;
}

.lightAction {

}

/* @media (max-width: 700px) {
  .modalContainer {
    width: 85%;
    height: auto;
    margin: 0 auto;
  }

  .modalContainer > div {
    max-width: 85%;
  }

  .defaultModal > div {
    right: -40px;
  }

  .defaultModal {
    padding-right: 60px;
    padding-left: 25px;
  }

  .defaultModal > div {
    display: flex;
    flex-direction: column;
  }

  .defaultModal > div > button {
    margin-top: 20px;
    padding: 10px 15px;
  }

  .defaultModal > div > button:focus {
    outline: none;
  }
}

@media (max-width: 400px) {
  .defaultModal {
    padding-right: 20px;
    padding-left: 20px;
  }
  .defaultModal > div {
    right: 0;
  }

  .modalContainer {
    width: 90%;
    top: 50%
  }
} */

.simpleModalContainer {
  width: 100%;
  padding: 0 20px;
}
.closeWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40px;
  flex: 1;
}

.closeWrapper > img {
  cursor: pointer;
}

.rateModalTitle {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Filson Pro Bold';
}

.rateModalSubTitle {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Filson Pro Regular';
}
