.customOptionWrapper {
  margin-right: 54px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.w_50{
  
}
.selectorContainer{
  width: 100%;
}

.selectorContainer  .selectorName{
  width: calc(100% - 35px)!important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.selectorContainer  .selectorNameWithPrice{
  width: calc(100% - 105px)!important;
}
/* SHARED STYLES */

.quantityWrapper, .singleWrapper {
  width: 160px;
  height: 195px;
  border-radius: 22px;
  border: solid 1px #983992;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.quantityWrapper > div > img, .quantityWrapper > h3, .quantityContainer,
.singleWrapper > div > img, .singleWrapper > h3, .singleContainer {
  
}

.quantityWrapper > div > img, .singleWrapper > div > img  {

}

.quantityWrapper > h3, .singleWrapper > h3 {
  width: 80%;
  left: 20px;
  top: 76px;
  align-self: center;
  position: absolute;
}

.quantityWrapper, .quantityContainer, .quantityContainer > div,
.singleWrapper, .singleContainer, .singleContainer > div {

  position: relative;
}

/* QUANTITY TYPE STYLES */

.quantityContainer {
  display: flex;
  align-items: center;
}

.quantityContainer > div {
  cursor: pointer;
  font-size: 30px;
  text-align: center;
}

.quantityContainer > span {
  font-size: 24px;
  margin: 0 16px;
}

/* SINGLE STYLES */

.singleContainer > div {
  cursor: pointer;
}

.singleContainer > div > img {
}

/* SELECTOR STYLES */

.selectorWrapper {
  display: flex;
  align-items: center;
}

.selectorWrapper > div {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: solid 1px #d5b88a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.selectorWrapper > div > img {
  height: 8px;
}

.selectorWrapper > p {
  line-height: 1.33;
}

/* ACTION STYLES */

.quantityContainerActive {
  border: solid 2px #5e2975;
}

.quantityContainerActive > .quantityContainer > div, .selectorSelected, .singleContainerSelected > div {
  background-color: #f3c244;
  border: solid 2px #f3c244;
}


.singleContainerSelected > div > img {
  opacity: 1;
}

.caloriesDisplay {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  color: #966826;
}

.caloriesQuantity {
  font-size: 14px;
  color: #966826;
}

.noImagePlaceHolderSimple{
  display: flex;
}

.noImagePlaceHolderSimple > div {
  margin-left: -20px;
  margin-top: 20px;
  display: none;
}

.renderPrice {
  display: block;
}

@media (min-width: 600px) {
  .noImagePlaceHolderSimple > div {
    display: block;
  }

  .renderPrice {
    display: none;
  }
 
}


@media (max-width: 600px) {

  .w_50{
    width: 100%!important;
  }

  .selectorContainer{
    width: 100%!important;
    margin-right: 0;
  }
  .customOptionWrapper {
    margin-right: 0;
    width: 100%;
  }

  /* SHARED STYLES */
  .quantityWrapper, .singleWrapper {
    width: 100%;
    height: auto;
    border: none;
    flex-direction: row;
  }

  .quantityWrapper > div > img, .singleWrapper > div > img  {
    right: 30px;
  }

  .quantityWrapper > h3,  .singleWrapper > h3 {

  }

  .quantityContainer, .singleContainer {
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    left: 0;
  }

  .quantityWrapper > div > img, .singleWrapper > div > img  {
    top: 0;
    right: 0;
  }

  .quantityWrapper > h3, .singleWrapper > h3 {
    margin-left: 9px;
    left: 0px;
    top: 0px;
    align-self: center;
    position: relative;
  }

  .caloriesQuantity {
    width: 100%;
    color: #966826;
  }

  /* SINGLE STYLES */
  .singleContainer > div {
    left: 0;
    top: 0;
  }

}

@media (max-width: 400px) {
  .quantityWrapper > h3, .singleWrapper > h3 {

  }
  .quantityContainer > div {
    width: 38px;
    height: 38px;
    font-size: 26px;
  }
}

@media (max-width: 350px) {
  .quantityWrapper > div > img, .singleWrapper > div >  img  {
    top: 0px;
    right: 0px;
  }

  .quantityContainer > div {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}

.removePadding{
  margin: 0px;
}
.removeTopPadding{
  margin-top: 0px;
}

.addPaddingbotton{
  margin-bottom: 45px;
}

.topMargin0 {
}

.bottomMargin0 {
  margin-bottom: 0px
}

.bottomMargin45 {
}

.bottomMargin15 {
}

.price {
  white-space: nowrap;
  font-family: 'Filson Pro Regular';
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
}
.oos {
  opacity: 0.5;
  filter: grayscale(100%);
}