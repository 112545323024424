.bannerBackground {
    background-image: url("./imgs/banner_bg.png");
    height: 384px;
    width: 1440px;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .bannerBackground {
        background-image: url("./imgs/mobile_banner_bg.png");
        height: 384px;
        width: 1440px;
        background-size: cover;
    }
}

@media only screen and (max-width: 425px) {
    .bannerBackground {
        background-image: url("./imgs/mobile_banner_bg.png");
        height: 384px;
        width: 1440px;
        background-size: cover;
    }
}