.listContainer {
    width: 100%;
    font-family: 'Filson Pro Regular';
}

.listContainer .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6DFE8;
    cursor: pointer;
}

.listContainer .item .title {
    padding: 20px 0;
    font-size: 14px;
    line-height: 16px;
    flex: 1;
}

.listContainer .item:hover .title {
    text-decoration: underline;
}
