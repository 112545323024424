
.sectionHeader,
.sectionSubHeader {
  /* color: #461d58; */
}

.sectionHeader {
  /* font-size: 18px;
  margin: 0;
  margin-bottom: 13px;
  line-height: 1.33; */
}

.selectionButton {
  width: 100%;
  margin: 64px 0 15px;
}

.dropDownRow {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.dropDownRow > div:first-of-type {
  margin-right: 24px;
}
.dropDownRow > div:first-of-type {
  margin-bottom: 10px;
}

.placeholderText11 > div > div {font-size: 11px !important;}

.errorMessage {
  color: #d21c38;
  font-family: 'Filson Pro Bold';
  line-height: 1.4;
}


@media (max-width: 1900px) {
    .dropDownRow > div:first-of-type {
        margin-right: 0;
    }
    .dropDownRow {
        flex-wrap: wrap;
    }
}

@media (max-width: 900px) {
    .dropDownRow > div:first-of-type {
        margin-right: 24px;
    }
    .dropDownRow {
        flex-wrap: nowrap;
    }
}

@media (max-width: 600px) {
    .dropDownRow > div:first-of-type {
        margin-right: 0;
    }
    .dropDownRow {
        flex-wrap: wrap;
    }
}