.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageContainer {
  z-index: 1;
  color: #fffdfb;
  margin-top: 40px;
}

.pageContainer > h1 {
  font-size: 60px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  margin: 0;
  margin-bottom: 50px;
}

.pageContainer > p {
  font-family: 'Filson Pro Bold';
  color: #ce4396;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: -30px;
}

.buttonContainer {
  width: 100% !important;
  height: 44px !important;
  margin-top: 60px !important; 
}

.buttonContainer > p {
  margin: 0 
}

/* CONDITIONAL STYLES */

.lightDark {
  color: #fffdfb;
}

.isLight {
  color: #461d58;
}

@media (max-width: 500px) {
  .pageWrapper {
    justify-content: flex-start;
    padding: 0 30px;
  }

  .pageContainer {
    width: 100%;
  }

  .pageContainer > h1 {
    font-size: 42px;
  }

  .buttonContainer {
    width: 100% !important;
  }
}
