.dropdown {
    background-color: transparent !important;
    outline: none !important;
    border: 0px;
}

.dropdown > div {
    background-color: transparent;
    border: 0px;
    color: white
}