.noStoresFound {
  font-family: 'Filson Pro Bold';
  padding: 0 30px;
}

.pageWrapper {
  height: 86vh;
  width: 100%;
  display: flex;
}

.exitIcon {
  /* z-index: 100000; */
  cursor: pointer;
  height: 15px;
  position: absolute;
  right: 25px;
  top: 20px;
}

.locationsContainerMobile, .locationContainer {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.locationsContainer {
  position: relative;
  width: 50%;
}

/* MAP STYLES */

.mapContainer {
  width: 50%;
  position: relative;
}

.mapContainer > img {
  position: absolute;
  top: 30px;
  left: 23px;
  cursor: pointer;
  display: none
}

.locationsContainer {
  /* background-color: #fffdfb; */
  padding: 50px 70px;
  overflow: scroll;
}

.locationsContainerMobile {
  display: none;
  /* background-color: #fffdfb; */
}

.locationsContainer > h1 {
  /* margin: 0; */
  font-family: 'Congenial Black';
  font-size: 36px;
  line-height: 36px;
  letter-spacing: -1px;
  /* color: #461d58; */
  /* margin-bottom: 35px; */
}

.locationsInputWrapper {
  display: flex;
  align-items: center;
}

.locationsInputWrapper > .locationInputContainer {
  width: 95%;
  /* border: 1px solid #d5b88a; */
  border-radius: 48px;
  display: flex;
  /* background-color: #fffdfb; */
  margin-right: 20px;
}

.needContactLink {
  /* color: #966826; */
}
.locationsInputWrapper > img, .locationsInputWrapper > div > img {
  height: 20px;
  align-self: center;
}

.locationsInputWrapper > img {
  cursor: pointer;
}

.locationsInputWrapper > div > .clearIcon {
  height: 13px;
  cursor: pointer;
}

.locationsInputWrapper > div {
  height: 44px;
  padding: 0 15px 0 20px;
  align-items: center;
}

.locationsInputWrapper > div > input {
  border: none;
  width: 95%;
  padding-left: 20px;
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  background-color: inherit;
  font-size: 16px;
  line-height: 20px;
  border-radius: inherit;
}

.locationsInputWrapper > div > input::placeholder {
  font-size: 16px;
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  line-height: 20px;
}

.locationsInputWrapper > div > input:focus {
  outline: none;
}

.locationsList {
  margin-top: 40px;
  height: 63vh;
  overflow-y: scroll;
}

.locationContainer {
  height: 65vh;
}

.dayName, .hoursRange {
  display: table-cell;
  text-align: right;
}

.locationContainer, .locationHero {
  position: relative;
}

.customButtonContainer {
  position: absolute;
  top: 30px;
  z-index: 1;
  height: 40px;
  /* width: 130px; */
  padding: 8px 4px;
}

.locationHero > img {
  position: relative;
  width: 100%;
  left: 50px;
}

.locationDetailsContainer {
  margin-bottom: 30px;
}

.locationDetailsContainer > h3 {
  font-family: 'Congenial Black';
  color: #461d58;
  display: none;
}

.locationDetailsContainer > p {
  font-family: 'Filson Pro Bold';
  color: #ce4396;
  font-size: 16px;
  margin: 0;
  margin: 20px 0;
}

.locationDetailsHeader, .locationDetailsHours {
  display: flex;
  justify-content: space-between;
}

.locationDetailsHeader > div {
  /* color: #966826; */
  font-family: 'Filson Pro Regular';
}

.locationDetailsHeader > div  > p {
  margin: 0;
  margin-top: 17px;
}

.locationDetailsHeader > p {
  font-family: 'Filson Pro Bold';
  /* color: #461d58; */
}

.isClosed > span {
  color: #ce4396;
}

.locationDetailsHours {
  justify-content: flex-start
}

.hoursColumn {
  width: 50%;
  display: table;
}

.hourColumnItem {
  display: table-row;
}

.hourColumnItem > p:first-child {
  display: table-cell;
  width: 120px;
}

.hourColumnItem > p {
  display: table-cell;
}

.hoursColumn > h4 {
  font-family: 'Filson Pro Bold';
  /* color: #461d58; */
}

.hoursColumn > div > p {
  /* color: #966826; */
  font-family: 'Filson Pro Regular';
  margin: 0;
}

.locationDetailsContainer > .customButtonContainer {
  /* display: none; */
}

.locationSearchAutoComplete {
  padding-top: 10px;
  z-index: 11;
}

.customLocationItemStyles {
  width: auto;
  padding: 20px 45px 20px 25px;
}
.blurbText, .promotionalText{
  font-family: Filson Pro Bold;
  font-size: 16px;
  margin-top:42px;
  display: block;
}
@media (max-width: 1100px) {
  .locationsContainer {
    padding: 30px 20px;
  }
  .locationHero > img {
    left: 0;
  }
}

@media (max-width: 1000px) {
  .locationsContainer {
    width: 60%;
  }
}


@media (max-width: 900px) {
  .pageWrapper {
    position: relative;
    top: -10px;
  }
}

@media (max-width: 700px) {
  .locationsContainer {
     overflow: unset;
  }
  .locationSearchAutoComplete {
    height: calc(100% - 86px);
    overflow: auto;
  }
  .pageWrapper, .locationsContainerMobile {
    display:block;
  }

  .pageWrapper {
    height: auto;
  }

  .pageWrapper > div {
    width: 100%;
  }

  .mapContainer > img {
    display: block
  }

  .pageContainer {
    overflow: hidden;
  }

  .mapContainer {
    height: 800px;
  }

  .locationsContainer {
    display: none;
  }

  .locationsList {
    height: 75%;
    margin-top: 24px;
    margin-left: 27px;
    margin-right: 27px;
    overflow-y: scroll;
  }

  .locationsContainerMobile {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: fixed;
    transition: 0.3s ease-in;
    width: 100%;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
  }

  .locationsInputWrapper {
    padding: 20px;
  }

  .customLocationItemStyles {
    width: auto;
    padding-left: 50px;
  }

  .locationContainer {
    height: 100%;
    width: 100%;
    padding: 5px;
    overflow-y: scroll;
    padding-top: 20px;
  }

  .locationHero > img {
    width: 95%;
    display: block;
    margin: 0 auto;
    margin-top: 40px;
    left: 0;
  }

  .locationDetailsContainer > h3 {
    display: block;
    font-size: 24px;
    margin-bottom: 10px;
  }


  .locationDetailsContainer > p {
    font-family: 'Filson Pro Bold';
    color: #ce4396;
    margin: 0;
    margin: 20px 0;
  }

  .customButtonContainer {
    /* display: none; */
  }

  .locationDetailsHeader > p {
    position: relative;
    top: -50px;
  }

  .locationDetailsHeader > div {
    font-size: 14px;
  }

  .locationDetailsHours {
    display: block;
  }

  .hoursColumn {
    width: 100%;
  }

  .hoursColumn > h4 {
    margin-bottom: 10px;
  }

  .locationDetailsContainer {
    padding: 30px;
  }

  .locationDetailsContainer > .customButtonContainer {
    display: flex;
    /* width: 130px !important; */
    position: relative;
  }
}

/* ERROR UI STYLES */

.errorContainer {
  padding: 10px 15px;
}

.errorContainer > p:first-of-type {
  font-family: 'Filson Pro Medium';
  font-size: 18px;
  line-height: 1.17;
  color: #966826;
}

.errorContainer > p:last-of-type {
  font-family: 'Filson Pro Regular';
  font-size: 16px;
  color: #966826;
}

.errorContainer > span {
  font-family: 'Filson Pro Black';
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 500px) {
  .locationsListItem > .customButtonContainer {
    padding: 0;
  }

  .locationDetailsContainer > h3 {
    width: 60%;
  }

  .locationsInputWrapper {
    padding: 20px 10px;
  }

  .locationInputContainer {
    margin-right: 10px !important;
  }

  .locationsList {
    margin: 0 20px;
  }

  .locationDetailsContainer > p {
    margin-top: 20px;
  }

  .locationDetailsHeader {
    position: relative
  }

  .locationDetailsHeader > p {
    position: absolute;
    top: -80px;
    right: 0
  }

  .locationDetailsContainer {
    padding: 15px;
  }

  .customButtonContainer {
    bottom: 30px;
  }

  .locationsList {
    height: 75%;
  }
}
