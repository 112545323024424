.orderHistoryWrapper {
  border-radius: 20px;
  width: 100%;
  display: block !important;
  margin: 30px 0;
}

.orderHistoryContainer {
  margin: 20px;
}

.orderHistoryHeader, .orderHistoryFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderHistoryHeader {
  font-family: 'Filson Pro Bold';
}

.orderHistoryHeader > div > h3, .orderHistoryHeader > h3 {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.orderHistoryHeader > div > p {
  font-family: 'Filson Pro Regular';
  margin: 0;
  margin-top: 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 425;
}

.totalsSection {
  margin-bottom: 25px;
}

.totalsSection:last-of-type {
  margin-bottom: 0;
}

.totalsSection > div {
  font-family: 'Filson Pro Bold';
}

.orderHistoryItemsContainer {
  margin: 30px 0;
}

.orderHistoryItemsContainer > p {
  font-family: 'Filson Pro Bold';
  cursor: pointer;
  margin-left: 35px;
  margin-top: 10px;
}

.orderHistoryFooter {
  margin: 40px 0 30px;
}

.orderHistoryFooter > div > h3 {
  margin: 0;
  font-family: 'Filson Pro Bold';
  display: none;
  font-weight: normal;
}

.orderHistoryFooter > div  p {
  font-family: 'Filson Pro Regular';
  margin: 0;
}

.orderHistoryFooter > div > p > span > img {
  margin-right: 10px;
  height: 20px;
}

.orderHistoryFooter > a {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Filson Pro bold';
  font-weight: 700;
  cursor: pointer;
  color: #983992;
}

@media (max-width: 800px) {
  .orderHistoryContainer {
    margin: 20px 15px;
  }

  .orderHistoryHeader > h3 {
    display: none;
  }

  .orderHistoryFooter > div > h3 {
    display: block;
  }

  .orderHistoryFooter > div > p {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .orderHistoryContainer {
    margin: 20px 15px;
  }
}
