.carouselWrapper {
    margin-top: 15px;
}
  
.carouselHeader > p {
    margin: 0;
    font-size: 18px;
    font-family: 'Filson Pro Bold';
    line-height: 1.33;
    position: relative;
    top: 20px;
}

.carouselDots {
    top: 35px;
    left: 75px;
    z-index: 10000;
    display: flex;
    align-items: center;
    position: relative;
}

.carouselDots > img {
    display: none;
}

.carouselDots > .dot {
    cursor: pointer;
    width: 7px;
    height: 7px;
    background-color: rgba(213, 184, 137, 0.8);
    border-radius: 100px;
    margin-right: 10px;
}

.carouselDots > .activeDot {
    background-color: #f3c244 !important;
}

.carouselSlide {
    display: flex;
    align-items: center;
    width: 90%;
    padding: 50px 0px;
}

.carouselSlide > div {
    margin: 0 !important;
    margin-right: 64px;
}

.carouselSlide > div:last-child {
    margin-right: 0 !important;
}

.arrow {
    position: relative;
    top: 10px;
    cursor: pointer !important;
    transform: rotate(180deg);
}

.arrowRight {
    transform: rotate(360deg);
}

@media (max-width: 1000px) {
    .arrow {
        display: none;
    }

    .carouselDots {
        justify-content: flex-end;
        left: 0;
        top: 1px;
    }

    .carouselDots > img {
        display: flex;
    }

    .carouselDots > img:first-child {
        transform: rotate(180deg);
        position: relative;
        right: 10px;
    }
}

@media (max-width: 900px) {
    /* CAROUSEL STYLES */
    .desktopCarousel {
        display: none;
    }
    .mobileCarousel {
        display: block;
    }
}