.pageWrapper {

}
.phoneWidget {
  margin-top: 20px;
}
.phoneSelectStyle > div {
  border: none;
}
.pageContainer {
  display: flex;
}

/* SECTION OPTION STYLES */

.sectionsContainer {
  width: 25%;
  padding: 30px 70px;
}

.sectionsContainer > h3 {
  margin: 0;
  font-family: 'Congenial Black';
  font-size: 32px;
  line-height: 1.13;
  margin-bottom: 32px;
  color: #461d58;
}

.mobileOptions {
  display: none;
}

.optionContainer {
  box-shadow:  5px 5px 0 1px #bc945a;
  border: 1px solid #ba935a;
  border-radius: 8px;
  background-color: #fffdfb;
  width: 238px;
  height: 76px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.chosenOption {
  background-color: #f3c244;
  border: 1px solid #f3c244;
  box-shadow: none;
}

.optionContainer > p {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  line-height: 1.33;
  color: #461d58;
}

.formContainer > p > a {
  color: #ce4396 !important;
  margin-left: 5px;
}

/* CONTENT STYLES */
.contentContainer {
  width: 75%;
  padding: 30px 70px;
}

.inputWrapper {
  width: 100%;
}

.dropDownWrapper {
  margin-top: 20px !important;
  margin: 5px 0;
}

.storeWrapper::before {
  content: 'Store' !important;
}

.inputWrapper > div > input {
  color: #461d58 !important;
}

.formContainer > p {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  margin: 0;
  line-height: 1.33;
  color: #966826;
}

.formContainer > div > span {
  font-family: 'Filson Pro Bold';
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
}

.feedback::before {
  content: 'Order Inquiry' !important;
}

.submitButton {
  margin-top: 20px;
}

/* ACTION STATES */

.error, .success {
  display: block;
  margin-top: 10px;
  font-family: 'Filson Pro Bold';
}

.error {
  color: #d21c38;
}

.feedbackFormSubmitedContainer {
  margin-top: 70px;
}

.success {
  color: #ce4396;
}

@media (max-width: 900px) {


  .pageContainer {
    flex-direction: column;
  }
  
  /* SECTION OPTION STYLES */
  
  .desktopOptions {
    display: none;
  }

  .mobileOptions {
    display: block;
  }

  .optionsContainer {
    border-radius: 8px;
    background-color: #f3c244;
    padding: 20px 0;
    text-align: center;
    position: relative;
    cursor: pointer;
  }

  .optionsContainer > p {
    margin: 0;
    padding: 0;
    font-family: 'Filson Pro Bold';
    font-size: 18px;
    line-height: 1.33;
    color: #461d58;
  }

  .optionsContainer > img {
    height: 10px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease-in;
  }

  .expanded {
    transform: rotate(180deg) !important;
    top: 40% !important;
    transition: 0.2s ease-in;
  }

  .optionsList {
    text-align: center;
  }

  .optionsList > .listOption > p {
    padding: 20px 0;
    font-family: 'Filson Pro Bold';
    font-size: 18px;
    line-height: 1.33;
    color: #461d58;
    margin: 0;
    cursor: pointer;
  }

  .optionsList > .listOption > p:hover {
    background-color: rgba(213, 184, 138, 0.2);
  }

  .sectionsContainer, .contentContainer {
    width: 100%;
    padding: 30px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }

}
