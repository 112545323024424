
.menuContainer {
  overflow: hidden;
  /* position: relative; */
}

.menuDetailsContainer {
  /* padding-bottom: 83px; */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  /* min-height: 450px; */
}

.bannerWrapper {
  /* margin-top: 100px; */
}

.boxBannerAssetWrapper {
  display: flex;
}

.boxShippingBannerAssetWrapper {
  display: flex;
}
.boxBannerAssetWrapper > img {
  width: 500px;
  height: 100%;
  position: relative;
  bottom:95px;
  right:50px;
}

.boxShippingBannerAssetWrapper > img {
  width: 600px;
  height: 100%;
  position: relative;
  top:-40px;
  right:120px;
}

/* PRODUCT SECTION STYLES */
.productsSection {
  /* margin: 42px 0; */
}

.productsSection:focus {
  /* outline: none; */
}

.productsSection > p {
  
}

.productsContainer {
  /* display: flex;
  flex-wrap: wrap; */
}

.shippingCarouselContainer {
  margin-top: 70px;
}

/* PRODUCT CARD STYLES */

.smallProduct, .mediumProduct{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.productsContainer > .smallProduct,
.productsContainer > .mediumProduct {
  /* width: 25%;
  padding: 0 23px; */
}

.productsContainer > .smallProduct:nth-child(4n),
.productsContainer > .mediumProduct:nth-child(4n) {
  /* padding-right: 0; */
}

.productsContainer > .smallProduct:nth-child(1),
.productsContainer > .mediumProduct:nth-child(1),
.productsContainer > .smallProduct:nth-child(4n + 1),
.productsContainer > .mediumProduct:nth-child(4n + 1){
  /* padding-left: 0; */
}

.menuSearch input {
  width: 100%;
  text-align: left;
}
.menuSearch input::placeholder {
  text-align: center;
  color: rgba(128, 128, 128, 0.5);
  -webkit-text-fill-color: rgba(128, 128, 128, 0.5) !important;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.searchDivWidth{
  width: 50%;
}

.closeIcon {
  right:15px;
  height:12px;
  top:15px;
}

@media (max-width: 768px){
  .searchDivWidth{
    width: 100%;
  }
}
.errorContainer > p {
  font-family: 'Filson Pro Regular';
  font-size: 13px;
  color: #f12a2f;
  margin-left: 10px;
}

@media (max-width: 1300px) {
  .boxShippingBannerAssetWrapper > img {
    right:80px;
  }
  .productsContainer > .smallProduct,
  .productsContainer > .mediumProduct {
    /* width: 33.333333%;
    padding: 0 23px; */
  }

  .productsContainer > .smallProduct:nth-child(4n),
  .productsContainer > .mediumProduct:nth-child(4n),
  .productsContainer > .smallProduct:nth-child(4n + 1),
  .productsContainer > .mediumProduct:nth-child(4n + 1){
    /* padding: 0 23px; */
  }

  .productsContainer > .smallProduct:nth-child(3n),
  .productsContainer > .mediumProduct:nth-child(3n){
    /* padding-right: 0; */
  }

  .productsContainer > .smallProduct:nth-child(3n + 1),
  .productsContainer > .mediumProduct:nth-child(3n + 1){
    /* padding-left: 0; */
  }
}

.loaderWrapper {
  height: 50vh;
  width: 100%;
}

@media (max-width: 1210px) {
  .boxShippingBannerAssetWrapper > img {
    right:25px;
    width:550px;
  }
}
@media (max-width: 1100px) {
  .boxBannerAssetWrapper > img{
    width: 400px !important;
    position: relative;
    bottom:46px;
    right:20px;
  }
  .boxShippingBannerAssetWrapper > img {
    width: 400px !important;
    position: relative;
    top: 35px;
  }
}

@media (max-width: 1050px) {

  .menuDetailsContainer {
    /* padding: 0 20px; */
  }

  .productsContainer > .smallProduct,
  .productsContainer > .mediumProduct {
    /* width: 50%;
    padding: 0 23px; */
  }

  .productsContainer > .smallProduct:nth-child(3n),
  .productsContainer > .mediumProduct:nth-child(3n),
  .productsContainer > .smallProduct:nth-child(3n + 1),
  .productsContainer > .mediumProduct:nth-child(3n + 1){
    /* padding: 0 23px; */
  }

  .productsContainer > .smallProduct:nth-child(2n),
  .productsContainer > .mediumProduct:nth-child(2n){
    /* padding-right: 0; */
  }

  .productsContainer > .smallProduct:nth-child(2n + 1),
  .productsContainer > .mediumProduct:nth-child(2n + 1){
    /* padding-left: 0; */
  }
}

@media (max-width: 900px) {
  .boxBannerAssetWrapper > img {
    width: 350px !important;
    bottom:25px;
  }

  .boxShippingBannerAssetWrapper > img {
    width: 350px !important;
    top: 30px;
  }

  .menuDetailsContainer {
    /* padding: 0 30px;
    padding-bottom: 30px; */
  }

  .boxBannerAssetWrapper, .boxShippingBannerAssetWrapper {
    justify-content: center;
  }

  .loaderWrapper {
    height: 70vh;
    width: 100%;
  }

  .shippingCarouselContainer {
    margin-top: 115px;
  }
}

@media (max-width: 800px) {
  .boxBannerAssetWrapper > img , .boxShippingBannerAssertWrapper > img {
    top: 0;
  }
}

@media (max-width: 700px) {
  .productsContainer > .smallProduct,
  .productsContainer > .mediumProduct {
    /* width: 100%;
    padding: 0 !important; */
  }
  .productsContainer {
    display: block;
  }
}

@media (max-width: 500px) {
  .boxBannerAssetWrapper > img {
    width: 100% !important;
  }

  .boxShippingBannerAssetWrapper > img {
    width: 100%;
    right: 40px;
  }
  .menuDetailsContainer {
    /* padding: 0 20px; */
  }
}

@media (max-width: 400px){
  .boxShippingBannerAssetWrapper > img {
    right: 20px;
  }
}

@media (max-width: 360px){
  .boxShippingBannerAssetWrapper > img {
    width:300px !important;
  }
}
