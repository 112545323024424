.productImage>p>img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    margin: auto;
}

@media only screen and (max-width: 425px) {
    .productImage>p>img {
        width: 75%;
        max-height: 290px;
        object-fit: contain;
        margin: auto;
    }
}