.productBackground {
    width: 100%;
    height: 125px;
    position: absolute;
    background-image: url('./imgs/product-bg.png');
}

.solidBackground {
    width: 100%;
    height: 750px;
    position: absolute;
    z-index: -1;
    background-color: #fbf6ef;
    background-position: bottom;
}

.tearSheetBackground {
    width: 100%;
    height: 847px;
    position: absolute;
    z-index: -1;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-image: url('./imgs/product-tear.png');
}

.loadingPageContainer {
  min-height: 100%;
  min-width: 100%;
}

.loaderWrapper {
  min-height: 100%;
  min-width: 100%;
  height: 550px;
  width: 300px;
}
