.checkoutCartWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Filson Pro Bold';
  font-size: 18px;
}


.checkoutCartButton {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  cursor: pointer;
  z-index: 100 !important;
  /* box-shadow:  4px 5px 0 1px #BC945A; */
  width: 100%;
  height: 60px;
  border-radius: 30px;
  /* background-color: #f3c244; */
  /* border: solid 1px #bc945a; */

  /* display: flex;
  justify-items: center;
  justify-content: space-between ;
  padding-left: 15px;
  padding-right: 15px; */
}

.quantityContainer {
  /* display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  gap: 25px;
  right: 30%; */
}

.quantityLabelContainer {  
  /* position: relative;
  text-align: center; */
}

.quantityLabelContainer > span {
  font-family: 'Filson Pro Bold';
  position: absolute;
  font-size: 18px;
  top: 42%;
  left: 39%;
  transform: translate(-90%, -35%);
}

.quantityLabelContainer > img {
  width: 70%;
}
