a {
    /* color: #fffdfb; */
}

.pageWrapper {
    display: flex;
    justify-content: center;
}

.pageContainer {
    width: 55%;
    z-index: 1;
    /* color: #fffdfb; */
    margin-top: 40px;
    min-height: calc(75vh);
}

.pageSection {
    margin-bottom: 10px;
}

.pageSection .currentCountry {
    /* color: #f3c244; */
    cursor: default !important;
}

.pageSection  > p > span {
    /* color: white; */
}

.pageSection  > p > span:hover {
    /* color: #f3c244; */
    cursor: pointer;
}

.pageSection > h1, .pageSection > h3 {
    font-family: 'Congenial Black';
    margin-bottom: 20px;
}

.pageSection > h1 {
    font-size: 72px;
}

.pageSection a {
    /* color: #f3c244; */
}

.pageSection > h3 {
    font-size: 35px;
}

.pageSection > p {
    font-size: 23px;
    line-height: 30px;
    margin: 0;
    font-family: 'Filson Pro Regular'
}

@media (max-width: 1200px) {
    .pageContainer {
        width: 80%;
    }
}

@media (max-width: 700px) {

    .pageContainer {
        width: 90%;
    }

    .pageSection > h1 {
        font-size: 42px;
        margin-bottom: 30px;
    }

    .pageSection > p {
        font-size: 20px;
        line-height: 25px;;
    }

}
