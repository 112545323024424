.buttonContainer {
  cursor: pointer;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  /* color: #1A0021; */
  height: 54px;
}

/* THEMES */

.darkTheme {
  /* border: 1.5px solid #ba935a;
  background-color: #F3C244;
  box-shadow:  4px 5px 0 1px #BC945A; */
}

.lightTheme {
  /* border: 1.5px solid #461d58;
  box-shadow:  4px 5px 0 1px #461d58; */
}

.buttonContainer > p {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  align-items: center;
  align-self: center;
  margin: 0 ;
}

/* ACTION STYLES */

.isDisabledDark {
  background-color: #d5b88a;
  box-shadow: none !important;
  color: #966826;
}

@media (max-width: 500px) {
  .buttonContainer {
    width: 100% !important;
  }

  .buttonContainer > p {
    /* margin: 15px !important; */
  }
}
