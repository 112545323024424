.embeddedpayment {
  /* background: white;
  border-radius: 20px !important;
  justify-content: center !important;
  max-height: 40px !important; */
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.embeddedpayment img {
  max-height: 17px;
}

