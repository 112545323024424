.formContainer .formTitle {
    font-weight: bold;
    font-family: "Filson Pro Bold";
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
}
.formContainer .loaderContainer {
    position: absolute;
    width: 100%;
    height: calc(100% + 4rem);
    background-color: rgba(100, 100, 100, .5);
    left: 0;
    top: 0;
    z-index: 99;
}
.formContainer .loaderContainer>div {
    height: 100%;
}

.formContainer .formSubTitleSection {
    padding: 20px 0 30px 0;
    border-bottom: 1px dotted #B4A5BB;
    margin-bottom: 30px;
}

.formContainer .formSubTitleSection .formSubTitle {
    font-family: "Filson Pro Bold";
    font-size: 16px;
    line-height: 20px;
}

.formContainer .formSubTitleSection .formDescription {
    font-family: "Filson Pro Regular";
    font-size: 14px;
    line-height: 16px;
}
.formContainer .formItem {
    margin-bottom: 20px;
}
.buttons {
    margin-top: 20px;
}
.buttons .formError {
    color: red;
    font-size: 14px;
    min-height: 20px;
}
.buttons > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
}
.buttons .submitButton {
    width: 344px;
}
.buttons .submitButton.processing {
    background-color: gray;
    cursor: not-allowed;
}
.buttons .cancelButtonSection.processing span {
    color: gray;
    cursor: not-allowed;
}
.buttons .cancelButtonSection > span {
    font-size: 18px;
    font-family: "Filson Pro Bold";
    font-weight: bold;
    line-height: 22px;
    letter-spacing: -0.1px;
    color: #983992;
    cursor: pointer;
}
.buttons .cancelButtonSection > span:hover {
    cursor: pointer;
    text-decoration: underline;
}


.successComponentContainer {
    min-height: 300px;
}
.successComponentContainer .successComponentTitle {
    font-family: "Filson Pro Bold";
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.3px;
    padding-bottom: 20px;
    
}
.successComponentContainer .successComponentSubTitle {
    font-size: 20px;
    font-family: "Filson Pro Bold";
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
}
.successComponentContainer .successComponentMessage {
    font-family: "Filson Pro Regular";
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.1px;
    margin-bottom: 20px;
}
.successComponentContainer .successComponentMessage b {
    font-family: "Filson Pro Bold";
    font-weight: bold;
}
.successComponentContainer .successComponentButtons {
    display: flex;
    justify-content: center;
}