.pageWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pageContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

.pageContainer > h1, .pageContainer > p {
}

.pageContainer > h1 {
  font-size: 42px;
  font-family: 'Congenial Black';
  margin: 0;
  margin-bottom: 20px;
}

.pageContainer > p {
  font-family: 'Filson Pro Bold';
  margin-bottom: 2px
}

.pageContainer > p > span {
  font-family: 'Filson Pro Regular';
  color: inherit;
  margin-left: 5px;
  font-size: inherit;
}

.pageContainer > span {
  font-size: 12px;
}

.detailsItemsContainer {
  margin-top: 20px;
}

/* LINE ITEM STYLES */

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d5b88a;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 5px;
  padding: 0.25rem 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.itemContainer > div {
  width: 33%;
}

.itemContainer > div:first-of-type > .itemType {
  margin: 0;
  display: none;
}

.itemContainer > div:nth-child(2) {
  text-align: center;
}

.itemTitle, .itemType, .orderAmountPrice {
  font-size: 14px;
}

.itemTitle {
  margin: 0;
  font-family: 'Filson Pro Bold'
}

.itemType {
  font-family: 'Filson Pro Regular';
  text-transform: capitalize;
}

.orderAmountPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Filson Pro Bold'
}

.orderAmountPrice > span {
  height: 4px;
  width: 4px;
  border-radius: 100px;
  margin: 0 7px;
  position: relative;
  top: 1.5px;
}

/* DETAILS */

.detailsWrapper {
  margin-top: 50px;
}

.detailsWrapper > h3 {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  margin: 0;
  margin-bottom: 13px;
  line-height: 1.33;
}

.detailsContainer, .userDetailsContainer {
  display: flex;
  justify-content: space-between;
}

.orderDetailsContainer {
  display: flex;
  width: 45%;
}

.discountsContainer {
  margin-bottom: 25px;
}

.userDetailsContainer {
  width: 50%;
  flex-direction: column;
}

.userDetailsContainer:last-child {
  display: none;
}

.userDetailsContainer > div > h5 {
  font-family: 'Filson Pro Regular';
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}

.userDetailsContainer > div > p {
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  margin: 0;
}

.orderDetailsContainer {
  display: block;
}

.subTotalPrice {
  display: flex;
  justify-content: space-between;
}

.subTotalPrice > p {
  margin: 0;
  margin-bottom: 5px;
}

.subTotalPrice > p:first-child {
  font-family: 'Filson Pro Regular'
}

.subTotalPrice > p:last-child {
  font-family: 'Filson Pro Bold'
}

.totalPrice > p {
  margin-top: 30px;
  font-family: 'Filson Pro Bold' !important;
}

.loaderWrapper {
  height: 50vh;
  width: 100%;
}

@media (max-width: 1000px) {
  .pageContainer {
    width: 90%;
  }

  .pageContainer > h1 {
    font-size: 36px;
  }

  .detailsItemsContainer {
    margin-top: 50px;
  }

  .detailsWrapper > h3 {
    display: none;
  }

  .detailsContainer {
    border-top: none;
    margin: 0;
    display: block;
  }

  /* USER DETAILS */

  .userDetailsContainer, .orderDetailsContainer {
    width: inherit;
  }

  .userDetailsContainer {
    margin: 0;
    padding: 0;
    margin-top: 28px;
  }

  .userDetailsContainer > h3 {
    font-size: 16px;
    font-family: 'Filson Pro Bold';
    line-height: 1.5;
  }

  .userDetailsContainer:first-child {
    display: none;
  }

  .userDetailsContainer:last-child {
    border-top: 1px solid #d5b88a;
    display: flex;
    margin-top: 10px;
    padding-top: 20px;
    margin-bottom: 50px;
  }

  /* LINE ITEM STYLE */

  .itemContainer > div {
    width: 50%;
  }

  .itemContainer > div:nth-child(2) {
    display: none;
  }

  .itemContainer > div:first-of-type > .itemType {
    display: block;
  }

}

@media (max-width: 350px) {
  .userDetailsContainer > div > h5 {
    font-size: 14px;
  }
  
  .userDetailsContainer > div > p {
    font-size: 14px;
  }
}

.trackingMapContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.trackingMapContainer .trackingMap {
  width: calc(60% - 10px);
}
.trackingMapContainer .orderTotals {
  width: calc(40% - 10px);
}

@media (max-width: 900px) {
  .trackingMapContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .trackingMapContainer .trackingMap {
    width: 100%;
  }
  .trackingMapContainer .orderTotals {
    width: 100%;
  }
}
