.alertContainer {
  position: relative;
  width: 100.37%;
  top: 30px;
  height: 60px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: #ce4396;
}

.alertContainer > p {
  padding-top: 5px;
  font-size: 14px;
  font-family: 'Filson Pro Bold';
  margin: 0;
  line-height: 1.43;
  text-align: center;
  color: #fffdfb;
}

.checkoutCartButton {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  position: relative;
  z-index: 100 !important;
  width: 100%;
  height: 54px;
  border-radius: 40px;

  display: flex;
  align-items: center;
  position: relative;
}

.checkoutCartButton > p, .checkoutCartButton > div > p, .quantityContainer {
  margin: 0;
  font-size: 18px;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.33;
  /* color: #1a0021; */
}

.labelPrice {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.labelPrice > span {
  position: relative;
  top: -2px;
  margin: 0 8px;
}

.labelPrice > span, .mobilePrice {
  display: none;
}

.checkoutCartButton > p > span {
  opacity: 0;
}


/* QUANTITY STYLES */

.quantityContainer {
  height: inherit;
  width: 65.5px;
  border-right: solid 1px #bd9555;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  
}

.quantityContainer > img {
  margin-left: 10px;
}

/* PRICE CONTAINER STYLES */
.priceContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
}

.priceContainer > div {
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 13px;
}

.priceContainer > div > img {
  height: 20px;
}

.priceContainer > div > p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-family: 'Filson Pro Bold';
  color: #461d58;
}

/* QUANTITY SELECTOR STYLES */
.quantitySelectorContainer {
  z-index: 100;
  position: absolute;
  width: 65.5px;
  border-top-left-radius: 30px;
  /* border: solid 1px #bc945a; */
  background-color: #fff;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  bottom: 50px;
}

.quantitySelectorContainer .scrollbar p:first-child {
  border-top-left-radius: inherit;
}
.scrollbar{
  border-top-left-radius: 30px;
}

.scrollbar >  div:first-child {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.quantitySelectorContainer .scrollbar  p {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fff;
  padding: 10px 0 10px 18px;
  margin:  0;
}

.quantitySelectorContainer .scrollbar  p:hover {
  color: #461d58;
  background-color: #fff;
}

/* ACTION STYLES */
.isDisabled {
}

.isDisabled > div > p {
  
}

.cartButtonShowQuantity {
  border-top-left-radius: 5px;
}

@media (max-width: 600px) {

  .labelPriceQuantity {
    position: relative;
    left: 30px;
  }

  .labelPrice > span, .labelPrice > p:last-of-type {
    display: block;
  }

  .priceContainer { 
    display: none;
  }

  .priceContainerHome {
    display: flex !important;
  }

  .labelPriceHome {
    position: absolute;
    left: 20px;
  }
}

@media (max-width: 350px) {
  .quantityContainer {
    padding-right: 0;
    left: 0;
  }
  .labelPriceQuantity {
    position: relative;
    left: 27px;
  }
  .alertContainer > p {
    font-size: 11px;
    padding-top: 7px;
  }
  .quantitySelectorContainer {
    width: 50px;
  }
  .quantityContainer{
    width: 50px;
  }
  .checkoutCartButton{
    /* height: 50px; */
  }
  .checkoutCartButton > p, .checkoutCartButton > div > p, .quantityContainer {
    font-size: 14px;
  }
}

.widgetWrapper {
    width: 460px;
    margin: 0 auto;
    padding: 57px 0 30px; 
}