.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageContainer {
  z-index: 1;
  margin: 40px 0 20px;
}

.loaderWrapper {
  height: 35px;
}

.pageContainer > h1 {
  font-size: 60px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  margin: 0;
  margin-bottom: 20px;
}

.pageContainer > p {
  font-family: 'Filson Pro Regular';
  font-size: 22px;
  margin-bottom: 50px;
  line-height: 1.36;
}
.pageContainer > p > a {
  color: #FF609E
}
.pageContainer > p > small {
  font-size: 15px;
}

.pageContainer > .text {
  margin: 0;
  padding: 0;
}

.buttonContainer {
  box-shadow: none !important;
  margin-top: 1.5px;
}

@media (max-width: 700px) {

  .pageContainer {
    z-index: 1;
    color: #fffdfb;
    margin-top: 40px;
  }

  .pageWrapper {
    justify-content: flex-start;
    padding: 0 30px;
  }

  .pageContainer {
    width: 100%;
  }
}

@media (max-width: 500px) {

  .pageContainer > h1 {
    font-size: 42px;
  }

  .pageContainer > p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
