.widgetItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 0 25px;
  margin: 5px 0;
}

.widgetItemContainer > div {
  width: 33.333333333%;
}

.unavailableProduct {
  border: 3px solid red !important;
}

.orderType {
  text-align: center;
}

.orderPrice {
  text-align: right;
}

.orderTitle > p {
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
} 

.orderTitle > p > span {
  margin-right: 10px;
}

.orderType > p {
  font-family: 'Filson Pro Regular';
  position: relative;
  text-transform: capitalize;
}

.orderPrice > p {
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

@media (max-width: 800px) {
  .widgetItemContainer {
    border: none;
    padding: 0;
  }

  .widgetItemContainer > div {
    width: 100%;
  }
  
  .orderTitle > p {
    font-family: 'Filson Pro Bold';
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  } 
  
  .orderTitle > p > span {
    margin-right: 5px;
  }

  .orderType, .orderPrice {
    display: none;
  }

}