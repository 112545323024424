.cardContainer {
  overflow: hidden;
  z-index: 1000;
}

.selectedIndex {
  /* background-color: rgb(237,241,232) !important; */
}

a > img .carouselContainer,
.carouselImage {
  display: block;
  margin: 0 auto;
  border-radius: 30px;
}