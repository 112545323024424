.headerContainer,
.headerLeft,
.headerRight
.headerAddress {
  box-sizing: border-box;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  align-items: center;
  font-size: 18px;
  transition: color 100ms;
  padding: 0;
}

.headerWrapper {
  width: 100%;
  position: fixed;
  z-index: 99999999;
}

.headerContainer {
  margin: 0;
  width: 100%;
  font-family: sans-serif;
  z-index: 99999;
  background-color: #461d58;
  transition: background-color 100ms;
}

.headerContainer.isLight {
  background-color: #fffdfb;
}

.headerLeft,
.headerRight
.headerAddress {
  z-index: 100;
  align-items: center;
  color: #fffdfb;
}

.headerRight {
  height: 10px !important;
}

.headerLeft {
  position: relative;
}

.headerLeft > a > img {
  /* height: 75px; */
  margin-right: 25px;
}

.headerLeft > p {
  font-size: 18px;
  cursor: pointer;
}

.headerLeft > span {
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: 20px;
  margin-left: 25px;
}

.deliveryContainer {
  cursor: pointer;
  position: relative;
}

.deliveryContainer > p,
.deliveryContainer > h3 {
  margin: 0;
}

.deliveryContainer > p {
  /* font-family: 'Filson Pro Regular'; */
  margin-bottom: 5px;
  font-size: 16px;
}

.deliveryContainer > h3 {
  /* font-family: 'Filson Pro Bold'; */
  font-size: 18px;
}

.deliveryContainer > a {
  display: none;
}

.dropdownRotate > img {
  transform: rotate(90deg);
}

.deliveryContainer > h3 > span > img {
  height: 12px;
  margin-left: 10px;
  position: relative;
  top: 1px;
}

.widgetWrapper {
  /* TODO */
}

.headerRight > div > img,
.headerRight > p,
.cartContainer > span {
  cursor: pointer;
  /* transition: all 200ms; */
}

.headerRight > p {
  font-size: 16px !important;
  font-weight: bold !important;
}

.headerAddress > div > img,
.headerAddress > p,
.cartContainer > span {
  cursor: pointer;
  /* transition: all 200ms; */
}


.headerAddress > div > a > img,
.headerAddress > a > div > img,
.headerAddress > div > img {
  height: 50px;
  z-index: 100;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.headerAddress > a {
  color: inherit;
  transition: all 200ms;
}

.headerAddress > p {
  /* margin-right: 20px; */
  transition: all 200ms;
}

.headerAddress > p > a {
  text-decoration: none;
  color: inherit;
  transition: all 200ms;
}

.headerAddress > p > a:focus {
  color: inherit;
}

.headerAddress > p > a:visited {
  color: inherit;
}



.headerRight > div > a > img,
.headerRight > a > div > img,
.headerRight > div > img {
  height: 50px;
  z-index: 100;
  margin: 0 7px;
  -webkit-transition: all .2s;
  transition: all .2s;
}

.headerRight > a {
  color: inherit;
  transition: all 200ms;
}

.headerRight > p {
  /* margin-right: 20px; */
  transition: all 200ms;
}

.headerRight > p > a {
  text-decoration: none;
  color: inherit;
  transition: all 200ms;
}

.headerRight > p > a:focus {
  color: inherit;
}

.headerRight > p > a:visited {
  color: inherit;
}

.ordersLink {
  margin-right: 2px;
  position: relative;
  padding-right: 2px;
}

.ordersAttentionGrabber {
  position: absolute;
  top: -12px;
  right: -14px;
  display: inline-block;
  overflow: hidden;
  width: 16px;
  height: 16px;
  background-color: #E01766;
  border: 2px solid #461D58;
  border-radius: 100px;
  padding: 2px 5px;
}

.userDetails {
  display: flex;
  justify-content: space-between;
  height: 44px;
}

.loyaltyReward {
  display: flex;
  justify-content: space-between;
  height: 44px;
}

.userContainer {
  display: flex;
  transition: all 200ms;
}

.userContainer > a {
  text-decoration: none;
  display: flex;
}

.userContainer > a > img {
  height: 50px !important;
  z-index: 100;
  margin: 0 !important;
  -webkit-transition: all .2s;
  transition: all .2s;
  padding: 15px;
}

.userContainer > a:active {
  color: inherit;
}

.userContainerLoggedIn {
  margin-right: -10px !important;
}

.userDetails {
  cursor: pointer;
  border: 1px solid #fffdfb;
  align-items: center;
  color: #fffdfb;
  border-radius: 24px;
  position: relative;
}

.userDetails > h3 {
  font-size: 14px;
}

.userDetails > span {
  height: 4px;
  width: 4px;
  margin: 0 6px;
  border-radius: 100px;
  /* background-color: #fffdfb; */
  align-items: center;
}

.loyaltyReward {
  cursor: pointer;
  border: 1px solid #fffdfb;
  align-items: center;
  color: #fffdfb;
  border-radius: 24px;
  position: relative;
}

.loyaltyReward > h3 {
  font-size: 14px;
}

.loyaltyReward > span {
  height: 4px;
  width: 4px;
  margin: 0 6px;
  border-radius: 100px;
  /* background-color: #fffdfb; */
  align-items: center;
}

.mobileDetailsWrapper {
  position: relative;
  display: none;
}

.userDetailsMobile {
  border: 2px solid #fffdfb;
  border-radius: 100px;
  margin-right: 5px;
  color: #fffdfb;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userDetailsMobile > p {
  margin: 0;
  font-size: 15px;
}

.userDetailsMobile ~ span {
  color: #fffdfb;
  position: absolute;
  font-size: 12px;
  left: 45%;
  top: 45px;
  transform: translateX(-50%);
}
.cartQuantity{
  color: #FFF;
}
.cartContainer {
  position: relative;
}

.cartContainer > span {
  font-family: 'Filson Pro Bold';
  /* position: absolute; */
  font-size: 14px;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-5%, -50%); */
  margin: 0 !important;
}

/* ALERTS AND NOTIFICATION STYLES */

.cartAlertContainer, .cartNotificationContainer {
  border-radius: 15.5px;
  width: 147px;
  position: absolute;
  text-align: center;
  font-family: 'Filson Pro Bold';
  font-weight: 425;
  font-size: 16px;
  line-height: 20px;
}

.cartAlertContainer > p, .cartNotificationContainer > p {
  /* color: #fffdfb; */
}

.cartAlertContainer {
  display: none;
  border-radius: 15.5px;
  width: 147px;
  right: 10px;
  bottom: -45px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: #1a0021;
}

.cartAlertContainer > p {
  font-size: 12px;
  /* font-family: 'Filson Pro Regular'; */
}

.cartNotificationContainer {
  width: 125px;
  height: 43px;
  border-radius: 8px;
  opacity: 0;
  display: none;
  bottom: -55px;
  left: -55px; /* initial position off screen */
  background-color: #ce4396;
  transition: 0.3s ease-out;
}

.cartNotificationContainer > p {
  /* font-family: 'Filson Pro Bold'; */
  font-size: 14px;
}

.renderNotification {
  display: block;
}

.showNotification {
  opacity: 1;
  left: -75px;
  transition: 0.3s ease-in-out;
}

.navContainer {
  display: none;
}

/* TEXT CONDITIONAL STYLES */

.isDark {
  color: #fffdfb !important;
}

.isLight {
  color: #461d58 !important;
}

.isLight > span {
  background-color: #461d58 !important;
}

.isLight ~ span {
  color: #461d58 !important;
}

.isLightBorder {
  border: 2px solid #461d58;
}
.noBorder {
  border: none;
}

.isDeliveryPickUp > h3,
.isDeliveryPickUp > p {
  display: none;
}

.isDeliveryPickUp > a {
  display: block !important;
}

.isDeliveryPickUp > a:focus {
  text-decoration: none;
}

.isDeliveryPickUp > a > h3 {
  font-size: 18px;
  color: #fffdfb;
  text-decoration: none;
}

.isDeliveryPickUp > a > h3 > img {
  height: 16px;
  margin-right: 10px;
  transform: rotate(180deg);
  position: relative;
  top: 2px;
}

/* ACTION STYLES */

.cartContainer > img:hover ~ div,
.cartContainer > span:hover ~ div {
  display: block !important;
}

.headerRight:hover > p,
.headerRight:hover > div,
.headerRight:hover > a {
  opacity: 0.5;
}

.headerRight > p:hover,
.headerRight > a:hover,
.userContainer:hover,
.cartContainer:hover,
.navContainer:hover {
  opacity: 1 !important;
}

/* CATEGORIES STYLES */
.categoriesWrapper {
  overflow-x: scroll;
  margin: 0 auto;
  /* padding-bottom: 8px;
  padding-top: 12px; */
}

.categoriesWrapper::-webkit-scrollbar {
  display: none;
}

.ribbonBannerWrapper {
  width: 100%;
}

@media (max-width: 900px) {
  .categoriesWrapper {
    border-radius: 0;
    background-color: #461d58;
    box-shadow: 0 8px 8px 0 rgba(213, 184, 138, 0.1);
  }

  .headerContainer {
    /* padding: 10px 8px 15px; */
  }

  .headerLeft > p {
    margin-left: -15px;
    margin-right: 15px;
  }

  .headerLeft > p > span {
    display: none;
  }

  .widgetWrapper {
    display: none;
  }

  .userDetails {
    display: none;
  }

  .loyaltyReward {
    display: none;
  }

  .userContainerLoggedIn > a > img {
    display: none;
  }

  .userContainer {
    margin-right: 0;
  }

  .headerRight > p {
    display: none;
  }

  .headerRight > div > a img {
    height: 48px;
  }

  .deliveryContainer {
    display: none;
  }

  .navContainer {
    display: block !important;
    position: relative;
  }

  .navContainer .ordersAttentionGrabber {
    top: 0;
    right: 0;
  }

  .mobileDetailsWrapper {
    display: block;
  }

  .cartNotificationContainer {
    left: 10px;
  }

  .showNotification {
    opacity: 1;
    left: -13px;
    transition: 0.3s ease-in-out;
  }

  .signInButton {
    font-size: 18px !important;
  }

}

@media (min-width: 600px) {
  .cartContainer > span {
  }
}

@media (max-width: 430px) {
  .userContainer > button {
    /* width: 145px; */
    font-size: 14px;
  }

  .userContainerLoggedIn {
    margin-right: -18px !important;
  }
}

@media (max-width: 375px) {
  .userContainer > button {
    /* width: 130px; */
    font-size: 12px;
  }

  .userContainerLoggedIn {
    margin-right: -18px !important;
  }
}

@media (max-width: 360px) {
  .userContainer > button {
    /* width: 115px; */
    font-size: 11px;
  }

  .userContainerLoggedIn {
    margin-right: -18px !important;
  }
}

@media (max-width: 320px) {
  .headerRight {
    margin-right: 5px;
  }
  .headerContainer {
    padding: 10px 5px 15px;
  }
  .headerRight > div > img {
    margin: 0 4px;
  }
  .userContainer > button {
    width: 30vw;
    font-size: 4.5vw;
  }

  .userContainerLoggedIn {
    margin-right: -18px !important;
  }
}
