.boxPageContainer {
  display: flex;
  align-content: center;
}

.boxImageContainer{
  max-width: 470px;
}

.boxDetailsContainer {
  max-width: 698px;
}

.boxImageContainer > img {
  width: 583px;
}

.boxDetailsContainer {
  margin-top: 100px;
}

p.errorMessage {
  color: #d21c38;
  font-family: 'Filson Pro Bold';
  text-align: center;
}

.detailsHeader > span {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
}

.widgetWrapper {
  width: 460px;
  margin: 0 auto;
  padding: 57px 0 30px;
}

.detailsHeader > h2 {
  margin: 0;
  font-size: 42px;
  font-family: 'Congenial Black';
  line-height: 1.14;
  color: #461d58;
}

/* BOX SLIDER */
.boxSlider {
  margin-top: 20px;
}

.boxSliderRange {
  background-image: url('./imgs/slider-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.sliderIconContainer {
  text-align: center;
  position: relative;
  height: 50px;
  width: 80px;
}

.sliderIconContainer > img {
  height: 32px;
  position: relative;
  top: -5px;
}

.sliderIconContainer > p {
  margin: 0 !important;
  font-size: 24px;
  font-family: 'Congenial Black';
  font-weight: 900;
  line-height: 1.17;
  color: #461d58;
}

.boxDetailsContainer > p {

}

/* SECTION HEADER STYLES */

.boxSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-right: 50px;
}

.boxSectionHeader > div > p {
  margin: 0;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
}

.boxSectionHeader > div > span {
  cursor: pointer;
}

.boxSectionHeader > button {
  width: 138px;
  height: 54px;
  border-radius: 40px;
  padding: 0;
}

.boxSectionRow {
  display: flex;
  flex-wrap: wrap;
}

.cartWrapper {
  position: sticky;
  margin-bottom: 30px;
  margin-top: 50px;
  bottom: 20px;
}

.loaderWrapper {
  position: absolute;
  top: 9%;
  left: 46%;
  z-index: 101 !important;
}

.pageLoaderWrapper {
  height: 50vh;
  width: 100%;
}

.backMenu span {
  padding-right:15px;
}

.backMenu h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
  cursor: pointer;
}

.nutritionalInfo {
  margin: 0;
  font-size: 12px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #ce4396;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
}


@media (max-width: 1400px) {
  .boxImageContainer {
  }

  .boxDetailsContainer {
  }

  .boxImageContainer > img {
    width: 375px;
  }
}

@media (max-width: 1150px) {
  .boxPageContainer {
   
  }

  .boxImageContainer {
   
  }

  .boxDetailsContainer {
   
  }

  .boxImageContainer > img {
    width: 275px;
  }
}

@media (max-width: 1000px) {
  .boxPageContainer {
    flex-direction: column;
    justify-content: center;
    padding-top: 150px;
  }

  .boxImageContainer,
  .boxDetailsContainer {
   
  }

  .boxImageContainer {
    text-align: center;
  }

  .boxDetailsContainer {
    margin-bottom: 75px;
  }

  .boxImageContainer > img {
    position: relative;
  }

  .boxDetailsContainer {
    margin-top: 0;
  }

  .detailsHeader {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .cartWrapper {
    position: sticky;
    margin-top: 50px;
    bottom: 20px;
  }
}

@media (max-width:768px){
  .detailsHeader{
    flex-direction: unset;
    flex-wrap: wrap;
  }

  .backMenu{
  }
}

@media (max-width: 600px) {
  .boxPageContainer {
  }

  .boxImageContainer > img {
    position: relative;
  }

  .boxDetailsContainer > p {
    margin-top: 0;
  }

  .detailsHeader > h2 {
    font-size: 24px;
    line-height: 1.17;
  }

  .boxSectionRowSelector > div:last-of-type {
    margin-left: 0;
  }

  .boxSectionHeader {
    padding-right: 0;
  }

  /* RANGE SLIDER */
  .boxSliderRange {
    background-image: url('./imgs/slider-bg-mobile.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
  }

  .sliderIconContainer > img {
    height: 32px;
    position: relative;
    left: 3px;
  }
}

@media (max-width: 400px) {
  .boxSectionHeader > button {
    font-size: 14px;
  }
}
