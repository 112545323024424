.borderBottom {
    border-bottom: 1px solid #E5E5E5;
}
.orderDetailsContainer .borderTop {
    border-top: 1px solid #E5E5E5;
}
.orderDetailsEditButton {
    border: 1px solid;
}
.orderDetailsLine {
    display: flex;
    width: 100%;
    padding: 20px 0;
}
.orderDetailsLine .iconCell {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 30px;
}
.orderDetailsLine .iconCell img {
    width: 24px;
}

.contentCell {
    width: calc(100% - 110px);
    padding: 0 5px 0 5px;
}
.buttonCell {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 140px;
}
.orderDetailsLineTitle .contentCell .orderDetailsTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.orderDetailsLineTitle.orderDetailsWithoutSubtitle button {
    height: 36px;
}
.orderDetailsLineTitle.orderDetailsWithoutSubtitle {
    padding: 10px 0;
    align-items: center;
}
.contentCell .orderDetailsTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}

.contentCell .orderDetailsSubtitle {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
}
.phoneInput {
    display: block;
    padding-top: 5px;
}
.phoneInputSelect {
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.phoneInputInput {
    display: block;
    width: 100%;
    margin-top: 0;
}
.deliveryTimeWidget {
    margin-top: 10px;
    box-shadow: none !important;
    border-bottom: none;
}
.deliveryTimeWidget > div {
    padding: 0;
    position: relative;
}
.deliveryTimeWidget > div > div > div {
    display: block;
}
.deliveryTimeWidget > div > div:first-of-type {
    position: absolute;
    z-index: 99999;
    right: 0px;
    top: 40px;
    width: 80px;
}
.inputField {
    margin-top: 10px;
}
.orderDetailsSubtitle input[type="text"] {
    width: 100% !important;
}
.formError {
    color: red;
    font-size: 14px;
    line-height: 16px;
    padding: 3px 0;
}
.orderDetailsLine .buttonCell .driverButton {
    padding: 0;
    font-size: 14px;
}

.orderDetailsLine .buttonCell .helpButton {
    padding: 0;
    width: 90px;
    font-size: 14px;
}
