.tipContainer {
    margin: 25px 0;
}

.tipContainer > div {
    font-family: 'Filson Pro Bold' !important;
    justify-content: space-between;
}

.tipContainer > h3 {
    font-size: 16px;
    font-family: 'Filson Pro Bold';
    color: #461d58;
}

.tipContainer > div:last-of-type {
    display: flex;
}

.tipContainer > div:last-of-type > div {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    /* border: solid 1px #d5b88a;
    background-color: #fffdfb; */
    margin-right: 6px;
    /* color: #461d58; */
    justify-content: center !important;
    align-items: center;
    height: 60px;
}

.tipContainer > div:last-of-type > div:last-of-type {
    margin-right: 0;
}

.chosenTip {
    border: 1px solid #f3c244 !important;
    background-color: #f3c244 !important;
}

.customTipWrapper > div {
    width: 100% !important;
    border: none !important;
}

.customTipWrapper > div > div > input {
    /* color: #461d58 !important; */
}
