.mobileMenuWrapper {
  z-index: 999999;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  /* background-image: url('./imgs/bg.png'); */
  background-size: cover ;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
}

.mobileMenuContainer > img {
  position: absolute;
  top: 50px;
  right: 50px;
  height: 20px;
  cursor: pointer;
}

.mobileMenuFooter, .mobileMenuContainer > div {
  padding: 0 20px;
}

.mobileMenuContainer {
  margin-bottom: 20px;
}

.mobileMenuContainer > div > a {
  display: block;
  text-decoration: none;
}

.mobileMenuContainer > div > a:focus {
  color: inherit;
}

.mobileMenuContainer > div > a > h4 {
  /* color: #fffdfb; */
  position: relative;
  display: inline-block;
  font-family: 'Congenial Black';
  font-size: 30px;
  margin: 0;
  margin-bottom: 10px;
}

.mobileMenuContainer > div > div {
  text-decoration: none;
}

.mobileMenuContainer > div > div:focus {
  color: inherit;
}

.mobileMenuContainer > div > div > h4 {
  /* color: #fffdfb; */
  font-family: 'Congenial Black';
  font-size: 30px;
  margin: 0;
  margin-bottom: 10px;
}

.mobileMenuSocial {
  text-align: center;
  margin-top: 20px;
  display: flex;
}

.mobileMenuSocial > a > img {
  margin: 20px 10px;
  height: 40px;
}

.mobileMenuFooter {
  display: flex;
  justify-content: space-between;
  border-top: dotted 2px #ffc0e5;
  margin-top: 40px;
  padding: 30px;
}

.mobileMenuFooter > div {
  margin-top: 20px;
}

.mobileMenuFooter > div > a, .mobileMenuContainer > span {
  color: #983992;
  font-family: 'Filson Pro Regular';
  text-decoration: none;;
}

.mobileMenuContainer > span {
  margin-top: 50px;
  font-size: 13px;
  text-align: center
}

@media (min-width: 600px) and (max-height: 900px) {
  .mobileMenuWrapper {
    padding-top: 150px;
  }
}


@media (max-width: 570px) and (max-height: 900px) {

  .mobileMenuWrapper {
    padding-top: 60px;
  }

  .mobileMenuContainer > img {
    top: 50px;
    right: 35px;
    height: 20px;
  }

  .mobileMenuContainer > span {
    font-size: 12px;
  }

}

@media (max-width: 360px) and (max-height: 900px) {
  .mobileMenuWrapper {
    padding-top: 150px;
  }
}

.mobileMenuContainer button.getMyFreeCookieBtn {
  text-indent: 2px;
  width: 190px!important;
  border-radius: 22.5px;
  background-color: #f3c244;
  padding: 3px 10px 0px 10px;
  font-size: 18px;
  line-height: 1.30;
  color: #1a0021;
}

.ordersAttentionGrabber {
  position: absolute;
  top: 4px;
  right: -14px;
  display: inline-block;
  overflow: hidden;
  width: 14px;
  height: 14px;
  background-color: #E01766;
  border-radius: 100px;
  padding: 2px 5px;
}
