.pickerContainer {
    font-family: 'Filson Pro Regular';
    padding: 10px 10px 0 10px;
    border-radius: 8px;
    border: solid 1px #d5b88a;
    background-color: #fffdfb;
    color:#461d58;
    font-weight: 700!important;
    font-size: 15px;
    height: 30px;
}

.pickerWrapper {
    position: relative;
}
.pickerWrapper::before {
    content: 'Send Date';
    font-family: 'Filson Pro Regular';
    position: absolute;
    color: #966826;
    font-size: 12px;
    top: 8px;
    z-index: 1;
    left: 26px;
}