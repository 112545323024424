/*
* Order tracking card with arrow :after
*------------------------------------------------------------------------------ */

.orderTracking {
  position: relative;
  box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.18);

  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 12px;
  margin: 24px 0;

  color: #461D58;

  font-family: "Filson Pro Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 425;
  line-height: 20px;
}

.orderTrackingChatBubble:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%; /* Move the starting point to the middle of the parent */
  transform: translateX(-50%); /* Shift the element back by half of its width */
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 13px solid white;
}

/*
* Header
*------------------------------------------------------------------------------ */

.orderTrackingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderTrackingHeader h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.orderTrackingEta {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
}

/*
* Progress indicator
*------------------------------------------------------------------------------ */

.orderTrackingProgress {
  display: grid;
  grid-gap: 4px;
  gap: 4px;
}

.orderTrackingPickupProgress {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.orderTrackingDeliveryProgress {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.orderTrackingProgress > div {
  background: #E6DFE8;
  border-radius: 20px;
  min-height: 6px;
}

.orderTrackingActiveStep {
  background: #52C41A !important;
}

/*
* Map
*------------------------------------------------------------------------------ */

.orderAddressDetails {
  display: flex;
  justify-content: space-between;
}

.orderAddressDetails em {
  display:block;
  font-size: 14px;
  font-style: normal;
  font-weight: 425;
  line-height: 16px;
}

.orderAddressDetails strong {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.orderAddressDetails > div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.orderAddressLatestArrival {
  text-align: right;
}

.orderAddressLatestArrival strong {
  font-weight: 425;
}

.mapContainer {
  height: 242px;
  overflow: hidden;
}

.pickupInstructions {
  font-size: 14px;
  font-style: normal;
  font-weight: 425;
  line-height: 16px;
  text-align: center;
}

@media (max-width: 800px) {
  .mapContainer {
    height: 35vh;
  }

  .pickupInstructions {
    text-align: left;
  }
}

/*
* Buttons
*------------------------------------------------------------------------------ */

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primaryButton {
  order: 9999;
  height: 44px !important;
  padding: 16px !important;
}

.primaryButton p {
  font-family: "Filson Pro Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 425;
  line-height: 20px;
  white-space: nowrap;
}

.secondaryButtonsContainer {
  display: flex;
  align-items: stretch;
  gap: 12px;
}

.secondaryButton {
  flex-grow: 1;
  height: 44px !important;
  border: 1px solid #E6DFE8;
  background-color: #F6F3F7 !important;
  padding: 16px !important;
}

.secondaryButton p {
  color: #461D58;

  font-family: "Filson Pro Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 425;
  line-height: 20px;
  white-space: nowrap;
}

.secondaryButton.contactStoreButton {
  order: 9999;
}

.buttonLabel .onlyOnDesktop {
  display: inline;
}

.buttonLabel .onlyOnMobile {
  display: none;
}

.buttonLabel {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 800px) {
  .buttonsContainer {
    flex-direction: column;
    gap: 12px;
  }

  .primaryButton {
    order: 0;
  }

  .secondaryButtonsContainer {
    width: 100%;
  }

  .secondaryButton.contactStoreButton {
    order: 0;
  }

  .buttonLabel .onlyOnDesktop {
    display: none;
  }

  .buttonLabel .onlyOnMobile {
    display: inline;
  }
}
