.componentWrapper {
    position: relative;
    box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.18);

    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 12px;
    margin: 24px 0;

    color: #461D58;

    font-family: "Filson Pro Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 425;
    line-height: 20px;
    padding: 20px;
}

.mapContainer {
    height: 242px;
    overflow: hidden;
}

.componentTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.3px;
}
.mainInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.mainInfo > div {
    margin-bottom: 10px;
}
@media (max-width: 768px) {
    .mainInfo {
        display: block;
    }
}
.mainInfo > div {
    font-size: 16px;
    font-weight: bold;
}
.mainInfo > div > span {
    font-weight: normal;
}
.trackingStatus {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.3px;
}
.trackingContainer {
    display: flex;
    flex-flow: row;
}
  
.trackingContainer .trackingRow {
    flex: 1 1 auto;
    padding-right: 10px;
}
.trackingContainer .trackingRow:last-of-type {
    padding-right: 0;
}
.shipTo {
    font-weight: bold;
}
.shipTo > span {
    font-weight: normal;
}