.pageWrapper{
    display: flex;
    justify-content: center;
}

.pageContainer{
    width: 55%;
    z-index: 1;
    color: #fffdfb;
    margin-top: 40px;
}
.termsConditionsContainer {
    color:  rgb(70 29 88);
}

.termsConditionsContainerTitle {
    font-size: 36px;
    margin:25px 0;
    font-family: 'Congenial Black';
}

.termsConditionsContainerTitle p{
    text-align: center;
}

.termsConditionsContainerBody {
    width:95%;
    margin-left: auto;
    margin-right: auto;
}

.termsConditionsSections {
    line-height: 1.6;
    margin:20px 10px;
}

.termsConditionsSectionsTitles {
    text-decoration: underline;
    font-family: 'Congenial Black';
}

.termsConditionsSectionBody {
    margin:5px 15px;
}

.termsConditionsSectionLists li {
    list-style-type: none;
}
.termsConditionsPoints {
    font-family:'Filson Pro Regular';
    line-height: 1.8;
    margin:5px 10px;
}

