.detailsSection > h3,
.discountContainer > h3,
.detailsSection > div > h3 {
  font-family: "Filson Pro Bold";
  font-size: 18px;
  line-height: 1.33;
  color: #966826;
}

p.callout {
  color: #461d58;
  font-family: "Filson Pro Bold";
}

.callout a {
  color: #461d58;
}
