.errorWrapper {
  padding-top: 100px;
}

.errorContainer {
  display: flex;
  justify-content: flex-end;
}

/* LEFT HERO STYLES */

.leftHero{
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  width: 40%;
  margin-right: 150px;
}

.leftHero > h1 {
  font-family: 'Congenial Black';
  font-size: 70px;
  line-height: 1.03;
  margin: 0;
  margin-bottom: 20px;
}

.leftHero > p {
  font-family: 'Filson Pro Regular';
  font-size: 24px;
  line-height: 1.17;
  margin: 0;
  margin-bottom: 28px;
}

.leftHero > .customButtonContainer {
  /* width: 149px !important;
  height: 48px !important; */
}

.mobileAsset {
  display: none;
}

@media (max-width: 1250px) {
  .leftHero{
    width: 100%;
    margin-right: 50px;
    padding-left: 50px;
  }

  .errorContainer > img {
    width: 500px;
  }
}

@media (max-width: 1100px) {
  .errorContainer > img {
    width: 400px;
  }
}

@media (max-width: 900px) {
  .errorWrapper {
    margin-top: -30px;
  }

  .errorContainer {
    flex-direction: column;
    justify-content: flex-end;
  }

  .leftHero{
    width: 100%;
    padding: 20px;
  }

  .leftHero > h1 {
    font-size: 42px;
    line-height: 1.14;
  }
  
  .leftHero > p {
    width: 75%;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 20px;
  }

  .errorContainer > img {
    align-self: flex-end;
    position: relative;
    width: 275px;
  }

  .desktopAsset {
    display: none;
  }

  .mobileAsset {
    display: block;
  }
}

@media (max-width: 450px) {
  .errorWrapper {
    padding-top: 30px;
  }
  .leftHero > h1 {
    font-size: 40px;
  }
  .errorContainer > img {
    width: 250px;
  }
  .leftHero > p {
    width: 90%;
  }
}

@media (max-width: 350px) {
  .leftHero > p {
    width: 95%;
  }
}
