.dropDownContainer {
  width: 100%;
  position: relative;
}

.dropDownControl {
  /* background: #fffdfb; */
  /* border: 1px solid #d5b88a; */
  border-radius: 40px;
  cursor: pointer;
  min-height: 50px; /* min height matched with input */
  width: 100%;
  padding-top: 11px;
}

.dropDownControl ~ div {
  border-top: none;
  position: absolute;
  top: 46px;
}

.dropDownPlaceholder {
  font-family: 'Filson Pro bold';
  /* color: #461d58 !important; */
  position: relative;
  font-size: 14px;
  top: 15px;
  left: 5px;
}

.dropDownPlaceholder::before {
  content: 'State';
  font-family: 'Filson Pro Regular';
  position: absolute;
  /* color: #461d58; */
  font-size: 12px;
  top: -18px;
}

.dropDownIcon {
  position: absolute;
  height: 8px;
  bottom: 18px;
  right: 15px;
}

.dropDownMenuContainer {
  background-color: #fffdfb;
  border: 1px solid #d5b88a;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;
  top: -5px;
  padding-top: 10px;
  z-index: 9999999;
}

.dropDownMenuContainer > div {
  font-family: 'Filson Pro Bold';
  padding: 15px 15px;
}

.dropDownMenuContainer > div:hover {
  background-color: rgba(213, 184, 138, 0.2);
}

.dropDownMenuContainer > div[aria-selected='true']{
  background-color: rgba(213, 184, 138, 0.2) !important;
}

/* CONDITIONAL RENDERINGS */
.dropDownPlaceholderDate::before {
  content: 'Pick a Date' !important;
}

.dropDownPlaceholderTime::before {
  content: 'Pick a Time' !important;
}

.Dropdown-menu {
  z-index: 9999999;
}

.isDisabled > div {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

@media (max-width: 500px) {
  .dropDownPlaceholder {
    font-size: 13.5px;
  }
  .dropDownContainer, .dropDownControl {
    height: auto;
  }
}
