.reCaptchaContent{
  margin:5px 0;
}
.pageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageContainer {
  z-index: 1;
  margin-top: 40px;
  max-width: 600px;
}

.pageContainer>div.registerCheckbox>div>div {
  width: 26px;
}

.pageContainer>div.registerCheckbox>div>div>img[alt="check"] {
  width: 26px;
}

.pageContainer>div.registerCheckbox {
  padding-top: 15px;
}

.pageContainer>div.registerCheckbox a {
  text-decoration: underline;
}

.pageContainer>div.registerCheckbox>div {
  align-items: start;
}
.pageContainer>div.registerCheckbox>div>span {
  display: block;
  width: calc(100% - 35px);
}
.pageContainer>div.registerCheckbox>div>span>p {
  margin: 0;
}

.pageContainer > h1 {
  font-size: 60px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  margin: 0;
  margin-bottom: 50px;
}

.pageContainer > a, .actionsContainer > p {
  font-family: 'Filson Pro Bold';
  font-size: 14px;
  text-decoration: none;
  align-self: center;
}
.actionsContainer > p {
  padding-right: 10px;
}
.hasError{
  color: #f12a2f !important;
  font-family: 'Filson Pro Regular';
  font-size: 13px;
  margin-left: 15px;
}
.actionsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.actionsContainer > p > a {
  color: #FF609E;
  text-decoration: underline;
  margin-left: 4px;
}

.buttonContainer {
  width: 158px;
}

.buttonContainer > p {
  margin: 0;
  font-size: 16px;
}

.buttonContainer:first-child {
  display: none;
}

.ssoContainer {
  margin-top: 60px;
  font-family: 'Filson Pro Regular'
}

.ssoContainer > p {
  font-family: 'Filson Pro Bold';
  text-align: center;
  font-size: 12px;
}

.ssoContainer > p > a {
  color: inherit;
}

.ssoContainer > p > span {
  margin: 0 5px;
}

.divider > span {
  font-size: 16px;
}

.divider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider > div {
  border: 0.5px solid #fffdfb;
  width: 175px;
  position: relative;
  top: 1px;
}

.ssoContainer > button {
  border-radius: 8px;
  background-color: #ffffff;
  height: 50px;
  width: 100%;
  margin: 20px 0;
  font-family: 'Filson Pro Regular';
  font-weight: normal;
  text-align: center;
  font-size: 16px;
  color: #545454;
  padding: 0;
}

.ssoContainer > button > p {
  margin: 0;
  align-items: center;
  width: 100%;
}

.ssoContainer > button > p > span > img {
  align-self: center;
  height: 18px;
  width: 18px;
  margin-right: 15px;
  position: relative;
  top: 3px;
}

button:focus {
  outline: none;
}

@media (max-width: 700px) {
  .pageWrapper {
    justify-content: flex-start;
    padding: 0 30px;
  }

  .pageContainer {
    width: 100%;
  }

  .ssoContainer > p {
    margin: 50px 0 40px;
  }
}

@media (max-width: 500px) {

  .pageContainer > h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }

  .pageContainer > p {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .actionsContainer {
    display: block;
  }

  .actionsContainer > p {
    text-align: center;
    margin-top: 20px
  }

  .buttonContainer:first-child {
    display: flex !important;
  }

  .buttonContainer:nth-child(3) {
    display: none !important;
  }

  .ssoContainer {
    margin-top: 30px;
  }

  .ssoContainer > button > p {
    margin: 0;
    align-items: center;
  }
}


@media (max-width: 500px) {
  .divider > div {
    width: 125px;
  }
}
