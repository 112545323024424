.productPageContainer {
  display: flex;
  align-content: center;
  /* padding: 0 67px;
  padding-top: 200px; */
}

.productImageContainer, .productDetailsContainer {
  width: 50%;
}

.productImageContainer > img {
  width: 519px;
}

.pageLoaderWrapper {
  height: 50vh;
  width: 100%;
}
p.errorMessage {
  color: #d21c38;
  /* font-family: 'Filson Pro Bold'; */
  text-align: center;
}

/* SINGLE PDP STYLES */

.productDetailsContainer {
  margin-top: 100px;
}

.productHeader > span {
  /* font-family: 'Filson Pro Regular'; */
  font-size: 18px;
  line-height: 1.33;
  /* color: #966826; */
}

.productHeader > h2 {
  margin: 0;
  font-size: 42px;
  font-family: 'Congenial Black';
  line-height: 1.14;
  margin-bottom: 8px;
  color: #461d58;
}

.productDetailsContainer > p {
  margin: 0;
  /* font-family: 'Filson Pro Regular'; */
  width: 70%;
  font-size: 16px;
  line-height: 1.5;
  /* color: #966826; */
}

.productQuantityContainer {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}

.productQuantityContainer > div:first-of-type > p:first-of-type {
  /* margin: 0;
  font-size: 18px; */
  /* font-family: 'Filson Pro Bold'; */
  /* line-height: 1.33;
  color: #461d58; */
}

.productQuantityContainer > div:first-of-type > p:last-of-type, .nutritionalInfo {
  /* margin: 0;
  font-size: 12px; */
  /* font-family: 'Filson Pro Bold'; */
  /* line-height: 1.33; */
  /* color: #ce4396; */
  /* text-decoration: underline; */
  cursor: pointer;
}
.nutritionalInfo{
  margin-bottom: 20px;
  display: block;
}

/* PRODUCT QUANTITY CHOOSE */

.productQuantityChooseContainer {
  display: flex;
  align-items: center;
}

.productQuantityChooseContainer > img {
  /* height: 44px; */
  cursor: pointer;
}

.productQuantityChooseContainer > span {
  margin: 0 20px;
  font-size: 24px;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.17;
  color: #461d58;
}

/* NUTRITION CONTAINER */

.productNutritionWrapper {
  margin-top: 39px;
  box-shadow:  4px 5px 0 1px #BC945A;
  border-radius: 19px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.productNutritionContainer {
  width: 50%;
  padding: 25px;
}

.productNutritionContainer > p {
  margin: 0;
  font-size: 14px;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.43;
  color: #966826;
  margin-bottom: 14px;
}

.productNutritionContainer > div {
  height: 48px;
  border-radius: 12px;
  border: solid 1px #d5b88a;
  color: #461d58;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productNutritionContainer > div > p {
  margin: 0;
}

#caloriesLabel {
  font-size: 18px;
  /* font-family: 'Filson Pro Bold'; */
}

#allergensLabel {
  /* font-family: 'Filson Pro Bold'; */
  font-size: 12px;
}

.widgetWrapper {
  width: 460px;
  margin: 0 auto;
  padding: 57px 0 30px; 
}

/* PRODUCT ACTION CONTAINERS */

.cartWrapper {
  margin: 30px 0;
  position: relative;
}

.loaderWrapper {
  position: absolute;
  top: 9%;
  left: 54%;
  z-index: 101 !important;
}

.backMenu span {
  /* padding-right: 15px; */
}

.backMenu h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
  cursor: pointer;
}
@media (max-width: 1150px) {
  .productPageWrapper {
    overflow-x: scroll;
  }

  .productPageContainer {
    flex-direction: column;
    justify-content: center;
    /* padding: 150px 30px 50px!important; */
  }
  
  .productPageContainer > div {
    width: 100% !important;
  }
  
  .productImageContainer > img {
    display: block;
    width: 50%;
    margin: 0 auto;
  }

  .productDetailsContainer {
    margin-top: 15px;
  }

  .productHeader {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
  }

  .productHeader > h2 {
    font-size: 24px;  
    line-height: 1.17;
  }
  
  .productDetailsContainer > p {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    /* color: #966826; */
  }

  .productNutritionWrapper {
    margin-bottom: 200px;
  }

  .cartWrapper {
    position: sticky;
    margin-top: 50px;
    bottom: 20px;
  }
}

@media (max-width:768px){
  .productPageContainer {
    /* padding: 150px 30px 10px!important; */
  }
  .productHeader{
    flex-direction: unset;
    flex-wrap: wrap;
  }

  .backMenu{
    width:100%;
  }
}

@media (max-width: 500px) {

  .productPageContainer {
    /* padding: 150px 30px 10px!important; */
  }
  .productImageContainer > img {
    width: 90% !important;
    margin-bottom: 20px;
  }
}

@media (max-width: 415px) {
  .productNutritionContainer {
    padding: 25px 5px;
  }
  .productNutritionContainer > p {
    font-size: 12px;
  }
  #allergensLabel {
    text-align: center;
  }
}
