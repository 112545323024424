.promoError {
    color: #d21c38;
    font-family: 'Filson Pro Bold';
    margin-left: 10px;
}

.appliedDiscount {
    margin: 16px 0;
}

.disableInput {
    /* background-color: #fcf7ef !important; */
}

.disableInput > button {
    /* background-color: #dab783 !important;
    color: #9e660e !important;
    width: 300px !important; */
}

.discountContainer > div {
    /* border-radius: 22px;
    border: solid 1px #d5b88a;
    background-color: #fffdfb;
    display: flex;
    align-items: center; */
}

input {
    /* border: none;
    width: 100%;
    font-family: 'Filson Pro Regular';
    background-color: transparent;
    font-size: 16px;
    line-height: 1.5;
    margin-left: 15px;
    color: #966826; */
}

input:focus {
    /* outline: none; */
}

div > button {
    height: 100%;
    padding: 0;
    border-radius: 22.5px;
    background-color: #f3c244;
    font-family: 'Filson Pro Bold';
    font-weight: normal;
    font-size: 18px;
    line-height: 1.33;
    color: #1a0021;
    width: 200px;
    padding: 10px 0;
    margin: 0;
}

.discountsInputRow {
    /* display: flex; */
}

.discountsInputRow .inputContainer {
    /* background-color: #fffdfb;
    border: 1px solid #d5b88a;
    border-radius: 8px !important;
    margin: 0;
    font-size: 12px;
    padding-left: 10px;
    height: 30px; */
}

.submitButton {
    /* margin-top: 2px;
    margin-left: 15px;
    margin-bottom: 5px;
    padding: 0; */
}

.inputLoaderContainer {
    margin-right: 10px;
    margin-left: 20px;
}