.pageTop {
  background-image: url('./imgs/bg.svg');
  background-size: cover;
  display: flex;
  height: 400px;
  align-items: center;
  justify-content: space-between;
  padding: 0 205px;
}

/* TOP LEFT HERO STYLES */

#orderConfirmation {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  margin-bottom: 24px;
  line-height: 1.33;
  color: #ffc0e5;
}

.errorContainer {
  color: #f12a2f !important;
  font-family: 'Filson Pro Bold';
  font-size: 13px;
  padding-left: 10px;
}

.requiredContainer {
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #ce4396;
  font-size: 12px;
}

.topLeft {
  position: relative;
  top: -75px;
}

.topLeftConfirmation {
  position: relative;
  top: -30px;
  width: 100%;
}

.topLeft > .goBack {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin-bottom: 34px;
}

.topLeft > .goBack > img {
  position: absolute;
  top: -2px;
  left: -30px;
}

.topLeft > h1, .topLeftConfirmation > h1 {
  font-size: 70px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  color: #fffdfb;
  margin: 0;
  margin-bottom: 20px;
}

.topLeft > p, .topLeftConfirmation > p {
  width: 100%;
  font-size: 24px;
  margin: 0;
  font-family: 'Filson Pro Regular';
  line-height: 1.17;
  color: #ffc0e5;
}

.topLeftConfirmation > div {
  display: flex;
  align-items: center;
}

.topLeftConfirmation > div > p {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin: 0;
  position: relative;
  left: 36px;
  top: 20px;
  cursor: pointer;
}

/* TOP RIGHT HERO STYLES */

.topRight > img {
  height: 500px;
  position: relative;
  top: 2px;
}

.topRightConfirmation {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.topRightConfirmation > img {
  height: 350px;
}

/* BOTTOM STYLES */

.pageBottom {
  min-height: 90vh;
}

.barCodeWrapper {
  display: flex;
  align-items: center;
  height: 350px;
}

.barCodeWrapper > div {
  /* width: 50%; */
  height: 100%;
}

/* BAR CODE STYLES */

.barCodeContainer {
  background-color: #fcf7ef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.barCodeContainer div > p {
  font-size: 14px;
  font-family: 'Filson Pro Bold';
  text-align: center;
  line-height: 1.43;
  color: #966826;
  margin: 0;
}

.barCodeContainer > div > svg {
  width: 250px;
}

.detailsContainer {
  width: 45%;
  margin: 75px auto;
}

.createContactLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 40%;
  left: 45%;
}

.sectionHeader {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #461d58;
  margin: 0 0 5px;
}

.inputWrapper {
  width: 100%;
  font-size: 12px;
}

.inputWrapper > div:first-of-type {
  min-height: 32px;
}

.giftMessageWrapper >div > input {
  width:100%;
  margin-right: 25px;
}

.hasError{
  color: #f12a2f !important;
  font-family: 'Filson Pro Regular';
  font-size: 13px;
  margin-left: 15px;
}
.detailsContainer > .inputWrapperDark > div {
  background-color: #faf7f2 !important;
  border: none;
}

.detailsContainer > .detailsNote {
  font-size: 12px;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
  color: #966826;
  width: 65%;
}

.loggedInBottomContainer, .loggedOutBottomContainer, .detailsContainer {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.loggedInBottomContainer {
  padding: 0;
}

/* LOGGED IN CONTAINER STYLES */
.loggedInBottomContainer > h2 {
  font-size: 42px;
  font-family: 'Congenial Black';
  /* color: #461d58; */
  margin-bottom: 16px;
}

.loggedInBottomContainer > p {
  font-size: 18px;
  margin: 0;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
}

.loggedInBottomContainer > a {
  display: block;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  color: #ce4396;
  margin: 26px 0;
  line-height: 1.33;
}

.loggedInBottomContainer > .bottomButtonContainer {
  width: 128px;
  height: 44px;
  margin: 26px 0;
}

/* LOGGED OUT CONTAINER STYLES */

.loggedOutBottomContainer {
  margin: 62px auto;
  width: 45%;
}

.loggedOutBottomContainer > p:first-of-type {
  font-size: 18px;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
  text-align: center;
  color: #966826;
  margin-bottom: 32px;
}

.loggedOutBottomContainer p > span > a {
  font-family: 'Filson Pro Bold';
  color: #ce4396;
  margin-left: 5px;
}

.facebookButton {
  cursor: pointer;
  border-radius: 8px;
  background-color: #355089;
  font-family: 'Filson Pro Regular';
  color: #fffdfb;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.facebookButton > img {
  margin-right: 15px;
}

.loggedOutBottomContainer > .divider {
  display: flex;
  align-items: center;
  margin: 15px 0 40px;
}

.loggedOutBottomContainer > .divider > div {
  height: 1px;
  width: 100%;
  background-color: #966826;
}

.loggedOutBottomContainer > .divider > span {
  margin: 0 10px;
  color: #966826;
}

.loaderWrapper {
  height: 400px;
}

/* SECONDARY CONTAINER STYLE */

.secondaryContainer {
  margin-top: 50px;
}

/* BUTTON STYLES */

.buttonContainer {
  width: 100%;
  margin: 64px 0;
  height: 57px;
}

@media (max-width: 1400px) {
  .pageTop, .loggedInBottomContainer {
    padding: 0 120px;
  }
}


@media (max-width: 1200px) {
  .topLeft > h1, .topLeftConfirmation > h1 {
    font-size: 60px;
  }
}

@media (max-width: 1125px) {
  .pageTop, .loggedInBottomContainer {
    padding: 0 30px;
  }
  .topLeft > h1, .topLeftConfirmation > h1 {
    font-size: 50px;
  }
}

@media (max-width: 1050px) {
  .pageTop, .loggedInBottomContainer {
    padding: 0 30px;
  }
  .detailsContainer, .loggedOutBottomContainer {
    width: 55%;
  }
}

@media (max-width: 990px) {
  .topLeft {
    position: relative;
  }
  .detailsContainer, .loggedOutBottomContainer {
    width: 60%;
  }
  .topLeft > h1, .topLeftConfirmation > h1 {
    font-size: 50px;
  }
}

@media (max-width: 910px) {
  .pageTop {
    position: relative;
    top: -25px;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
  /* TOP LEFT SYLES */
  .topLeft {
    top: 25px;
  }

  .topLeftConfirmation {
    top: 30px;
  }
  /* TOP RIGHT SYLES */
  .topRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .topRight > img {
    height: 400px;
  }
  .topLeft > .goBack {
    margin-bottom: 5px;
  }
  /* BOTTOM STYLES */

  .detailsContainer {
    width: 80%;
  }

  .loggedOutBottomContainer {
    width: 85%;
  }

  /* BARCODE STYLES */
  .barCodeWrapper {
    margin-top: -25px;
    flex-direction: column-reverse !important;
    height: auto;
  }

  .barCodeWrapper > div {
    width: 100%;
  }

  .barCodeContainer {
    padding: 30px 0;
  }

}

@media (max-width:615px){
  #orderConfirmation{
    margin-top:15px;
  }
}

@media (max-width: 600px) {
  .loggedInBottomContainer, .loggedOutBottomContainer {
    padding: 0 30px;
  }
  /* TOP LEFT STYLES */
  .topLeft, .topLeftConfirmation {
    top: 20px;
  }
  .topLeft > .goBack > img {
    position: absolute;
    top: -2px;
    left: -30px;
  }

  .topLeft > h1, .topLeftConfirmation > h1 {
    font-size: 42px;
    margin-bottom: 10px;
  }

  .topLeft > p, .topLeftConfirmation > p {
    width: 100%;
  }
  .topLeft > .goBack {
    margin-bottom: 10px;
  }

  /* TOP RIGHT STYLES */
  .topRightConfirmation > img {
    height: auto;
    width: 100%;
  }
  /* GO BACK GIFT SYLES */
  .goBack > span {
    display: none;
  }
  .goBack > img {
    position: initial !important;
    top: 0 !important;
    left: 0 !important;
  }
  /* TOP RIGHT STYLES */
  .topRight {
    margin-top: 20px;
  }
  .topRight > img {
    position: relative;
    left: 30px;
  }
  .detailsContainer {
    width: 100%;
    margin: 5px auto;
    padding: 0 30px;
  }
  .detailsContainer > .detailsNote {
    width: 100%;
  }
  /* BOTTOM STYLES */

  .pageBottom {
    height: 100vh;
  }

  .loggedOutBottomContainer {
    margin: 10px 0 !important;
    width: 100%;
  }

  .loggedOutBottomContainer > p:first-of-type {
    font-size: 16px;
    text-align: left;
    margin-bottom: 32px;
  }

  .loggedInBottomContainer > h2 {
    font-size: 32px;
  }

  .loggedInBottomContainer > p {
    font-size: 16px;
    text-align: left;
    margin-bottom: 32px;
  }

  .loggedInBottomContainer > .bottomButtonContainer {
    width: 128px !important;
    height: 44px !important;
    margin: 26px 0 !important;
  }
}

@media (max-width: 350px) {
  .pageTop {
    top: -35px;
  }
  /* BARCODE STYLES */
  .barCodeWrapper {
    margin-top: -35px;
  }
}

.phoneInputStyles > div:first-of-type {
  /* border: 1px solid #d5b88a; */
  min-height: 53px;
  /* background: rgb(255, 253, 251); */
}

.phoneInputStyles {
  margin-top: -5px;
}

.phoneSelectStyle {
  margin-right: 0;
  width: auto;
}
.phoneSelectStyle > div {
  background: rgb(255, 253, 251);
}

.phoneInputContainer {
  display: block;
}
.loaderConfirmPage {
  display: flex;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
}
.learnMoreLinkBlock {
  display: inline-block;
}