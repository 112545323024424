.container {
  max-width: 1140px;
  padding: 0 15px;
  margin: auto;
}

/* Components */
.buttonContainer {
  width: 160px;
  height: 50px;
  box-shadow: 2px 3px 0 1px #BC945A;
}

/* Banner Section */
.bannerSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;
  background-image: url(./imgs/vegan-gluten-free-cookie-banner.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: contain;
}

.bannerSection h1 {
  text-align: center;
  color: #461d58;
}

.bannerSection h1 span {
  display: block;
}

.bannerSection h1 span:first-child {
  font-size: 65px;
  font-family: 'Filson Pro Bold';
}

.bannerSection h1 span:last-child {
  font-size: 60px;
  font-family: 'Filson Pro Regular';
}

@media only screen and (max-width: 767px) {
  .bannerSection h1 {
    display: none;
  }
}

/* Mobile Headline Section */
.mobileHeadlineSection {
  display: none;
}

.mobileHeadlineSection h1 {
  text-align: center;
  color: #461d58;
}

.mobileHeadlineSection h1 span {
  display: block;
}

.mobileHeadlineSection h1 span:first-child {
  font-size: 35px;
  font-family: 'Filson Pro Bold';
}

.mobileHeadlineSection h1 span:last-child {
  font-size: 24px;
  font-family: 'Filson Pro Regular';
}

@media only screen and (max-width: 767px) {
  .mobileHeadlineSection {
    display: block;
  }
}

/* Available section */
.nowAvailableSection {
  background: #d0c8de;
  color: #461d58;
  text-align: center;
  padding: 15px 0;
}

.nowAvailableSection h3 {
  font-size: 37px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Filson Pro Regular';
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .nowAvailableSection h3 {
    font-size: 24px;
  }
}

/* Nutritional Section */
.nutritionalSection {
  background: #422f86;
  color: #fff;
  text-align: center;
  padding: 30px 0 25px;
}

.nutritionalSection .container > p {
  font-size: 39px;
  color: white;
  font-family: 'Filson Pro Regular';
  margin: 0;
}

.nutritionalSection .container > a {
  color: #a28dc0;
  font-size: 39px;
  display: inline-block;
  margin-top: 15px;
  font-family: 'Filson Pro Regular';
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .nutritionalSection .container > p {
    font-size: 24px;
  }

  .nutritionalSection .container > a {
    font-size: 18px;
  }
}

/* Cta Section */
.ctaSection {
  padding: 30px 0;
}

.ctaSection .containerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctaSection .containerContent > div {
  text-align: center;
  margin: 0 15px;
}

.ctaSection .containerContent > div .ctaFooter {
  font-size: 16px;
}

.ctaSection .containerContent > div > a {
  text-decoration: none;
}

.ctaSection .buttonContainer {
  width: 200px;
  height: 60px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .ctaSection .containerContent > div {
    margin: 0 10px;
  }

  .ctaSection .containerContent > div .ctaFooter {
    font-size: 14px;
  }
}

/* FAQ Section */
.faqSection h4 {
  font-family: 'Filson Pro Bold';
  color: #422f86;
  margin-bottom: 10px;
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.faqSection a {
  color: #461d58;
  text-decoration: none;
}

.faq {
  margin-bottom: 40px;
}

.faq > h5 {
  color: #422f86;
  font-size: 22px;
  font-family: 'Filson Pro Bold';
  margin: 0;
  line-height: 1.4;
}

.faq > p {
  font-family: 'Filson Pro Regular';
  font-size: 18px;
  line-height: 1.4;
}

.faqOrderNow {
  display: flex;
  align-items: center;
}

.faqOrderNow img {
  height: 250px;
  max-width: 100%;
}

.faqOrderNow a {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .faqSection h4 {
    font-size: 24px;
  }

  .faq > h5 {
    font-size: 18px;
  }

  .faq > p {
    font-size: 16px;
  }

  .faqOrderNow img {
    margin-bottom: 15px;
  }

  .faqOrderNow {
    flex-direction: column;
    justify-content: center;
  }
}
