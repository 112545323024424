.star-rating button {
  background-color: transparent !important;
  border: none;
  outline: none;
  cursor: pointer;
  width: 55px !important;
  font-size: 35px !important;
}

.on {
  color: #f0c14b;
}
.off {
  color: #ccc;
}

.star-rating {
  line-height: 32px;
  font-size: 5.25em;
  display: flex;
  justify-content: center;
}

.orderExperienceContainer {
  display: flex;
  align-items: left;
  font-family: 'Filson Pro Bold';
  font-size: 20px;
  margin-bottom: 24px;
  margin-top: 24px;
  color: #ffffff;
}

.display-none {
  display: none !important;
}

.display-block {
  display: block;
}

.comment {
  height: 200px;
  border: 1px solid #000;
  margin-left: 0px !important;
  margin-right: -10px !important;
}

.comment-submit {
  margin-top: 20px;
  margin-left: auto;
  width: 40%;
}

.comment-div {
  display: flex;
  flex-direction: column;
  font-family: 'Filson Pro Bold';
  font-size: 23px;
  margin: 40px;
  color: #000000;
}

.text{
  margin-right: 20px;
}

.experienceSubmit {
  /* border: 1.5px solid #ba935a;
  background-color: #F3C244;
  box-shadow: 4px 5px 0 1px #BC945A; */
}

@media (max-width: 700px) {

  .orderExperienceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    font-family: 'Filson Pro Bold';
    font-size: 20px;
    margin-right: auto !important;
    margin-left: 0px !important;
    margin-bottom: 24px;
    margin-top: 20px;
    color: #ffffff;
  }

  .comment-div {
    display: flex;
    flex-direction: column;
    font-family: 'Filson Pro Bold';
    font-size: 20px;
    margin: 15px;
    color: #000000;
  }
}

@media (max-width: 320px) {

  .orderExperienceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    font-family: 'Filson Pro Bold';
    font-size: 25px;
    margin-right: auto !important;
    margin-left: 0px !important;
    margin-bottom: 24px;
    margin-top: 20px;
    color: #ffffff;
  }

  .experienceSubmit {
    /* border: 1.5px solid #ba935a;
    background-color: #F3C244;
    box-shadow: 4px 5px 0 1px #BC945A; */
  }

  .comment-div {
    display: flex;
    flex-direction: column;
    font-family: 'Filson Pro Bold';
    font-size: 15px;
    margin: 20px;
    color: #000000;
  }
}
