.mediumWrapper {
  /* width: 100%; */
  border-radius: 22px;
  /* border: solid 1px #d5b88a;
  background-color: #fffdfb; */
  /* position: relative; */
}

/* BANNER STYLES */
.bannerContainer {
  /* border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: #ce4396;
  height: 34px;
  width: inherit;
  z-index: 0;
  top: 0;
  text-align: center;
  position: absolute; */
}
.alternateBannerContainer{
  /* bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 22px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 22px;
  top: auto;
  position: absolute; */
}

.isLimitedMediumDetailsContainer{
  /* margin-top: 19px; */
}
.bannerContainer > p {
  /* margin: 0;
  font-size: 14px; */
  font-family: 'Filson Pro Bold';
  /* line-height: 34px; */
  text-align: center;
  /* color: #fffdfb; */
}

.mediumContainer {
  position: relative;
  cursor: pointer;
}

.mediumDetailsContainer {
  height: 254px !important;
  display: flex;
  flex-direction: column;
}

.primaryImageContainer {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* position: absolute; */
  /* bottom: -50px;
  height: 175px;
  width: 175px;
  left: -15px; */
  z-index: 9999;
}

/* ALTERNATE STYLES */

.alternateContainer > .primaryImageContainer {
  /* position: absolute;
  top: -50px;
  left: -15px;
  height: 175px;
  width: 175px; */
}
.primaryImageContainer img {
  /* height:100%; */
}
.alternateContainer > .mediumDetails {
  margin-top: 142px;
  /* padding: 0 15px; */
  -webkit-line-clamp:7;
  -moz-line-clamp: 7;
  -ms-line-clamp: 7;
  line-clamp: 7;
}
/* DETAILS STYLES */

.mediumDetails {
  font-family: 'Filson Pro Bold';
  /* padding: 0 0 0 0; */
  cursor: pointer;
  align-self: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp:8;
  -moz-line-clamp: 8;
  -ms-line-clamp: 8;
  line-clamp: 8;
  overflow: hidden;
  width: calc(100% - 30px);
}

.mediumDetails > span {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #983992;
}

.mediumDetails > h3 {
  margin: 0;
  /* font-size: 24px; */
  width: calc(100% - 15px);
  line-height: 26px;
  letter-spacing: -0.3px;
  align-self: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp:2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.mediumDetails h5 {
  margin: 0;
  font-size: 15px;
  width: 80%;
  line-height: 1.17;
}

.mediumDetails > p {
  word-break: break-word;
  margin: 10px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  width: calc(100% - 20px);
  padding-left: 10px;
  opacity: 0;
}


/* CART BUTTON STYLES */
.cartButton {
  position: absolute;
  top: -5px;
  right: -15px;
  cursor: pointer;
  height: 44px;
  width: 44px;
  z-index: 9999;
}

.cartButton > img {
  height: 24px;
  width: 24px;
}
.alternateCartButton{
  bottom: -5px;
  right: -15px!important;
  top: auto!important;
  left: auto!important;
  height: 44px;
  width: 44px;
  z-index: 9999;
}
/* SECONDARY IMAGE CONTAINER */
.secondaryImageContainer {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.alternateSecondary {
  /* top:5px;
  right: 5px;
  left: auto!important; */
}
.secondaryImageContainer, .alternateSecondary{
  width: 85px;
  height: 85px;
}

/* ANIMATION STYLES */

.primaryImageContainer , .mediumDetails {
  transition: 0.7s ease-in-out;
}
.primaryImageContainer , .mediumDetails  {
  transition:  0.2s ease-in;

}
 .mediumDetails > p {
   transition: opacity 0.7s ease-in-out, opacity 0.2s ease-in;
 }



@media (min-width: 700px) {
  .mediumContainer:hover > div > .primaryImageContainer {
    width: 198px;
    height: 198px;
  }
  .mediumContainer:hover > div > .mediumDetails > p {
    opacity: 1;
  }

  .mediumContainer:hover > .alternateContainer > .mediumDetails {
    margin-top: 145px;
  }
}

@media (max-width: 700px) {

  .mediumWrapper {
    width: 100%;
  }
  .mediumContainer {
    pointer-events: none;
  }
  .mediumDetailsContainer {
    /* max-height: 350px !important;
    height: auto !important;
    flex-direction: unset; */
  }

   /* IMAGE STYLES */
  .primaryImageContainer  {
    /* position: absolute;
    bottom: -50px;
    width: 150px;
    height: 150px;
    right: 0px;
    left: auto;
    margin-top: 105px; */
  }
  .secondaryImageContainer {
    /* left: 5px; */
  }
  .alternateContainer > .primaryImageContainer  {
    /* position: absolute;
    top: -50px;
    left: 0px;
    right: auto;
    height: 150px;
    width: 150px;
    margin-top: 0; */
  }
  .isLimitedMediumDetailsContainer{
    /* margin-top: 22px; */
  }
  .mediumDetails{
    /* padding: 12px 12px 0 12px;
    width: calc(100% - 24px);
    margin-bottom: 100px; */

  }
  .alternateContainer > .mediumDetails {
    /* margin-top: 100px;
    padding: 0 12px;
    margin-bottom: 45px; */
  }
  .mediumDetails > h3 {
    /* font-size: 20px; */
  }
  .mediumDetails > p {
    width: calc(100% - 12px);
    padding-left: 6px;
    opacity: 1;
  }

  .secondaryImageContainer, .alternateSecondary {
    /* width: 70px; */
    /* height: 70px; */
  }

  .mediumDetails > h3 {
    /* font-size: 20px; */
  }
}

@media (max-width: 550px) {

  .mediumContainer {
    pointer-events: none;
  }
  .mediumWrapper {
    width: 100%;
  }
  .mediumContainer {
    pointer-events: none;
  }
    /* IMAGE STYLES */
  .primaryImageContainer {
    /* height: 150px;
    width: 150px;
    bottom: -55px;
    right: 0px;
    left: auto;
    margin-top: 100px; */
  }
  .alternateContainer > .primaryImageContainer {
    /* position: absolute;
    top: -55px;
    left: 0px;
    right: auto;
    height: 150px;
    width: 150px;
    margin-top: 0; */
   }
  .isLimitedMediumDetailsContainer{
    /* margin-top: 26px; */
  }
  .mediumDetails{
    /* margin-bottom: 95px; */

  }
  .alternateContainer > .mediumDetails {
   /* margin-top: 95px;
   margin-bottom: 45px; */

  }
  .mediumDetails > h3 {
    /* font-size: 20px; */
  }
  .secondaryImageContainer, .alternateSecondary {
    /* width: 70px; */
    /* height: 70px; */
  }
}

@media (max-width: 415px) {

  .mediumContainer {
    pointer-events: none;
  }
  .mediumWrapper {
    width: 100%;
  }

  .primaryImageContainer {
    /* height: 125px;
    width: 125px;
    bottom: -45px;
    right: 0px;
    left: auto;
    margin-top: 85px; */
  }
  .alternateContainer > .primaryImageContainer {
    /* position: absolute;
    top: -45px;
    left: 0px;
    right: auto;
    height: 125px;
    width: 125px;
    margin-top: 0; */
  }
  .isLimitedMediumDetailsContainer{
    /* margin-top: 26px; */
  }
  .mediumDetails{
    /* margin-bottom: 80px; */

  }
  .alternateContainer > .mediumDetails {
    /* margin-top: 80px;
    margin-bottom: 45px; */

  }
  .mediumDetails > h3 {
    /* font-size: 16px; */
  }
  .secondaryImageContainer, .alternateSecondary {
    /* width: 60px; */
    /* height: 60px; */
  }

}

@media (max-width: 350px) {

  .mediumContainer {
    pointer-events: none;
  }
  .mediumWrapper {
    width: 100%;
  }

  .primaryImageContainer {
    /* height: 100px;
    width: 100px;
    bottom: -35px;
    right: -35px;
    left: auto;
    margin-top: 70px; */
  }
  .alternateContainer > .primaryImageContainer {
    /* position: absolute;
    top: -35px;
    left: -35px;
    right: auto;
    height: 100px;
    width: 100px;
    margin-top: 0; */
  }
  .isLimitedMediumDetailsContainer{
    /* margin-top: 26px; */
  }
  .mediumDetails{
    /* margin-bottom: 70px; */
  }
  .alternateContainer > .mediumDetails {
    /* margin-top: 65px;
    margin-bottom: 45px; */
  }
  .mediumDetails > h3 {
    /* font-size: 16px; */
  }
  .secondaryImageContainer, .alternateSecondary {
    /* width: 50px; */
    /* height: 50px; */
  }
}
