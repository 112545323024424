.featuredLocation {
  display: flex;
  background: var(--light-elevation-background-alt, #F6F3F7);
  align-items: center;
  border-radius: 12px;
  padding: 12px;
  cursor: pointer;
  margin-top: 10px;
  /* margin: 0 15px; */
}

.featuredLocation > img {
  height: 20px;
  /* margin-left: 15px;
  margin-right: 15px; */
}

.featuredLocation > div > p, .featuredLocation > div > h3 {
  margin: 0;
}

.featuredLocation > div > p {
  font-family: 'Filson Pro Regular';
  font-size: 12px;
  margin-bottom: 5px;
  /* color: #966826; */
}

.featuredLocation > div > h3 {
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
  /* color: var(--light-elevation-content-primary, #461D58); */
  text-overflow: ellipsis;
}