.ab-feed {
    width: 100% !important;
    box-shadow: none !important;
    background-color: inherit !important;
}

.ab-feed-buttons-wrapper {
    display: none;
}

.ab-feed-body {
    border: none;
    padding: 0;
}

.ab-pinned-indicator {
    display: none;
}

.ab-feed-body {
    border: none !important;
    padding: 0 !important;
}

.ab-no-cards-message {
    display: none;
}
