.checkBoxWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  line-height: 16px;
}

.checkBoxWrapper > div {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxWrapper > div > img {
  /* height: 80%; */
}

.checkBoxWrapper > span {
  /* font-size: 16px;
  line-height: 20px;
  font-weight: 700; */
}

/* ACTION STYLES */
.isChecked {

}
