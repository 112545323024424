.doughProPayments {
  max-width: 1000px;
  margin: 30px auto;
}

.doughProPayments h2 {
  font-family: 'Congenial Black';
  color: #461d58;
  text-align: center;
}

table {
  width: 100%;
  font-size: 16px;
  font-family: "Filson Pro Regular";
  line-height: 1.5;
  color: #966826;
  border-collapse: separate;
  border-spacing: 0;
}
th {
  font-family: "Filson Pro Bold";
  color: #461d58;
}
table tr th,
table tr td {
  border-right: 1px solid #d5b88a;
  border-bottom: 1px solid #d5b88a;
  padding: 5px;
}
table tr th:first-child,
table tr th:last-child {
  border-top: solid 1px #d5b88a;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #d5b88a;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #d5b88a;
}
td {
  text-align: center !important;
}
table tr th,
table tr:first-child td {
  border-top: 1px solid #d5b88a;
}
table tr:first-child th:first-child,
table tr:first-child td:first-child {
  border-top-left-radius: 8px;
}
table tr:first-child th:last-child,
table tr:first-child td:last-child {
  border-top-right-radius: 8px;
}
table tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
table tfoot > tr > td:first-child {
  text-align: left !important;
}