.blog {
  padding: 50px;
  margin-top: 50px;
  width: 100%;
  height: 700px;
  background-color: #d9cee7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder {
  font-size: 34px;
  font-weight: bold;
}
