.sectionsWrapper {
  background: linear-gradient(180deg, #481f5a 0%, #6c2a73 33%, #6c2a73 66%, #481f5a 100%);
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

/***  Layout  ***/
.pageSection {
  max-width: 1600px;
  margin: 8em auto;
}

.spacing > * + * {
  margin-top: 2rem;
}

/***  Buttons  ***/
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 42px;
  border: 1.5px solid #ba935a;
  background-color: #f3c244;
  box-shadow: 4px 5px 0 1px #bc945a;
  color: black;
  border-radius: 30px;
  cursor: pointer;
}

.buttonContainer a {
  text-decoration: none;
  color: black;
}
  
@media (min-width: 700px) {
  .buttonContainer {
    width: 130px;
  }
}
  
.longButton {
    width: 220px;
}

/***  Hero Section  ***/
.hero {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}
@media (min-width: 700px) {
  .hero {
    flex-direction: row;
  }
}

.heroTextContainer {
  display: flex;
  width: 100%;
}
@media (min-width: 700px) {
  .heroTextContainer {
    width: 100%;
    justify-content: center;
  }
}

.heroImageContainer {
  width: 90%;
  display: flex;
  overflow: hidden;
}
.heroImageContainer img {
  align-self: flex-end;
  border-radius: 18px;
}
@media (min-width: 700px) {
  .heroImageContainer img {
    width: 1000px;
    min-width: 500px;
    padding: 0;
    transition: border-radius 250ms linear;
    border-radius: 20px 0 0 20px;
    min-width: 500px;
  }
}
@media (min-width: 1600px) {
  .heroImageContainer img {
    border-radius: 0 27px 27px 0;
  }
}

.heroText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2em;
  text-align: center;
}
.heroText h1 {
  max-width: 350px;
  font-size: 74px;
  font-family: Filson Pro Bold;
  margin-bottom: 0;
}
.heroText p {
  max-width: 400px;
  font-size: 24px;
  font-family: Filson Pro Regular;
  line-height: 32px;
  margin-bottom: 0;
}
.heroText a {
  font-size: 18px;
  font-family: Filson Pro Regular;
}
@media (min-width: 700px) {
  .heroText {
    text-align: left;
    width: initial;
    align-items: flex-start;
  }
}

/***  Cookie Magic Card Section  ***/
.cookieMagic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 4em auto;
}
.cookieMagic > h2 {
  color: #fff;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 0;
}
@media (min-width: 700px) {
  .cookieMagic {
    margin: 8em auto;
  }

  .cookieMagic h2 {
    margin-bottom: initial;
  }
}
.cookieMagic > img {
  width: 80%;
}
@media (min-width: 700px) {
  .cookieMagic > img {
    max-width: 600px;
    min-width: 300px;
    width: 50%;
  }
}
.cookieMagic > h2 {
  width: 90%;
  max-width: 400px;
}

/* Bullets */
.cmFeatures {
  margin: 0 auto;
  text-align: left;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 0 auto;
}

@media (min-width: 700px) {
  .cmFeatures {
    max-width: 800px;
    margin: 2em auto;
  }
}
@media (min-width: 1200px) {
  .cmFeatures {
    max-width: 1200px;
  }
}

.bullets {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bullets h5 {
  font-size: 19px;
  font-size: clamp(1.2rem, 5vw - 0.5rem, 1.5rem);
  color: #ffffff;
  font-family: "Filson Pro Bold";
  margin: 0;
}

.bullets li {
  display: flex;
  justify-content: flex-start;
  min-width: 260px;
  max-width: 480px;
}

.bullets li img {
  width: 16px;
  margin-top: 4px;
  max-width: initial;
}

.bullets li > .bulletStarLeft {
  margin-right: 10px;
}


/***  Gift Cards Section  ***/
.giftcards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 7em;
}
@media (min-width: 700px) {
  .giftcards {
    flex-direction: row;
  }
}

.giftcardSpread {
  position: relative;
  flex: 2;
  padding: 6em 0;
  width: 100%;
  display: flex;
}
.giftcardSpreadImage {
  border-radius: 14px;
  position: absolute;
  width: 40%;
  top: 50%;
  left: 40%;
  min-width: 222px;
  max-width: 242px;
}
@media (min-width: 700px) {
  .giftcardSpreadImage {
    width: 60%;
    max-width: 1000px;
    border-radius: 20px;
  }
}
.giftcardSpreadBack {
  transform: rotate(-40deg) translate(-29%, -120%);
}
.giftcardSpreadMiddle {
  transform: rotate(-20deg) translate(-17%, -65%);
}

.giftcardTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.giftcardTextContainer a {
  text-decoration: none;
}

@media (min-width: 700px) {
  .giftcardTextContainer {
    width: 50%;
  }
}

.giftcardText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 6em;
  align-items: center;
  text-align: center;
}

.giftcardText h2 {
  font-family: "Filson Pro Regular";
  font-weight: 100;
  line-height: 1.4;
  width: 90%;
  color: #fff;
  font-size: 36px;
  line-height: 48px;
}

.giftcardText h2 strong {
  font-family: "Filson Pro Bold";
  font-size: 45px;
}

@media (min-width: 700px) {
  .giftcardText {
    text-align: left;
    max-width: 500px;
    padding: 6em 2em 0;
    align-items: flex-start;
    width: 50vw;
  }
}
