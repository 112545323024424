.smallContainer {
  width: 100%;
  height: 109px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
}

.imageContainer {
  cursor: pointer;
}

.imageContainer > img {
  height: 120px;
  position: relative;
  top: -15px;
  right: 15px;
}

.smallDetails {
  align-self: center;
  cursor: pointer;
  padding-left: 5px;
}

.smallDetails > h3,
.smallDetails > span {
  margin: 0;
  font-family: 'Filson Pro Bold';
}

.smallDetails > span {
  font-size: 18px;
  margin-bottom: 6px;
  line-height: 1.33;
}

.smallDetails > h3 {
  font-size: 22px;
  width: calc(100% - 5px);
  line-height: 1.17;
  align-self: center;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp:3;
  -moz-line-clamp: 3;
  -ms-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.smallDetails h5 {
  margin: 0;
  font-size: 15px;
  width: 80%;
  line-height: 1.17;
}

.cartButton {
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 9999;
  right: -15px;
  top: -15px;
}

.cartButton > img {
  
}

@media (max-width: 700px) {
  .smallContainer {
    max-height: 109px!important;
    height:auto!important;
  }
  .smallContainer {
    width: 100%;
    justify-content: flex-start;
  }
  .smallDetails > h3 {
    font-size: 20px;
  }
  .cartButton {
    width: 44px;
    height: 44px;
    position: absolute;
    top: -15px;
    right: -10px;
  }
  .imageContainer > img {
    height: 100px;
  }
}

@media (max-width: 500px) {
  .smallContainer {
    max-height: 109px!important;
    height:auto!important;
  }
  .smallContainer {
    margin-bottom: 62px;
  }
  .imageContainer > img {
    height: 80px;
  }
  .smallDetails > h3 {
    font-size: 16px;
  }
}
