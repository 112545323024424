.pageWrapper {}

.topContainer {
    background-image: url("./imgs/top-bg.svg");
    height: 340px;
    width: 100%;
    background-size: cover;
}

.loyaltyPoints {
    background-image: url("./imgs/loyalty-point-bg.svg");
    max-width: 860px;
    height: 302px;
}

.pageContainer {
    max-width: 860px;
    margin-top: 20px;
}

.loyaltyPointsCards {
    width: 128px;
    height: 88px;
    border: 1px solid #2C0C37;
    background-color: #461D58;
    border-radius: 20px;
    border-color: #2C0C37;
    color: #FFFFFF;
    font-family: 'Filson Pro Regular';
    font-weight: 700;
    padding: 25px 20px;
    text-align: center;
}

.loyaltyPointsCards>p:first-child {
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.3px !important;
    font-weight: 700 !important;
    color: #FFFFFF !important;
}

.loyaltyPointsCards>p>small {
    font-size: 14px !important;
    line-height: 18px !important;
}

.loyaltyPointsCards>p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 425;
    color: #B4A5BB;
}

.lockedProduct {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.expiredTime {
    /* expired time */
}

@media only screen and (max-width: 600px) {
    .topContainer {
        background-image: url("./imgs/top-bg-mobile.png");
        height: 486px;
        width: 100%;
    }

    .loyaltyPoints {
        background-image: url("./imgs/loyalty-point-bg-mobile.svg");
        width: 363px;
        height: 220px;
    }

    .loyaltyPointsCards {
        width: 109px;
        height: 88px;
        border: 1px solid #2C0C37;
        background-color: #461D58;
        border-radius: 20px;
        border-color: #2C0C37;
        color: #FFFFFF;
        font-family: 'Filson Pro Regular';
        font-weight: 700;
        padding: 20px 15px;
        text-align: center;
    }
}

@media only screen and (max-width: 425px) {
    .topContainer {
        background-image: url("./imgs/top-bg-mobile.png");
        height: 486px;
        width: 100%;
        background-size: cover;
    }

    .loyaltyPoints {
        background-image: url("./imgs/loyalty-point-bg-mobile.png");
        width: 363px;
        height: 220px;
    }

    .loyaltyPointsCards {
        width: 109px;
        height: 88px;
        border: 1px solid #2C0C37;
        background-color: #461D58;
        border-radius: 20px;
        border-color: #2C0C37;
        color: #FFFFFF;
        font-family: 'Filson Pro Regular';
        font-weight: 700;
        padding: 20px 0px;
        text-align: center;
    }
}