.dropDownRow {
  display: flex;
}
.inputStyle {
  margin-top: -20px;
}
.selectStyle {
  width: 200px;
  margin-right: 20px;
}
.selectStyle > div {
  /* background: #faf7f2; */
}
.selectStyle > div:first-of-type {
  height: 53px;
}
.dropDownPlaceholder {
  left: 10px;
}
.selectStyle > div:nth-of-type(2) > div {
  padding-left: 19px;
}
.dropDownPlaceholder::before {
  content: "Country code" !important;
  white-space: nowrap;
  font-family: "Filson Pro Regular";
  position: absolute;
  /* color: #966826; */
  font-size: 12px;
  top: -18px;
}
.customInputWrapperLight > div:first-child {
  /* border: none;
  background: #faf7f2; */
}
