
.sectionHeader,
.sectionSubHeader {

}

.sectionHeader {
  font-size: 18px;
  margin: 0;
  margin-bottom: 13px;
  line-height: 1.33;
}

.selectionButton {
  width: 100%;
  margin: 64px 0 15px;
}

.dropDownRow {
  display: flex;
}

.dropDownRow > div:first-of-type {

}
.dropDownRow > div:first-of-type {

}

.dropDownPlaceholderDay::before {
  content: 'Day' !important;
}

.dropDownPlaceholderMonth::before {
  content: 'Month' !important;
}

.dropDownPlaceholderYear::before {
  content: 'Year' !important;
}

.error {
  display: block;
  margin-top: 30px;
  font-family: 'Filson Pro Bold';
}

.error {
  color: #d21c38;
}