.listItemWrapper, .listItemContainer {
  width: 100%;
  /* cursor: pointer; */
}

.listItemContainer:hover {
  /* box-shadow:  0 1px 9px 3px rgba(213, 184, 138, 0.54); */
}



.listItemContainer {
  /* border-top: 1px solid #d5b88a; */
  /* padding: 30px; */
  position: relative;
  cursor: pointer;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.listItemContainer > p {
  font-family: 'Filson Pro Bold';
  color: #ce4396;
  width: 50%;
  font-size: 12px;
}

.listItemHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.listItemHeader {
  font-family: 'Filson Pro Regular';
  font-weight: 16px;
  font-size: 16px;
  line-height: 20px;
}

.listItemHeader > h3, .listItemHeader > p {
  margin: 0;
}

.listItemHeader > h3 > span > img {
  height: 18px;
  margin-left: 8px;
  position: relative;
  /* top: 3px; */
  cursor: pointer;
}

.listItemHeader > p, .listItemHeader > p > span {
  font-family: 'Filson Pro Regular';
  font-size: 14px;
  line-height: 16px;
  font-weight: 425;
  white-space: nowrap;
}

.listContactLink {
 /* color:  #966826; */
}

.listItemLocation {
  margin-top: 10px;
}

.listItemLocation > p {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
}

.listItemLocation > p:last-child {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  padding: 0;
}

/* CONDITIONAL RENDER STYLES */

.isClosed > span {
  color: #ce4396 !important;
}

.customButtonContainer {
  height: 32px !important;
  width: 100px !important;
  background: transparent !important;
  padding: 4px 0 !important;
  right: 20px;
}
.customButtonContainer > p {
  font-family: 'Filson Pro Regular' !important;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center;
  margin: 0 !important;
  color: #ce4396 ;
}

@media (max-width: 900px) {
  .listItemContainer {
    /* padding: 30px 20px; */
  }

  .listItemHeader > h3 {
    font-size: 16px;
  }

  /* .listItemHeader > p, .listItemLocation > p {
    font-size: 14px;
  } */

  .listItemContainer > p {
    width: 95%;
  }

}

@media (max-width: 500px) {
  .listItemLocation > p {
    width: 60%;
    /* margin-bottom: 5px; */
  }

  /* .customButtonContainer {
    padding: 0;
  }

  .customButtonContainer > p {
    font-size: 14px !important;
  }

  .listItemContainer > .customButtonContainer {
    opacity: 1;
  } */
}
