@font-face {
  font-family: 'Congenial Black';
  src: url('./static/fonts/Congenial/Congenial-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Bold';
  src: url('./static/fonts/SofiaPro/Sofia-Pro-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Regular';
  src: url('./static/fonts/SofiaPro/Sofia-Pro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro soft Bold';
  src: url('./static/fonts/SofiaPro/Sofia-Pro-Soft-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro soft Regular';
  src: url('./static/fonts/SofiaPro/Sofia-Pro-Soft-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro Regular';
  src: url('./static/fonts/MyriadPro/MyriadPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Arya Font faces */

@font-face {
  font-family: 'Arya-Double-Slant';
  src: url('./static/fonts/Arya/Arya-Double-Slant.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arya-Double';
  src: url('./static/fonts/Arya/Arya-Double.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arya-Sigle-Slant';
  src: url('./static/fonts/Arya/Arya-Sigle-Slant.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arya-Single';
  src: url('./static/fonts/Arya/Arya-Single.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Congenial Font faces */

@font-face {
  font-family: 'Congenial-Black';
  src: url('./static/fonts/Congenial/congenial_black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Bold';
  src: url('./static/fonts/Congenial/congenial_bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Extra-Light';
  src: url('./static/fonts/Congenial/congenial_extralight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Hairline';
  src: url('./static/fonts/Congenial/congenial_hairline.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Heavy';
  src: url('./static/fonts/Congenial/congenial_heavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Light';
  src: url('./static/fonts/Congenial/congenial_light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Medium';
  src: url('./static/fonts/Congenial/congenial_medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Regular';
  src: url('./static/fonts/Congenial/congenial_regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Thin';
  src: url('./static/fonts/Congenial/congenial_thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Congenial-Ultra-Light';
  src: url('./static/fonts/Congenial/congenial_ultralight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Filson Font faces */
@font-face {
  font-family: 'Filson Pro Black Italic';
  src: url('./static/fonts/Filson/Filson Pro Black Italic.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Black';
  src: url('./static/fonts/Filson/Filson Pro Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Bold Italic';
  src: url('./static/fonts/Filson/Filson Pro Bold Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro Bold';
  src: url('./static/fonts/Filson/Filson Pro Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Book Italic';
  src: url('./static/fonts/Filson/Filson Pro Book Italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro Book';
  src: url('./static/fonts/Filson/Filson Pro Book.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Heavy Italic';
  src: url('./static/fonts/Filson/Filson Pro Heavy Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro Heavy';
  src: url('./static/fonts/Filson/Filson Pro Heavy.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Medium Italic';
  src: url('./static/fonts/Filson/Filson Pro Medium Italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro Medium';
  src: url('./static/fonts/Filson/Filson Pro Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Filson Pro Regular Italic';
  src: url('./static/fonts/Filson/Filson Pro Regular Italic.otf') format('opentype');
  font-weight: 425;
  font-style: italic;
}

@font-face {
  font-family: 'Filson Pro Regular';
  src: url('./static/fonts/Filson/Filson Pro Regular.otf') format('opentype');
  font-weight: 425;
  font-style: normal;
}




/* HEADER DEFAULTS */
h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

/* BUTTON DEFAULTS */
button {
  font-weight: normal;
}

#kustomer-ui-sdk-iframe {
  margin-bottom: 28px !important;
}

.dark .dark\:image-negative {
  filter: invert(1);
}

.userway_buttons_wrapper {
  margin-bottom: 55px;
  margin-left: 5px;
  left: 0 !important;
}
