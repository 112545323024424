.videoContainer {
    position: relative;
    height: 450px;
    overflow: hidden;
}

.videoContainer video {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    clip-path: inset(0px 0px 0px 0px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
    .videoContainer {
        margin-top: 4px;
    }
}

@media (max-width: 576px) {
    .videoContainer {
        height: 570px;
        margin-top: 4px;
    }
}

@media (max-width: 360px) {
    .videoContainer {
        height: 450px;
    }
}

.fadeOut {
    opacity: 0;
    transition: width 0.8s 0.8s, height 0.8s 0.8s, opacity 0.8s;
}


