.quickCheckoutButton {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  z-index: 100 !important;
  box-shadow:  4px 5px 0 1px #BC945A;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #fffcfc;
  border: solid 1px #bc945a;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 25px;
}

.quickCheckoutButton > p, .quickCheckoutButton > div > p {
  margin: 0;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #1a0021;
}

.labelTitle {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.quickCheckoutButton > p > span {
  opacity: 0;
}

@media (max-width: 600px) {

  .labelTitle > p:last-of-type {
    display: block;
  }
}

@media (min-width: 1000px) {
  .quickCheckoutButton {
    display: none;
  }
}

@media (max-width: 350px) {
  .quickCheckoutButton{
    height: 50px;
  }
  .quickCheckoutButton > p, .quickCheckoutButton > div > p {
    font-size: 14px;
  }
}

.widgetWrapper {
  width: 460px;
  margin: 0 auto;
  padding: 20px 0 30px;
}

.quickCheckoutButtonWrapper {
  display: block;
}
