.checkoutContainerWrapper {
  /* background-color: #fbf5ee; */
}

.checkoutContainerCollapsedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkoutContainerCollapsedContainer > img {
  margin-right: 5px;
}

/* HEADER STYLES */

.checkoutContainerLeft {
  font-family: "Filson Pro Bold";
}

.checkoutContainerLeft > p {
  font-size: 16px;
  line-height: 1.5;
  color: #461d58;
  margin: 0;
}

.checkoutContainerLeft > span {
  font-size: 12px;
  line-height: 1.33;
  position: relative;
  top: -3px;
}
.checkoutContainerLeft > span:empty:before {
  content: "\200b";
}

.checkoutContainerLeft > p > span > img {
  margin-right: 5px;
  height: 12px;
}

.dropDownCheckContainer {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ba935a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownCheckContainer > img {
  height: 9px;
}

.containerDropDownChildren {
  font-family: "Filson Pro Bold";
}

.checkoutContainerRight {
  display: flex;
}

#alertIcon {
  height: 16px;
  margin-right: 10px;
}

/* ACTION STYLES */

.containerInteractive {
  cursor: pointer;
}

.containerDisabled,
.containerDisabled div {
  cursor: not-allowed;
}

.containerExpanded
  > .checkoutContainerCollapsedContainer
  > .checkoutContainerRight
  > img {
  transform: rotate(180deg);
}

.checkSelected {
  border: none;
  border: 1px solid #f3c244;
  background-color: #f3c244 !important;
}

.alert {
  color: #D21C38 !important;
}

.navigate {
  transform: rotate(-90deg);
}
