.pageTop {
  background-image: url('./imgs/hero_bg.svg');
  background-size: cover;
  display: flex;
  height: 500px;
  align-items: center;
  justify-content: space-between;
  padding: 0 175px;
}

/* TOP LEFT HERO STYLES */

.topLeft {
  position: relative;
  top: -75px;
}

.topLeftConfirmation {
  position: relative;
  top: -50px;
  width: 100%;
}

.topLeft > .goBack {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin-bottom: 34px;
}

.topLeft > .goBack > img {
  position: absolute;
  top: -2px;
  left: -30px;
}

.topLeft > h1 {
  font-size: 70px;
  font-family: 'Congenial Black';
  line-height: 1.03;
  color: #fffdfb;
  margin: 0;
  margin-bottom: 20px;
}

.topLeft > p {
  width: 70%;
  font-size: 24px;
  margin: 0;
  font-family: 'Filson Pro Regular';
  line-height: 1.17;
  color: #ffc0e5;
}

.topLeftConfirmation > div {
  display: flex;
  align-items: center;
}

.topLeftConfirmation > div > .heroButtonContainer {
  width: 130px;
  height: 48px;
  margin-top: 34px;
}

.topLeftConfirmation > div > p {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  margin: 0;
  position: relative;
  left: 36px;
  top: 20px;
  cursor: pointer;
}

/* TOP RIGHT HERO STYLES */

.topRight > img {
  height: 500px;
  position: relative;
  top: 2px;
}

.topRightConfirmation {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.topRightConfirmation > img {
  height: 350px;
}

/* BOTTOM STYLES */

.pageBottom {
  margin: 50px auto;
  width: 50%;
}
.pageBottom>div>div>div{
  border: none;
}
.pageBottom > p {
  margin: 0;
  margin-bottom: 10px;
  font-family: "Filson Pro Bold";
  font-size: 18px;
  line-height: 1.33;
  color: #966826;
}

.pageBottom > p:last-of-type {
  margin-top: 45px;
}

.pageBottom > span {
  font-family: 'Filson Pro Bold';
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
}

.inputWrapper {
  width: 100%;
}

.inputWrapper > div > input {
  color: #461d58 !important;
}

.submitButton {
  margin-top: 65px;
}

/* ACTION STATES */

.error, .success {
  display: block;
  margin-top: 10px;
  font-size: 14px !important;
  font-family: 'Filson Pro Bold';
}

.error {
  color: #d21c38 !important;
}

.success {
  color: #ce4396 !important;
}


@media (max-width: 1400px) {
  .pageTop {
    padding: 0 120px;
  }
}

@media (max-width: 1150px) {
  .pageTop {
    padding: 0 70px;
  }
  .pageBottom {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .pageTop {
    padding: 0 50px;
  }
  .pageBottom {
    width: 70%;
  }
}

@media (max-width: 910px) {
  .pageTop {
    position: relative;
    top: -25px;
    padding: 0 26px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
  }

  /* TOP LEFT SYLES */
  .topLeft {
    top: 25px;
  }

  .topLeft > h1 {
    font-size: 50px;
  }

  .topLeft > p {
    width: 90%;
  }

  /* TOP RIGHT STYLES */
  .topRight > img {
    height: 400px;
  }
  .topRight {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  /* BOTTOM STYLES */
  .pageBottom {
    width: 100%;
    padding: 0 26px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
  }
}

@media (max-width: 350px) {
  .pageTop {
    top: -38px;
  }
}