.cmsEditMode, .cmsEditModeMobile  {
  align-items: center;
  zoom: 0.9;
}

.cmsEditMode {
  display: flex;
}

.cmsEditModeMobile {
  display: none;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .cmsEditMode {
    display: none;
  }

  .cmsEditModeMobile {
    display: flex !important;
  }
}
