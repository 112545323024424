.orderRemovalWrapper {
  width: 550px;
  margin: 0 auto;
  padding: 71px 40px 35px;
}

.orderRemovalWrapper > span {
  font-size: 16px;
  font-family: 'Filson Pro Bold';
  line-height: 1.5;
  color: #ce4396;
}

.orderRemovalWrapper > h3 {
  font-size: 24px;
  font-family: 'Filson Pro Bold';
  line-height: 1.17;
  color: #461d58;
  margin: 14px 0 5px;
}

.orderRemovalWrapper > p {
  font-size: 16px;
  font-family: 'Filson Pro Regular';
  line-height: 1.5;
  color: #966826;
  margin: 0;
  margin-bottom: 21px;
}

.orderRemovalActions {
  display: flex;
  justify-content: flex-end;
}

.orderRemovalActions > button {
  border-radius: 22.5px;
  font-family: 'Filson Pro Bold';
  background-color: #f3c244;
  padding: 0;
  font-size: 14px;
  line-height: 1.33;
  color: #1a0021;
  padding: 10px 14px;
  margin-left: 15px;
}

.productContainer {
  border-radius: 8px;
  border: solid 1px #d5b88a;
  margin-bottom: 10px;
}

.productContainer > p {
  padding: 8px 24px;
  font-family: 'Filson Pro Bold';
  font-size: 14px;
  line-height: 1.43;
  color: #461d58;
  margin: 0;
}

@media (max-width: 600px) {
  .orderRemovalWrapper {
    width: 100%;
  }

  .orderRemovalWrapper {
    padding: 71px 20px 35px;
  }
}
