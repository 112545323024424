.cmsInlinEdit {
    position: relative;
    border: 1px solid transparent;
    z-index: 10;
}

.cmsInlinEdit:hover {
    border: 1px solid red;
}

.cmsInlinEdit:hover .cmsInlinEditHeaderIcon {
    display: block;
    position: absolute;
    cursor: pointer;
}

.cmsInlinEditHeader {
    display: flex;
    justify-content: flex-end;
}

.cmsInlinEditHeaderIcon {
    display: none;
    background: red;
    padding: 5px;
}

.cmsInlinEditHeader svg {
    width: 15px;
}

.cmsInlinEditHeaderIcon svg {
    fill: #fff;
}
