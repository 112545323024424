.ribbonHeader {
  display: flex;
}

.ribbonHeader .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
  width: 100%;
}

.ribbonHeader .row p {
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Filson Pro Regular', sans-serif;
}
.ribbonHeader .row p label span:nth-child(1) label{
  /* padding: 0 4px; */
}
.ribbonHeader .row p label span:nth-child(2) label{
  /* padding: 0 4px; */
}

@media (max-width: 570px) {
  /* .ribbonHeader .row p {font-size: 12px} */
  .ribbonHeader .row { padding: 10px 10px}
}

@media (max-width: 320px) {
  .ribbonHeader .row { padding: 10px 10px}
}

