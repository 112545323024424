.pageContainer {
  background-image: url('./imgs/bg.svg');
  background-size: cover;
  padding: 50px 169px 20px;
}

.pageTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftContainer, .rightContainer {
  height: 100%;
}

/* LEFT CONTAINER STYLES */
.leftContainer {
  position: relative;
  top: -50px;
}

.leftContainer > h1, .leftContainer > p {
  margin: 0
}

.leftContainer > h1 {
  font-family: 'Congenial Black';
  font-size: 70px;
  line-height: 1.03;
  color: #fffdfb;
  margin-bottom: 20px;
}

.leftContainer > h1 > span {
  display: inline-block;
  margin-bottom: -8px;
  border-bottom: 2px solid #fffdfb;
}

.leftContainer > p {
  font-family: 'Filson Pro Regular';
  font-size: 24px;
  line-height: 1.17;
  color: #ffc0e5;
  margin-bottom: 25px;
}

.leftContainer > p > span > img {
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.leftContainer > .buttonContainer {
  width: 93px !important;
  height: 48px !important;
}

/* RIGHT CONTAINER STYLES */
.rightContainer > img {
  height: 400px;
}

/* PAGE BOTTOM STYLES */

.pageBottom {
  border-top: solid 3px #7f3083;
  padding-top: 20px;
}

.bottomDirections {
  width: 100%;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.bottomDirections > p {
  font-size: 12px;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
  color: #fffdfb;
  margin: 0;
  margin-left: 12px;
}

.bottomDirections > p > span {
  display: inline-block;
  margin-left: 5px;
  font-family: 'Filson Pro Bold';
  text-decoration: underline;
}

.bottomDirections > div {
  border-radius: 8px;
  background-color: rgba(255, 253, 251, 0.23);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
}

.bottomDirections > div > a {
  font-size: 12px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #fffdfb;
  align-self: center;
}

@media (max-width: 1300px) {
  .pageContainer {
    padding: 50px 100px 20px;
  }  
}

@media (max-width: 1150px) {
  .pageContainer {
    padding: 50px 50px 20px;
  }  
}

@media (max-width: 1050px) {
  .leftContainer > h1 {
    font-size: 60px;
  }
  
  .leftContainer > p {
    font-size: 20px;
  }

  .rightContainer > img {
    height: 350px;
  }
}

@media (max-width: 900px) {
  .pageWrapper {
    position: relative;
    top: -25px;
  }
  .pageContainer {
    background-image: url('./imgs/bg-mobile.svg');
  }
  .pageTop {
    flex-direction: column;
    align-items: flex-start;
  }
  .leftContainer {
    top: 0;
  }
  .rightContainer {
    align-self: flex-end !important;
  }
}

@media (max-width: 600px) {
  .leftContainer > h1 {
    font-size: 42px;
    font-weight: 900;
    line-height: 1.14;
    margin-bottom: 10px;
  }
  
  .leftContainer > p {
    line-height: 1.17;
    margin-bottom: 15px;
  }

  .rightContainer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }

  .rightContainer > img {
    height: auto;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .pageContainer {
    padding: 50px 20px 20px;
  }  
}

@media (max-width: 350px) {
  .bottomDirections > p {
    font-size: 11px;
  }
  
  .bottomDirections > div > a {
    font-size: 11px;
  }
}

@media (max-width: 320px) {
  .pageWrapper {
    top: -40px;
  }
}