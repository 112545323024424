.pageWrapper {
    width: 100%;
}

.pageContainer {
    min-height: 70vh;
    margin-top:5%;
}

.pageLeftContainer{
    max-width:25%;
    width:100%;
    padding: 15px;
    border-radius: 8px;
    border: solid 1px #ba935a;
    background-color: #fffdfb;
    box-shadow: 4px 5px 0 1px #bc945a;
}

.pageRightContainer{
    margin-left: 35px;
    max-width:35%;
    width: 100%;
}

.pageLoaderWrapper {
    height: 50vh;
    width: 100%;
}

.loaderWrapper {
    position: absolute;
    top: 9%;
    left: 46%;
    z-index: 101 !important;
}

.giftCardContainer {
    display: flex;
    justify-content: center;
}
.datePickerWrapper {
    margin-left:-14px;
    max-width:97%;
    width: 100%;
}

.giftCardImg {
    display: flex;
    justify-content: center;
}
.giftCardImg img {
    max-width: 400px;
    width: 100%;
}

.giftCardDescriptions {
    margin-left:10%;
}

.giftCardDescriptions h4 {
    font-weight: 700;
    font-family: 'Filson Pro Regular';
    color:#461d58;
}
.giftCardDescriptions p, h2 {
    font-weight: 700!important;
    font-family: 'Filson Pro Regular';
    /* color: #966826; */
}

.inputsWrapper {
    display: flex;
    justify-content: space-between;

}
.mediumInputs {
    max-width: 48%;
    width: 100%;
}

.largeInputs {
    width:100%;
}

.customDropDown{
    margin-top:20px;
    max-width: 48%;
    width:100%;
}
.disabledInputs {
    cursor: not-allowed !important;
    color:#461d58c4 !important;
}
.disabledInputs input {
    color:#461d58c4 !important;
}

.dropDownPlaceHolder {
    color: #966826 !important;
}
.dropDownPlaceHolder::before {
    content: 'Amout' !important;
}
.customButton{
    position: relative;
    margin-top:13%;
}

.pickerLabel {
    padding-top:20px;
}

.errorWrapper {
    color: #d21c38;
    font-family: 'Filson Pro Bold';
    margin:15px;
    font-weight: bold;
}
@media only screen and (max-width: 1100px) {
    .giftCardContainer{
        flex-wrap: wrap;
    }

    .pageRightContainer{
        max-width: 45%;
    }

    .pageLeftContainer {
        max-width: 40%;
    }
}

@media only screen and (max-width: 900px) {
    .pageRightContainer{
        max-width: 60%;
        margin-top:40px;
        margin-left: 0;
    }

    .pageLeftContainer {
        max-width: 60%;

    }
}

@media only screen and (max-width: 768px) {
    .pageRightContainer {
        max-width: 90%;
    }
}

@media only screen and (max-width: 576px) {
    .pageLeftContainer {
        max-width: 80%;
    }

    .inputsWrapper {
        flex-wrap:wrap;
    }

    .datePickerWrapper {
        max-width: 96%;
    }
    .mediumInputs, .customDropDown {
        max-width:100%;
    }

    .giftCardImg {
        max-width: 300px;
    }

    .giftCardDescriptions {
        margin-left: 0;
    }

    .giftCardDescriptions p,h4,h2 {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }

    .customButton {
        margin-bottom:10%;
    }
}

@media only screen and (max-width: 480px) {
    .datePickerWrapper {
        max-width: 93%;
    }
}