.widgetWrapper .productImage {
  width: 30%;
  position: relative;
  margin: 10px auto;
  display: block;
}

.widgetContainer {
  font-family: 'Filson Pro Bold';
}

.widgetContainer .loadingHeaderMessage {
  font-size: 25px;
}

.widgetContainer > h2, .widgetContainer > h3 {
  color: #461d58;
  font-size: 28px;
  text-align: center;
}

.widgetContainer > h3 {
  font-size: 18px;
}

.widgetContainer > hr {
  color: #966826;
}

.widgetContainer > .detailFields {
  font-size: 18px;
  margin: 0 25px;
}

.widgetContainer > .detailFields > p {
  color: #966826;
}

.widgetContainer > .detailFields > p > span.title {
  color: #461d58;
}

.widgetContainer > .detailFields > p > span.text {
  display: block;
  margin-left: 20px;
  padding-top: 5px;
}

.widgetContainer > .detailFields > p > span.number {
  float: right;
}

.footerButtonWrapper {
  text-align: right;
  margin-right: 5px;
}

.errorMessage {
  text-align: center;
  color: #d21c38;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  margin-top: 30px;
  margin-bottom: 0;
}

.actionButton {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  cursor: pointer;
  z-index: 100 !important;
  border-radius: 30px;
  border: solid 2px #f3c244;
  display: inline-flex;
  align-items: center;
  color: #461d58;
  width: 166px;
  height: 45px;
  font-size: 16px;
  margin-right: 10px;
  text-decoration: none;
}

.editDetailsButton {
  background-color: #ffffff;
}

.placeOrderButton {
  background-color: #f3c244;
}

.actionButton > div {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.actionButton > div > p {
  margin: 0;
  font-size: 16px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #1a0021;
}

@media (max-width: 450px) {
  .actionButton {
    width: 120px;
  }
}
