.deliveryInputContainer > input {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}
.deliveryPostCodeSearchMessage {
  padding: 0 32px;
  margin-bottom: 5px;
}

.deliveryPostCodeSearchMessage > div.postcodeMessageTitle {
  font-family: "Filson Pro Medium";
  font-size: 18px;
  /* color: #461d58; */
  padding: 3px 0;
}

.deliveryPostCodeSearchMessage > div.postcodeMessageSubtitle {
  font-family: "Filson Pro Regular";
  font-size: 16px;
  color: #966826;
  padding: 3px 0;
}

.widgetType {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
}

.widgetType {
  /* border: 1px solid #bc945a; */
  border-radius: 22.5px;
  margin-bottom: 15px;
}

.loaderWrapper {
  position: relative;
  top: -5px;
}

.widgetType > p {
  /* color: #d5b88a; */
  font-family: "Filson Pro Medium";
  margin: 0;
  width: 50%;
  text-align: center;
  padding: 10px 50px;
  cursor: pointer;
}

.chosenType {
  /* color: #1a0021 !important; */
  /* background-color: #f3c244; */
  border-radius: 22.5px;
}

.deliveryInputContainer,
.pickUpInputContainer {
  /* margin: 0 30px; */
}

.locationListContainer {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
}

.deliveryInputContainer,
.pickUpInputContainer > div {
  display: flex;
  /* border: 1px solid #d5b88a; */
  border-radius: 22px;
  position: relative;
  align-items: center;
  padding: 8px 15px;
  margin-bottom: 10px;
}

.deliveryInputContainer > img {
  height: 20px;
}

.deliveryPickupWidget >.pickupInputContainer > .pickupLocationLoading {
  width: 0;
  border: none;
}

.deliveryPickupWidget >.pickupInputContainer > .pickupLocationLoading  {
  height: 15px;
}

.deliveryInputContainer > img:last-child,
.pickUpInputContainer > div > img:last-child {
  position: absolute;
  right: 15px;
  height: 12px;
  cursor: pointer;
}

.deliveryInputContainer > input,
.pickUpInputContainer > div > input {
  border: none;
  margin-left: 10px;
  color: #966826;
  font-size: 15px;
  width: 100%;
}

.deliveryInputContainer > input:focus,
.pickUpInputContainer > div > input:focus {
  outline: none;
}

.savedLocation {
  margin-top: 20px;
  padding-top: 15px;
}

.savedLocation > p {
  font-family: "Filson Pro Black";
  margin: 0;
  font-size: 14px;
  margin-bottom: 15px;
}

/* PICKUP STYLES */

.pickUpInputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.pickUpInputContainer > div {
  /* width: 80%; */
}

.pickUpInputContainer > img,
.pickUpInputContainer > div > img {
  height: 20px;
}

.pickupLocationIcon {
  height: 20px;
}

.pickUpInputContainer > img {
  position: relative;
  top: -5px;
  cursor: pointer;
}

.pickUpContainer > a {
  color: #ce4396;
  font-family: "Filson Pro Black";
  margin-left: 55px;
  font-size: 12px;
}

.submitButton {
  background-color: #f3c244;
  position: absolute;
  right: 0;
  border-radius: inherit;
  /* color: #461d58; */
  font-family: "Filson Pro Black";
  cursor: pointer;
  text-decoration: none;
}

.locationLoaderContainer {
  /* position: absolute; */
  z-index: 999;
  bottom: 25px;
  background-color: white;
  /* height: 363px; */
  width: 100%;
}

.locationLoader {
  margin: 50px 0;
}

.locationLoader > p {
  font-family: 'Filson Pro Regular';
  color: #D6B88A;
  text-align: center;
}

.submitButton > p {
  text-decoration: none;
  margin: 0;
  font-size: 16px;
  padding: 8px 15px;
}

/* MODAL ACTIONS STYLES */

.orderChangeActions {
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;
}

.orderChangeActions > button {
  border-radius: 22.5px;
  font-family: "Filson Pro Medium";
  background-color: #f3c244;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  color: #1a0021;
  padding: 10px 14px;
  margin-left: 15px;
}

.disabled {
  background-color: #dab783 !important;
  color: #9e660e !important;
}

/* ERROR UI STYLES */

.errorContainer {
  padding: 10px 35px;
}

.errorContainer > p:first-of-type {
  font-family: 'Filson Pro Medium';
  font-size: 18px;
  line-height: 1.17;
  color: #966826;
}

.errorContainer > p:last-of-type {
  font-family: 'Filson Pro Regular';
  font-size: 16px;
  color: #966826;
}

.errorContainer > span {
  font-family: 'Filson Pro Black';
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
  text-decoration: underline;
  cursor: pointer;
}

.productContainer {
  border-radius: 8px;
  border: solid 1px #d5b88a;
  margin-bottom: 10px;
}

.productContainer > p {
  padding: 8px 24px;
  font-family: 'Filson Pro Black';
  font-size: 14px;
  line-height: 1.43;
  /* color: #461d58; */
  margin: 0;
}
.deliveryContainer, .pickUpContainer {
  /* height: 350px; */
  overflow: auto;
}
@media (max-width: 900px) {
  .deliveryContainer, .pickUpContainer {
    /* height: 300px; */
    overflow: auto;
  }
}

@media (max-width: 450px) {
  .widgetType,
  .deliveryInputContainer,
  .pickUpInputContainer {
    /* margin-left: 15px;
    margin-right: 15px; */
  }

  .widgetType > p {
    padding: 10px 20px;
  }
  .pickUpInputContainer > img {
    left: 5px;
  }

  .orderChangeActions {
    flex-direction: column-reverse;
  }

  .orderChangeActions > button {
    margin-left: 0;
  }

  .pickUpContainer > a {
    margin-left: 35px;
  }
}
