.react-datepicker__current-month,.react-datepicker__day-name, .react-datepicker__day {
    color: #461d58 !important;
}

.react-datepicker__navigation:hover *::before, .react-datepicker__navigation *::before {
    border-color:#461d58 !important;
}
.react-datepicker {
    border: 2px solid #bc945a !important;
    margin-top: 10px;
    margin-left: 15px;
}
.react-datepicker__triangle{
    left:25px!important;
    transform: unset !important;
}
.react-datepicker__header {
    background-color: transparent !important;
    border-bottom: 2px solid #bc945a !important;
}

.react-datepicker-popper {
    z-index:101;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
    margin-top:-12px !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #461d58 !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--selected:focus, .react-datepicker__day--selected:active, .react-datepicker__day--selected {
   background-color: rgba(213, 184, 138, 0.2) !important;
   color: #461d58 !important;
}

.react-datepicker__day--disabled {
    cursor: not-allowed;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent;
}

.react-datepicker__day--keyboard-selected:hover {
    background-color: #f0f0f0;
}