.inputWrapper {
  width: 100%;
}

.alertContainer, .inputContainer {
  width: 100%;
}

.alertWrapper {
  justify-content: center;
}

.alertContainer {
  /* border-radius: 22px; */
  height: 90px;
  position: relative;
  background-color: #ce4396;
  color: #fffdfb;
}

.alertContainer > p {
  text-align: center;
  font-family: 'Filson Pro Bold';
  padding-top: 15px;
}

.hasAlert {
  top: -45px;
}

.inputContainer {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  position: relative;
  display: flex;
  justify-content: space-between;
}

.inputLabelContainer {
  min-height: 50px;
}

.inputContainer > img {
  height: 25px;
  align-self: center;
  position: relative;
  left: 20px;
}

.inputContainer > input, .inputContainer > textarea {
  position: relative;
  border-radius: inherit;
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  /* color: #461d58; */
  width: 50%;
  background: transparent;
  justify-content: center;
  border: none;
  font-size: 14px;
}

.inputContainer > textarea {
  padding-top: 10px;
  min-height: 10px !important;
  width: 100%;
  margin: 0 24px;
  height: 5px;
  z-index: 100;
  resize: none;
}

.inputContainer > input:focus, .inputContainer > textarea:focus {
  outline: none;
}

::placeholder, .inputContainer > label {
  align-self: center;
  /* color: #966826; */
  font-family: 'Filson Pro Regular';
}

.inputContainer > label {
  z-index: 10;
  position: absolute;
  transition: 0.2s ease all;
  padding-left: 16px;
}

.inputContainer__password {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.inputContainer__password > div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.inputContainer__password > div > input {
  position: relative;
  margin-left: 24px;
  font-family: 'Filson Pro Bold';
  width: 100%;
  background: transparent;
  justify-content: center;
  border: none;
  font-size: 16px;
}

.inputContainer__password > div > input:focus {
  outline: none;
}


::placeholder, .inputContainer__password > div > label {
  align-self: center;
  font-family: 'Filson Pro Regular';
}

.inputContainer__password > div > label {
  z-index: 10;
  position: absolute;
  transition: 0.2s ease all;
  padding-left: 10px;
}

.inputContainer__password > svg {
  font-size: 20px;
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}


.password > svg {
  font-size: 20px;
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}


.loaderWrapper {
  position: relative;
  left: 20px;
}

.footerLoaderWrapper {
  left: -5px;
}

.requiredLabel::after {
  content: '*';
  font-weight: bold;
  margin-left: 2px;
  color: #ce4396;
}

.hasLabel:focus, .hasLabel:not(:placeholder-shown) {
  margin-top: 20px;
  margin-bottom: 5px;
}

.hasLabel:focus ~ label, .hasLabel:not(:placeholder-shown) ~ label {
  margin-top: 10px;
  top: -3px;
  font-size: 12px;
}

.inputContainer > button {
  /* border-radius: 22px; */
  position: relative;
  left: 4px;
  cursor: pointer;
  font-family: 'Filson Pro Bold';
  margin: 0;
  font-size: 18px;
  padding: 15px 14px 14px 18px;
}

.inputContainer > button:focus {
  outline: none;
}

.errorContainer > p {
  font-family: 'Filson Pro Regular';
  font-size: 13px;
  color: #ffbdbd;
  margin-left: 15px;
}

.errorIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 22px;
  display: flex;
}

.errorIcon > img {
  align-self: center;
  height: 20px;
}

/* STATE CLASSES */

.isDisabled {
  color: #966826 !important;
  background-color: #d5b88a !important;
}

.hasError {
  color: #f12a2f !important;
}

.hasLabel {
  /* border-radius: 8px !important; */
  margin-left: 0 !important;
  padding-left: 10px;
}

.hasLabel > input {
  width: 100%;
  padding-right: 25px;
}

.errorContainer {
  margin-top: 10px;
}

.hide {
  display: none;
}


/* THEME STYLES */
.inputWrapperDark {

}

.inputWrapperDark > .errorContainer > p {
  color: #f12a2f !important;
}

.inputWrapperLight > .inputContainer {
  /* border: none; */
}

.inputWrapperLight > .inputContainer > input {
  /*  */
}


.inputWrapperLight > .errorContainer > p {
  color: #f12a2f !important;
}


@media (max-width: 700px) {
  .inputWrapper {
    width: 100%;
  }
}


@media (max-width: 500px) {
  .alertContainer > p {
    font-size: 12px;
    padding-top: 20px;
  }
  
  ::placeholder, .inputContainer > input {
    font-size: 13.5px;
  }

  .inputContainer > button {
    font-size: 16px;
  }

  .inputContainer > img {
    left: 15px !important;
  }

  .inputContainer > button {
    padding: 5px 12px;
  }
}

@media (max-width: 400px) {
  .inputContainer > img {
    left: 8px !important;
  }

  .inputContainer > input {
    padding-left: 10px;
  }

  ::placeholder, .inputContainer > input {
    font-size: 12.5px;
  }
}

@media (max-width: 350px) {
  .inputContainer > button {
    padding: 5px 10px;
  }
}