.formItem .formItemTitle {
    font-family: "Filson Pro Bold";
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
}
.formItem .formItemSubTitle {
    font-family: "Filson Pro Regular";
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.formItem .textItem textarea {
    width: 100%;
    border: 1px solid #E6DFE8;
    min-height: 98px;
    border-radius: 8px;
    padding: 5px;
}
.uploadFileButton {
    padding: 5px 15px;
    width: auto;
    background-color: #F6F3F7;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid #ccc;
}
.previewContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
}
.fileItem .fileInput {
    display: none;
}
.previewContainer .previewItem {
    width: 68px;
    flex: 0 0 68px;
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
}
.previewContainer .previewItem .filePreview {
    width: 100%;
    border-radius: 5px;
}
.previewContainer .previewItem div.filePreview {
    font-size: 11px;
    word-wrap: break-word;
    min-height: 50px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 3px;
    padding-top: 10px;
}
.previewContainer .previewItem .removeFile {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
}
.addImageButtonIcon {
    display: inline;
}

.formItem .errorMessage {
    color: red;
    font-size: 14px;
    padding-bottom: 10px;
}
.formItem .errorMessage img {
    display: inline;
}