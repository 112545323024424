.pageWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  position: relative;
}
  
.pageContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.pageContainer > h1 {
  font-size: 36px;
  font-family: 'Congenial Black';
  margin: 20px 0;
}
.navBlock > img:hover {
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.subTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Filson Pro Bold';
}
  