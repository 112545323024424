
.pageWrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.pageContainer {
  width: 100%;
  margin-bottom: 30px;
}

.pageContainer > div > h1 {
  font-family: 'Congenial Black';
  line-height: 1.03;
  font-size: 36px;
  margin-bottom: 10px;
}

.pageContainer > p {
  font-family: 'Filson Pro Bold';
  font-size: 12px;
  margin: 0;
}

.inputsWrapper {

  margin-top: 25px;
}

.inputWrapper {
  width: 100%;
}

.inputsColumn {

}

.inputsColumn:last-child {

}

.inputsColumn > h4, .passwordCardContainer > h4,  .cardsContainer > h4, .cardsContainer p {
  font-family: 'Filson Pro Bold'
}

.inputsColumnRow {
  margin-top: -14px;
  display: flex;
}

.inputsColumnRow > div {
  top: 20px;
}

.inputsColumnRow > div:last-child {
  margin-left: 30px;
}

.loyaltyCopyContainer > p {
  font-family: 'Filson Pro Regular';
  line-height: 1.5;
  font-size: 15px;
  margin-bottom: 3px;
}

.loyaltyCopyContainer > a, .actionsContainer > span > a, .actionsContainer > span  {
  font-family: 'Filson Pro Bold';
  font-size: 13px;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:65px;
}

.actionsContainer > span, .actionsContainer > span > a {
  font-size: 11px;
}

.actionsContainer > span > a:first-child {
  margin-right: 5px;
}

.actionsContainer > span > a:last-child {
  margin-left: 5px;
}

.actionsContainerUpdate {
  justify-content: flex-end;
  top: 65px;
}

.actionsContainerUpdate > span {
  display: none;
}

.hideDesktop {
  display: none;
}

/* LOADER WRAPPER */

.loaderWrapper {
  height: 60vh;
  width: 100%;
}

.loaderSecondaryWrapper {
  height: 60px;
}

/* ERROR STYLES */

.error {
  display: block;
  font-family: 'Filson Pro Bold';
}

.birthdayError {
  margin-top: 0px !important;
}

.error {
  color: #d21c38 !important;
}

/* ERROR STYLES */

.success {
  display: block;
  margin-top: 30px;
  font-family: 'Filson Pro Bold';
}

.success {
  color: #983992 !important;
}

/** CUSTOM INPUT STYLES */

.customInputWrapper, .customInputWrapperLight {
  width: 100% !important;
}

.customInputWrapperLight > div:first-child {

}

.createAccountButtonContainer {

}

.createAccountButtonContainer > p {
  font-size: 15px;
}

.passwordCardContainer {
  margin-bottom:15px;
}

.confirmationWrapper {
  display: flex;
  justify-content: space-between;
  margin-top:-15px;
}

.confirmationWrapper > div {
  width: 100%;
  max-width: 48%!important;
}

.updateAction {
  margin: 20px 0 20px auto;
}

.cardsList {
  list-style-type: none;
}

.listItem {
  display: flex;
  justify-content:space-between;
  align-items: flex-end;
}

.listItem p {
  font-family: 'Filson Pro Bold';
  font-size: 18px !important;
  font-weight: 700;
}

.deleteAction {
  background: transparent;
  border: unset;
  padding: 0;
  margin-right: 20px;
  font-size: 14px !important;
  box-shadow: unset;
}
.selectPhoneCode > div {
  border: none;
}
.phoneInputWidget.hideMobile {
  display: flex !important;
}

.accountFormDisabled {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.dropDownRow {
  display: flex;
}

.dropDownRow > div:first-of-type {
  margin-right: 24px;
}
.dropDownRow > div:first-of-type {
  margin-bottom: 10px;
}

.dropDownPlaceholderYear::before {
  content: 'Graduation Year' !important;
}

.dropDownPlaceholderSchool::before {
  content: 'School Name' !important;
}

@media (max-width: 1250px) {
  .pageContainer {
    width: 85%;
  }
}

@media (max-width: 950px) {

  .pageContainer > div > h1 {
    font-size: 36px;
  }

  .hideMobile {
    display: none !important;
  }

  .hideDesktop {
    display: block !important;
  }

  .phoneInputWidget.hideDesktop {
    display: flex !important;
  }

  .phoneInputWidget.hideMobile {
    display: none !important;
  }

  .inputsWrapper, .inputsColumnRow {
    display: block !important;
  }

  .pageContainer {
    width: 75%;
  }

  .inputsColumn {

  }

  .inputsColumn:last-child {
    margin: 0;
  }

  .inputsColumnRow {
    display: flex !important;
  }

  .actionsContainer {
    display: block;
    text-align: center;
  }

  .actionsContainer > span {
    margin: 50px 0;
  }

  .actionsContainerUpdate {
    justify-content: flex-end;
    top: 35px;
  }

  .createAccountButtonContainer {
    width: 100%
  }

  .cardsList{
    padding-inline-start: 10px;
  }
}

@media (max-width: 850px) {
  .pageContainer {
    width: 85%;
  }
  .inputsColumnRow > div:last-child {
    margin-left: 15px;
  }

}

@media (max-width: 600px) {
  .confirmationWrapper {
    flex-wrap: wrap;
  }

  .confirmationWrapper > div {
    width: 100%;
    max-width: 100%!important;
  }

  .listItem p {
    font-size: 13px !important;
  }
}

@media (max-width: 500px) {
  .deleteAction {
    width: unset !important;
    margin-right: -5px;
    margin-top: -3px !important;
  }
}

