/* .errorMessage {
    margin-top: 20px !important;
} */

.resendWrapper {
    display: flex;
    margin-top: 20px;
}

.resendOTPBtn {
    margin-left: 10px;
}
.phoneInputContainer {
    display: block;
}
.phoneSelectStyle > div {
    /* background-color: #FFFFFF !important;
    border: 1px solid #d5b88a !important; */
}
.phoneInputStyle > div:first-of-type > label {
    font-size: 12px;
}
.phoneInputStyle > div:first-of-type {
    /* background-color: #FFFFFF !important;
    border: 1px solid #d5b88a !important; */
}
.phoneInputStyle {
    margin-top: -2px;
}
.phoneSelectStyle {
    margin-right: 0;
    width: auto;
}
