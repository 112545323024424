.heroWrapper {
  background-color: #D9CEE7;
  background: url('./imgs/bg.png');
  background-size: cover;
  align-items: center;
  height: 340px;
}

.heroContainer {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.heroLeft {
  position: relative;
  font-family: sans-serif;
}

.heroLeft > div > h1 {
  color: #FFF;
  font-family: 'Congenial Black';
  line-height: 55px;
  font-size: 60px;
  margin-top: 0;
  margin-bottom: 0;
}

.heroLeft > div > p {
  font-family: 'Filson Pro Regular';
  color: #ffc0e5;
  font-size: 23px;
  margin: 10px 0 30px;
}

.heroLeft > div > a {
  text-decoration: none;
}

.heroLeft > div > .buttonContainer {
  width: 100px !important;
}

.heroRight > div > img {
  height: 375px;
  position: relative;
  top: 125px;
}

/* POINTS ASSET STYLES */

.heroRight > div > div  {
  height: 340px;
  position: relative;
  /* top: 150px; */
}

.heroRight > div > div > img {
  height: 100%;
}

.heroRight > div > div > div:first-of-type {
  position: relative;
  top: -270px;
  left: 314px;
  color: #1a0021;
  text-align: center;
  width: 60px;
}

.heroRight > div > div > div:first-of-type > h3 {
  font-size: 49px;
  font-family: 'Congenial Black';
  margin: 0;
}

.heroRight > div > div > div:first-of-type > p {
  margin: 0;
  margin-top: -8px;
  font-size: 25px;
  font-family: 'Filson Pro Bold'
}

.heroRight > div > div > div:last-of-type {
  position: relative;
  top: -155px;
  left: 35px;
  color: #fbf6ef;
  text-align: center;
  font-family: 'Filson Pro Bold';
  width: 85px;
}

.heroRight > div > div > div:last-of-type > h4 {
  font-size: 32px;
  font-family: 'Filson Pro Bold';
  margin: 0;
  margin-bottom: 5px;
}

.heroRight > div > div > div:last-of-type > p {
  margin: 0;
  margin-top: -8px;
  font-size: 20px;
  font-family: 'Filson Pro Bold'
}

@media (max-width: 900px) {
  .heroWrapper {
    height: auto;
  }
  
  .heroContainer {
    display: block;
    padding-top: 30px;
    padding: 0 25px;
  }

  .heroLeft {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
  }

  .heroLeft > div > h1 {
    margin-top: 0;
    font-size: 42px;
    line-height: 42px;
  }

  .heroLeft > div > p {
    font-size: 20px;
    margin-top: 10px;
  }

  .heroLeft > div > h1 > br {
    display: none;
  }
  

  .heroRight > div > img {
    width: 100%;
    height: auto;
    position: relative;
  }

  .heroRight > div > img {
    top: 55px;
  }

  /** POINT ASSETS STYLE */

  .heroRight > div {
    display: flex;
    justify-content: center;
  }

  .heroRight > div > div  {
    height: 300px;
    position: relative;
    margin-top: 50px;
    top: 0;
  }

  .heroRight > div > div > div:first-of-type {
    top: -218px;
    left: 268px;
  }

  .heroRight > div > div > div:first-of-type > h3 {
    font-size: 30px;
  }
  
  .heroRight > div > div > div:first-of-type > p {
    font-size: 18px;
  }

  .heroRight > div > div > div:last-of-type {
    top: -110px;
    left: 15px;
  }
  
  .heroRight > div > div > div:last-of-type > h4 {
    font-size: 28px;
  }
  
  .heroRight > div > div > div:last-of-type > p {
    font-size: 16px;
  }
  
}

@media (max-width: 400px) {

  .heroLeft > div > h1 {
    margin-top: 0;
    font-size: 36px;
    line-height: 36px;
  }

  .heroLeft > div > p {
    font-size: 18px;
  }

  /** POINT ASSETS STYLE */

  .heroRight > div > div  {
    height: 240px;
  }

  .heroRight > div > div > div:first-of-type {
    top: -178px;
    left: 215px;
  }

  .heroRight > div > div > div:first-of-type > h3 {
    font-size: 25px;
    margin-bottom: 5px;
  }
  
  .heroRight > div > div > div:first-of-type > p {
    font-size: 16px;
  }

  .heroRight > div > div > div:last-of-type {
    top: -95px;
    left: 5px;
  }
  
  .heroRight > div > div > div:last-of-type > h4 {
    font-size: 25px;
  }
  
  .heroRight > div > div > div:last-of-type > p {
    font-size: 15px;
  }
}

