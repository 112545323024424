.customizePageContainer {
  display: flex;
  max-width: 1200px;
  align-content: center;
}

.customizeImageContainer {
  max-width: 470px;
}
.customizeDetailsContainer {
  max-width: 698px;
  margin: 0 auto;
  position: relative;
}

.pageLoaderWrapper {
  height: 50vh;
  width: 100%;
}
.customizeImageContainer > img {
  width: 470px;
}

.customizeDetailsContainer {
  margin-top: 100px;
}

.customizeImageContainer {
  margin-top: 100px;
}

.optionPrice{
  font-size: 14px!important;
}

p.errorMessage {
  color: #d21c38;
  /* font-family: 'Filson Pro Bold'; */
  text-align: center;
}

.loaderWrapper {
  position: absolute;
  top: 9%;
  left: 46%;
  z-index: 101 !important;
}

.detailsHeader > span {
  /* font-size: 18px; */
  /* line-height: 1.33; */
  /* font-family: 'Filson Pro Bold'; */
  /* color: #966826; */
}

.detailsHeader > h2 {
  margin: 0;
  font-size: 42px;
  margin-bottom: 10px;
  font-family: 'Congenial Black';
  line-height: 1.14;
  color: #461d58;
}

.customizeDetailsContainer > p {
  /* font-size: 16px; */
  /* font-family: 'Filson Pro Regular'; */
  /* line-height: 1.5; */
  /* color: #966826; */
}

/* SECTION HEADER STYLES */

.customizeSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
  /* padding-right: 50px; */
}

#customizeSelector {
  margin-top: -40px !important;
}

.customizeSectionHeader > div > p {
  margin: 0;
  font-size: 18px;
  /* font-family: 'Filson Pro Bold'; */
  /* line-height: 1.33; */
}

.customizeSectionHeader > div > span {
  font-size: 12px;
  text-decoration: underline;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.33;
  color: #ce4396;
  cursor: pointer;
}

.customizeSectionHeader > button {
  width: 120px;
  height: 44px;
  border-radius: 22.5px;
  background-color: #f3c244;
  padding: 0;
  /* font-family: 'Filson Pro Bold'; */
  font-size: 18px;
  line-height: 1.33;
  color: #1a0021;
}
.quantityNumberStyle{
  font-size: 12px;
  color:#585858!important;
  text-decoration: none!important;
}
/* SECTION ROW STYLES */

.customizeSectionRow {
  display: flex;
  /* flex-wrap: wrap; */
}

.customizeSectionRowSelector {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.customizeSectionRowSelector:first-of-type {
  margin-top: -40px !important;
}

.customizeSectionRowSelector > div:last-of-type {
  margin-bottom: 0;
}

/* LIST OPTION STYLES */
.listOptionWrapper {
  margin-top: -30px;
  display: flex;
}

.listOptionContainer {
  width: 145px;
  height: 64px;
  border-radius: 8px;
  border: solid 1px #d5b88a;
  background-color: #fffdfb;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.listOptionSelected {
  background-color: #f3c244;
}

.listOptionContainer > p {
  font-size: 18px;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.33;
  color: #461d58;
}

/* Alert Styles */
.alert {
  font-size: 12px !important;
  margin-top: -50px;
  /* font-family: 'Filson Pro Bold' !important; */
  line-height: 1.33;
  color: #ce4396 !important;
}

.cartWrapper {
  position: sticky;
  margin-top: 50px;
  bottom: 20px;
  margin-bottom: 30px;
}

.backMenu span {

}

.loyaltyPrice {
  display: inline-block;
  margin-left: 10px;
}

.backMenu h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
  cursor: pointer;  
}

.nutritionalInfo {
  margin: 0;
  font-size: 12px;
  /* font-family: 'Filson Pro Bold'; */
  line-height: 1.33;
  color: #ce4396;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
}

@media (max-width: 1400px) {
  .customizeImageContainer {
    width: 35%;
  }

  .customizeDetailsContainer {
    width: 65%;
  }

  .customizeImageContainer > img {
    width: 375px;
  }
}

@media (max-width: 1150px) {
  .customizePageContainer {
    padding: 0px 30px 50px;
  }

  .customizeImageContainer {
    width: 30%;
  }

  .customizeDetailsContainer {
    width: 70%;
  }

  .customizeImageContainer > img {
    width: 275px;
  }
}

@media (max-width: 1000px) {
  .customizePageContainer {
    flex-direction: column;
    justify-content: center;
    /* padding-top: 150px; */
  }

  .customizeImageContainer,
  .customizeDetailsContainer {
    width: 100%;
  }

  .customizeImageContainer {
    text-align: center;
  }

  .customizeImageContainer > img {
    width: 80%;
    margin: 0 auto;
  }

  .customizeDetailsContainer {
    margin-top: 0;
  }

  .detailsHeader {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .cartWrapper {
    position: sticky;
    margin-top: 50px;
    bottom: 20px;
  }
}

@media (max-width:768px){
  .detailsHeader{
    flex-direction: unset;
    flex-wrap: wrap;
  }

  .backMenu{
    width:100%;
  }

  .detailsHeader >h2 {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .customizePageContainer {
    padding: 0px 30px 0;
  }

  .detailsHeader > h2 {
    font-size: 24px;
    line-height: 1.17;
  }

  .customizeSectionRowSelector > div:last-of-type {
    margin-left: 0;
  }

  .customizeSectionHeader {
    padding-right: 0;
  }
}

@media (max-width: 400px) {
  .customizeSectionHeader > button {
    font-size: 14px;
  }

  .listOptionContainer > p {
    font-size: 15px;
  }
}
