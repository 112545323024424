.pageWrapper {
  min-height: 50vh;
  width: 70%;
  margin: 0 auto 20px auto;
}
.headerBlock{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerBlock > h3 {
  font-family: 'Congenial Black';
  font-size: 36px;
  line-height: 36px;
  /* color: #461d58; */
  cursor: pointer;
  /* margin-bottom: 44px; */
  position: relative;
  letter-spacing: -1px;
}

#shippingAddress {
  display: none;
}

#pickUpAddress {
  /* font-family: 'Filson Pro Bold';
  margin-bottom: 30px; */
}

#pickUpAddress > p {
  /* font-size: 16px;
  color: #461d58;
  margin: 0; */
}

.loaderWidget {
  position: fixed;
  top: 50%;
  left: 50%;
}

.loaderWrapper {
  height: 400px;
}

#pickUpAddress > span {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
}

.headerBlock > h3 > span > img {
  /* color: #461d58; */
  height: 24px;
  /* position: absolute;
  left: -30px;
  top: 7px; */
}

.pageContainer > p {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 20px;
  letter-spacing: -0.1px;
}

.pageContainer > a {
  font-size: 12px;
  font-family: 'Filson Pro Bold';
  color: #ce4396;
}

/* CHANGE ORDER STYLES */
.orderChangeWrapper {
  width: 460px;
  margin: 0 auto;
  padding: 57px 0 30px;
}

.productContainer {
  border-radius: 8px;
  border: solid 1px #d5b88a;
  margin-bottom: 10px;
}

.productContainer > p {
  padding: 8px 24px;
  font-family: 'Filson Pro Bold';
  font-size: 14px;
  line-height: 1.43;
  color: #461d58;
  margin: 0;
}

/* DESKTOP CAROUSEL STYLES */

.carouselWrapper {
  margin-top: 15px;
}

.carouselHeader > p {
  margin: 0;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #966826;
  position: relative;
  top: 20px;
}

.carouselDots {
  top: 35px;
  left: 75px;
  z-index: 10000;
  display: flex;
  align-items: center;
  position: relative;
}

.carouselDots > img {
  display: none;
}

.carouselDots > .dot {
  cursor: pointer;
  width: 7px;
  height: 7px;
  background-color: rgba(213, 184, 137, 0.8);
  border-radius: 100px;
  margin-right: 10px;
}

.carouselDots > .activeDot {
  background-color: #f3c244 !important;
}

.carouselSlide {
  display: flex;
  align-items: center;
  width: 90%;
  padding: 50px 0px;
}

.carouselSlide > div {
  margin: 0 !important;
  margin-right: 64px;
}

.carouselSlide > div:last-child {
  margin-right: 0 !important;
}

.arrow {
  position: relative;
  top: 10px;
  cursor: pointer !important;
  transform: rotate(180deg);
}

.arrowRight {
  transform: rotate(360deg);
}

/* ORDER COST STYLES */
.orderCostContainer {
  /* margin-top: 36px;
  padding: 26px 30px;
  border-radius: 20px;
  border: solid 1px #d5b88a; */
}

.lineItemContainer {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
  font-family: 'Filson Pro Bold';
  font-weight: 700;
}

.lineItemContainer > p {
  margin: 0;
}

.lineItemContainer > p:first-of-type {
  font-family: 'Filson Pro Regular';
}

.lineItemContainer > p:last-of-type {
  font-family: 'Filson Pro Bold';
}

.isTotal {
  margin-top: 30px;
}

.isTotal > p {
  font-family: 'Filson Pro Bold' !important;
}

/* ACTIONS STYLES */

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.submitButton {
  /* width: 139px;
  height: 48px; */
}

.submitButton > p {
  font-size: 18px;
}

@media (max-width: 1200px) {
  .pageWrapper {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .arrow {
    display: none;
  }

  .carouselDots {
    justify-content: flex-end;
    left: 0;
    top: 1px;
  }

  .carouselDots > img {
    display: flex;
  }

  .carouselDots > img:first-child {
    transform: rotate(180deg);
    position: relative;
    right: 10px;
  }
}

@media (max-width: 900px) {
  /* CAROUSEL STYLES */
  .desktopCarousel {
    display: none;
  }
  .mobileCarousel {
    display: block;
  }
}

@media (max-width: 800px) {
  .pageWrapper {
    width: 90%;
  }

  .headerBlock > h3 > span > img {
    display: none;
  }
}

@media (max-width: 600px) {
  .headerBlock > h3 {
    /* margin-bottom: 8px; */
  }

  #shippingAddress {
    display: block;
    margin: 0;
    font-size: 14px;
    margin-bottom: 40px;
  }

  .actionsContainer {
    flex-direction: column;
  }

  .submitButton {
    /* width: 100%;
    margin-top: 65px; */
  }

  /* CHANGE ORDER STYLES */
  .orderChangeWrapper {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .orderCostContainer {
    margin-top: 30px;
  }
}
