.pageWrapper {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 50px;
}

div > p.errorMessage {
  color: #d21c38;
  font-family: "Filson Pro Bold";
  text-align: center;
}

.disclaimerNotes {
  font-family: "Filson Pro Regular";
  font-size: 12px;
  color: #966825;
  padding-bottom: 10px;
}

p.callout {
  color: #461d58;
  font-family: "Filson Pro Bold";
}

.callout a {
  color: #461d58;
}

.orderProcessingLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 40%;
  left: 45%;
}

.giftCheckboxWrapper {
  margin-top: 20px;
  margin-bottom: 16px;
}

.clearImg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.pageContainer > h3 {
  font-family: "Congenial Black";
  font-size: 32px;
  line-height: 1.13;
  color: #461d58;
  cursor: pointer;
  position: relative;
  margin: 0;
}

.pageContainer > h3 > span > img {
  color: #461d58;
  height: 24px;
  position: absolute;
  left: -30px;
  top: 7px;
}

.inputLoaderContainer {
  margin-right: 10px;
}

.loaderWrapper {
  height: 400px;
}

#deliveryAddress {
  font-family: "Filson Pro Bold";
  font-size: 16px;
  color: #461d58;
  margin: 12px 0 35px;
}

/* COLUMN STYLES */

.columnsContainer {
  display: flex;
  justify-content: space-between;
}

.columnsContainer > div {
  width: 50%;
}

/* DETAILS STYLES */

.detailsContainer {
  margin-right: 20px;
}

.detailsSection > h3,
.discountContainer > h3,
.detailsSection > div > h3 {
  font-family: "Filson Pro Bold";
  font-size: 18px;
  line-height: 1.33;
  color: #966826;
}

.detailsSection {
  margin-bottom: 64px;
}

.inputContainer {
  width: 100%;
}

/* SELECTION STYLES */

.mainContainer {
  margin-top: 5px;
  border-radius: 8px;
  border: solid 1px #d5b88a;
  /* background-color: #fffdfb !important; */
}

.selectionContainer {
  /* background-color: #fbf7f1; */
  border-radius: 8px;
  margin-bottom: 12px;
}

.selectionContainer,
.itemDetailsContainer {
  width: 100%;
  display: flex;
}

.sectionHeader,
.sectionSubHeader {
  color: #966826;
}

.sectionHeader {
  font-size: 18px;
  margin: 0;
  margin-bottom: 13px;
  line-height: 1.33;
}

/* DROPDOOWN HEADER ROW */

.dropDownRow {
  display: flex;
}

.dropDownRow > div:first-of-type {
  margin-right: 24px;
}

.sectionSubHeader {
  font-size: 14px;
  margin-bottom: 6px;
}

.selectionContainer {
  padding: 10px 16px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

.itemDetailsContainer > img {
  margin-right: 10px;
  position: relative;
  top: -8px;
}

.itemDetailsContainer > div > p,
.itemDetailsContainer > div > h4 {
  margin: 0;
}

.itemDetailsContainer > div > p {
  font-size: 12px;
  font-family: "Filson Pro Regular";
  line-height: 1.33;
  color: #966826;
}

.itemDetailsContainer > div > h4 {
  font-family: "Filson Pro Bold";
  font-size: 18px;
  line-height: 1.33;
  color: #461d58;
}

.selectionButton {
  width: 100%;
  margin: 64px 0 15px;
  height: 57px;
}

.selectionMinor,
.selectionAction {
  font-family: "Filson Pro Bold";
  line-height: 1.33;
  color: #ce4396;
  font-size: 12px;
}

.selectionMinor {
  display: block;
  margin-top: -8px;
  margin-bottom: 10px;
}

.selectionAction {
  cursor: pointer;
  text-decoration: underline;
}

#editAddress {
  display: flex;
  justify-content: flex-end;
}

/* DISCOUNT STYLES */

.discountContainer {
  margin-top: 30px;
}

.discountContainer > div {
  border-radius: 22px;
  border: solid 1px #d5b88a;
  background-color: #fffdfb;
  display: flex;
  align-items: center;
}

.disableInput {
  background-color: #fcf7ef !important;
}

.disableInput > button {
  background-color: #dab783 !important;
  color: #9e660e !important;
  width: 300px !important;
}

/* COST STYLES */

.costDetails > h3 {
  font-size: 18px;
  font-family: "Filson Pro Bold";
  line-height: 1.33;
  color: #461d58;
}

.totalsContainer {
  position: relative;
  padding: 33px;
  border-radius: 20px;
  border: solid 1px #d5b88a;
}

.lineCost {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  color: #966826;
}

.lineCost > p {
  margin: 0 5px 5px 0;
}

.lineCost > p:last-child {
  font-family: "Filson Pro Bold";
}

.products {
  font-family: "Filson Pro Bold";
}

.totalsSection {
  margin-bottom: 25px;
}

.totalsSection:last-of-type {
  margin-bottom: 0;
}

#total > div {
  font-family: "Filson Pro Bold";
  color: #461d58;
}

/* ACTIONS SECTION */

.actionsSection > h3,
.cancelPolicy > h3 {
  margin: 0;
  font-family: "Filson Pro Bold";
  font-size: 16px;
  line-height: 1.5;
  color: #966826;
  margin-bottom: 5px;
}

.actionsSection > p,
.cancelPolicy > p {
  font-size: 12px;
  margin: 0;
  font-family: "Filson Pro Regular";
  line-height: 1.33;
  color: #966826;
}

.actionsSection a {
  color: #966826 !important;
}

.actionsSection > div {
  margin: 15px 0 30px;
}

.actionsSection > .buttonContainer {
  width: 100%;
}

.cancelPolicy {
  margin-bottom: 5px !important;
}

/* RADIO OPTIONS STYLES */

.optionsContainer > div {
  font-family: "Filson Pro Bold" !important;
  justify-content: space-between;
}

.optionsContainer {
  display: flex;
  margin-top: 10px;
}

.optionsContainer > div {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #d5b88a;
  background-color: #fffdfb;
  margin-right: 6px;
  display: flex;
  color: #461d58;
  justify-content: center !important;
  align-items: center;
}

.optionsContainer > div:last-of-type {
  height: 58px;
  margin-right: 0;
}

.chosen {
  border: 1px solid #f3c244 !important;
  background-color: #f3c244 !important;
}

.labelHeader {
  margin-top: 30px !important;
}

/* SECONDARY RENDERING STYLES */

.secondaryAddressContainer {
  position: relative;
}

.createContactLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 40%;
  left: 45%;
}

.paymentsLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
}

.secondaryAddressContainer > form > p,
.secondaryAddressContainer > form > span {
  font-size: 18px;
  font-family: "Filson Pro Bold";
  line-height: 1.33;
  color: #966826;
  margin: 0;
}

.secondaryAddressContainer .secondaryInputWrapper {
  width: 100%;
}

.secondaryAddressContainer .secondaryInputWrapper > div > input,
.secondaryInputRow,
.secondaryInputWrapper > div > input {
  color: #461d58 !important;
}

.customExpirationDate {
  width: 70% !important;
}

.customCVV {
  width: 30% !important;
}

.secondaryAddressContainer .secondaryInputRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -7px;
}

.secondaryInputRow > div {
  width: 50%;
}

.secondaryAddressContainer .secondaryInputRow > div:first-of-type {
  margin-right: 20px;
}

.secondaryButtonContainer {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
}

.secondaryAddressContainer #deleteOption {
  display: block;
  text-align: center;
  margin: 50px auto 15px;
  cursor: pointer;
}

.secondaryAddressContainer .selectionMinor {
  font-size: 12px;
  font-family: "Filson Pro Bold";
  line-height: 1.33;
  color: #ce4396;
}

.zipContainer {
  margin-top: -6px;
}

.billingZipContainer {
  margin-top: -10px;
}

.pickupContactsContainer {
  margin-bottom: 0;
  margin-top: 30px;
}

.returnLineCostDetails summary {
  display: flex;
  align-items: center;
  color: #966826;
  cursor: pointer;
  outline: none;
  line-height: 1.5;
}

.returnLineCostDetails > div {
  font-size: 14px;
  padding-left: 15px;
}

.returnLineCostDetails > div > p {
  font-family: "Filson Pro Regular" !important;
}

.returnLineCostDetails > div:nth-of-type(1) {
  margin-top: 5px;
}

.returnLineCostDetails summary p {
  margin: 0;
}

.returnLineCostDetails summary > p:last-child {
  flex-grow: 1;
  text-align: right;
  margin: 0 5px 5px 0;
}

.shippingAddressContent {
  position: relative;
  width: 100%;
}

.shippingAddressLoader {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
}

.buttonContainer {
  margin-top: 30px !important;
}

.buttonContainer > div {
  margin-bottom: 16px;
}

@media (max-width: 1300px) {
  .pageWrapper {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .pageWrapper {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .detailsContainer {
    margin-right: 0;
  }
  /* COLUMN STYLES */
  .columnsContainer {
    flex-direction: column;
  }
  .columnsContainer > div {
    width: 100%;
  }
  .pageContainer > h3 {
    margin: 0;
    margin-bottom: 10px;
  }
  .dropDownRow {
    flex-direction: column;
  }
  .dropDownRow > div:first-of-type {
    margin-bottom: 10px;
  }
  .pageContainer > h3 > span > img {
    display: none;
  }
  .totalsContainer {
    border: none;
    padding: 0;
  }
}

@media (max-width: 455px) {
  .secondaryInputRow {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
  .secondaryInputRow > div {
    width: 100%;
  }

  .customExpirationDate {
    width: 100% !important;
    margin-right: 0 !important;
  }
  
  .customCVV {
    width: 100% !important;
  }
}
