.footerWrapper {
  background-color: #1a0021;
  width: 100%;
  padding: 35px 59px 15px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.footerContainer {
  display: flex;
  justify-content: space-between;
}

/* COLUMN STYLES */
.footerContainer > .sectionContainer:nth-child(1) {
  /* width: 50%; */
}

.footerContainer > .sectionContainer:nth-child(2), .footerContainer > .sectionContainer:nth-child(3) {
  /* width: 25%; */
}

.footerContainer > .sectionContainer:nth-child(2) {
  margin-right: 50px;
}

.footerContainer > .sectionContainer {
  height: 100%;
}

.footerContainer > .sectionContainer > h3 {
  font-size: 18px;
  /* font-family: 'Filson Pro Bold'; */
  margin: 0;
  line-height: 1.33;
  /* color: #ffc0e5; */
}

.footerContainer > .sectionContainer > p {
  /* font-family: 'Filson Pro Regular'; */
  font-size: 16px;
  /* width: 75%; */
  line-height: 1.5;
  /* color: #fffdfb; */
  /* margin: 14px 0 40px; */
}

.footerContainer > .sectionContainer > p > a {
  /* color: #ffc0e5; */
  /* font-family: 'Filson Pro Bold'; */
  display: block;
  margin-top: 10px;
}

/* DEALS STYLES */
.sectionContainer > .dealsContainer {
  margin-top: -5px;
}

.sectionContainerSuccess p {
  display: block;
  margin-top: 10px;
  /* font-family: 'Filson Pro Bold'; */
  color: #ce4396;
}

/* DOWNLOAD STYLES */
.sectionContainer > .downloadsContainer {
  margin: 14px 0 25px;
}

.sectionContainer > .downloadsContainer > a > img {
  width: 207px;
  height: 48px;
}

.sectionContainer > .downloadsContainer > a:first-of-type > img{
  margin-right: 15px;
}

/* OPTIONS STYLES */

.linksContainer {
  display: flex;
}

.mobileLinks {
  display: none;
}

.linksContainer > div:first-of-type {
  margin-right: 85px;
}

.linksContainer > div > a {
  display: block;
  text-decoration: none;
  /* font-family: 'Filson Pro Regular'; */
  font-size: 16px;
  line-height: 1.5;
  /* color: #d5b88a; */
}

/* SOCIAL STYLES */
.socialContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.socialContainer > a {
  display: flex;
  align-items: center;
}

.socialContainer > a > img {
  height: 40px;
}

.socialContainer > a:last-of-type > img {
  margin-bottom:0;
}
.socialContainer > a > img {
  margin-bottom: 15px;
}

/* INPUT STYLES */

.inputWrapper {
  height: 44px;
  width: 90%;
  padding: 0;
}


.inputWrapper > div {
  height: 100%;
  min-height: 44px;
  border-radius: 100px !important;
}

.inputWrapper > div > img {
  display: none;
}

.locationListContainer > div > img {
  top: 0;
}

.inputWrapper > div > input {
  margin-left: 20px;
  width: 100%;
  font-size: 14px;
}

.inputWrapper > div > button {
  padding: 10px 15px;
  font-weight: normal;
}

/* BUTTON STYLES */

.button {
  /* font-family: 'Filson Pro Bold'; */
  font-weight: normal;
  font-size: 18px;
  line-height: 1.33;
  color: #1a0021;
  border-radius: 22.5px;
  background-color: #f3c244;
  padding: 10px 15px;
  margin: 0;
  display: block;
}

.copyright {
  /* font-family: 'Filson Pro Regular'; */
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #d5b88a;
  display: block;
  margin-top: 17px;
}

@media (max-width: 1100px) {
  /* COLUMN STYLES */
  .footerContainer > .sectionContainer:nth-child(1) {
    /* width: 40%; */
  }

  .footerContainer > .sectionContainer:nth-child(2), .footerContainer > .sectionContainer:nth-child(3) {
    /* width: 30%; */
  }

  .sectionContainer > .downloadsContainer > a:first-of-type > img {
    margin-bottom: 10px;
  }

  .linksContainer > div:first-of-type {
    margin-right: 40px;
  }

  .footerContainer > .sectionContainer > p {
    /* width: 95%; */
  }

  .inputWrapper {
    /* width: 95% */
  }
}

@media (max-width: 950px) {
    /* COLUMN STYLES */
    .footerContainer > .sectionContainer:nth-child(1) {
      /* width: 25%; */
      margin-right: 50px;
    }
  
    .footerContainer > .sectionContainer:nth-child(2), .footerContainer > .sectionContainer:nth-child(3) {
      /* width: 40%; */
    }
}

@media (max-width: 900px) {
  .footerWrapper {
    padding: 35px 30px 15px;
  }

  /* COLUMN STYLES */
  .footerContainer > .sectionContainer:nth-child(1) {
    /* width: 20%; */
    margin-right: 100px;
  }
    
  .footerContainer > .sectionContainer:nth-child(2), .footerContainer > .sectionContainer:nth-child(3) {
    /* width: 50%; */
  }

  .inputWrapper > div > input {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footerContainer > .sectionContainer {
    width: 100% !important;
    border-bottom: dotted 2px #ffc0e5;
    padding: 31px 0;
    margin: 0 !important;
  }

  /* INPUT STYLES */

  .inputWrapper {
    width: 100%;
    margin-bottom: 15px;
  }

  .inputWrapper > div > input {
    font-size: 15px;
  }

  .desktopLinks {
    display: none;
  }

  .mobileLinks {
    display: block;
  }

  /* OPTIONS STYLES */

  .mobileLinks {
    display: flex;
    margin-top: 25px;
  }

  .sectionContainer > .downloadsContainer {
    margin-bottom: 0;
  }

  .sectionContainer > .downloadsContainer > a:first-of-type > img {
    margin-bottom: 0;
  }

  /* INPUT STYLES */
  .footerContainer > .sectionContainer > p {
    margin: 4px 0 12px;
  }

  .button {
    margin: 0;
    margin-top: 30px;
  }

  .socialContainer {
    flex-direction: row;
    justify-content: center;
    margin-top: 38px;
  }

  .socialContainer > a:last-of-type > img {
    margin: 0;
  }

  .socialContainer > a > img {
    margin: 0 13px 0 0;
  }

  .copyright {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .sectionContainer > .downloadsContainer > a  > img {
    height: auto;
    /* width: 49%; */
  }
  .sectionContainer > .downloadsContainer > a:first-of-type > img {
    margin-right: 5px;
  }
}

@media screen and (max-width: 500px) and (orientation: portrait) {
  .footerWrapper{
    display: none;
  }
}