.sideCartWrapper {
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: flex-end;
  font-family: sans-serif;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.4)
}

.sideCartContainer {
  height: 100%;
  width: 375px;
  background-color: #FFF;
}

.sideCartHeader {
  background-color: #412C8A;
  display: flex;
  justify-content: space-between;
  color: #FFF;
  padding: 0 25px;
}

.sideCartHeader > h3 {
  font-size: 15px;
}

.sideCartHeader > span {
  font-weight: 300;
  position: relative;
  font-size: 12px;
  top: 17px;
  cursor: pointer;
}

.sideCartDetailsContainer {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #E5E5E5;
  padding: 15px 0;
}

.sideCartContainer > .sideCartDetailsContainer:last-child {
  border-bottom: none
}

.sideCartDetailsContainer > h5 {
  font-size: 15px;
}

.sideCartDetailsContainer > p {
  font-weight: 300;
  font-size: 12px;
}

.sideCartDetailsContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 50px;
  background-color: #C2C2C2;
  cursor: pointer;;
}


.sideCartDetailsContainer > div > span {
  font-weight: 300;
  font-size: 12px;
}

button {
  background-color: #412C8A;
  border: none;
  color: #FFF;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 95px;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 20px;
  margin-left: 20px;
}

@media (max-width: 700px) {
  .sideCartContainer {
    width: 100%;
  }
}
