.loaderWrapper {
  width: 100%;
  height: 25vh;
}

.pageWrapper {
  background-image: url('./imgs/bg.svg');
  background-size: cover;
}

@media (max-width: 768px) {
  .pageWrapper {
    background-image: url('./imgs/bg-mobile.svg');
    padding: 2rem 1rem;
  }
}

.orderTrackerWrapper {
  max-width: 1200px;
  margin: 4rem auto 6rem auto;
}

.orderTrackerWrapper > h1 {
  color: #fff;
  font-size: 50px;
  font-family: 'Congenial Black';
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .orderTrackerWrapper > h1 {
    font-size: 40px;
  }
}
.dropoffImage > div {
  color: #fff;
}