.checkoutContainerWrapper {
  /* border-bottom: solid 1px #E1D9E4; */
  /* background-color: #fbf5ee; */
}

.checkoutContainer {
  padding: 16px 24px;
}

.checkoutContainerCollapsedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkoutContainerCollapsedContainer > img {
  margin-right: 5px;
}

/* HEADER STYLES */

.checkoutContainerLeft {
  font-family: 'Filson Pro Bold';
  width: 100%;
}

.checkoutContainerLeft > p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.checkoutContainerLeft > span {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  white-space: break-spaces;
}

.checkoutContainerLeft > span > img {
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.checkoutContainerLeft > span > p {
  position: relative;
  top: 5px;
}

.dropDownCheckContainer {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #ba935a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownCheckContainer > img {
  height: 9px;
}

.containerDropDownChildren {
  font-family: 'Filson Pro Bold';
}

.checkoutContainerRight {
  display: flex;
  gap: 4px;
}

.checkoutContainerRight .changeText {
  /* font-size: 12px;
  font-family: 'Filson Pro Bold';
  color: #461d58;*/
  padding: 3px 0 0 0; 
}

.containerExpanded > div > .checkoutContainerLeft {
  height: 0;
}

.containerExpanded > div > .checkoutContainerLeft > p {
  height: 0 !important;
}

.containerExpanded > div > .checkoutContainerLeft > span {
  height: 0 !important;
}

.containerExpanded > div > .checkoutContainerRight {
  position:relative;
  top:10px;
}

#alertIcon {
  height: 16px;
}

/* ACTION STYLES */

.containerInteractive {
  cursor: pointer;
  z-index: 1000;
  position: relative;
}

.containerDisabled, .containerDisabled div{
  cursor: not-allowed;
}

.containerExpanded > .checkoutContainerCollapsedContainer > .checkoutContainerRight > img {
  transform: rotate(180deg);
}

.containerExpanded > .checkoutContainerCollapsedContainer > .checkoutContainerLeft > p,
.containerExpanded > .checkoutContainerCollapsedContainer > .checkoutContainerLeft > span {
  opacity: 0;
}


.checkSelected {
  border: none;
  /* border: 1px solid #f3c244; */
  /* background-color: #f3c244 !important; */
}

.alert {
  color: #d21c38 !important;
}

.navigate {
  transform: rotate(-90deg);
}
