.templateContainer {
  display: flex;
  width: 100%;
  height: 450px;
}

.templateContainer .row {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.templateContainer .mainImage {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.templateContainer .imgWrapper {
  padding: 0 83px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.templateContainer .leftChildContainer, .templateContainer .rightChildContainer {
  display: flex;
  flex-direction: column;
}

.templateContainer .imgWrapper .inner {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.templateContainer .leftChildContainer img, .templateContainer .rightChildContainer img {
  width: 100%;
  height: auto;
  display: block;
}

.templateContainer .rightChildContainer img {
  width: 350px;
}

.templateContainer .leftChildContainer .customButtonContainer {
  padding: 10px 15px;
  height: unset;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dotsContainer {
  width: 100%;
  margin-bottom: 0;
  position: relative;
}

.cardDots {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  height: 40px;
  margin-top: -40px;
  overflow: hidden;
}

.cardDots > button {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: rgb(173,147,179);
  margin-right: 9px;
  padding: initial;
  z-index: 9999;
}
@media (max-width: 1920px){
  .templateContainer .mainImage{width: unset;}
}
@media (max-width: 576px) {
  .templateContainer {
    display: flex;
    width: 100%;
    height: 600px;
  }
  .templateContainer .imgWrapper .inner{
    flex-direction: column;
    align-self: flex-start;
  }
  .templateContainer .imgWrapper .inner .leftChildContainer{margin-bottom: 20px}
  .templateContainer .imgWrapper{padding: 0 20px;}
  .templateContainer .leftChildContainer img, .templateContainer .rightChildContainer img{width: 275px;}
  .templateContainer .leftChildContainer button {width: 150px; padding: 6px 0;font-size: 14px;}

  .cardDots {
    height: 30px;
    margin-top: -30px;
  }

  .cardDots > button {
    width: 12px;
    height: 12px;
  }
}
@media (max-width: 360px) {
  .templateContainer {
    height: 450px;
  }
  .templateContainer .leftChildContainer img, .templateContainer .rightChildContainer img{width: 190px;}
  .templateContainer .leftChildContainer button { font-size: 12px; }
}

.animate {
  opacity: 0.01;
  transition: opacity 300ms ease-out;
}

.animateActive {
  opacity: 1;
  transition: opacity 300ms ease-in;
}