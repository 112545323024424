.pageWrapper {
  /* width: 70%; */
  /* margin: 0 auto;
  padding-bottom: 50px; */
}

div > p.errorMessage {
  color: #d21c38;
  font-family: 'Filson Pro Bold';
  text-align: center;
  margin-top: 20px;
}

.disclaimerNotes {
  font-family: 'Filson Pro Regular';
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 425 !important;
  padding-bottom: 10px;
}

.disclaimerSMSNotes {
  font-family: 'Filson Pro Regular';
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 425 !important;
  padding-bottom: 10px;
}

p.callout {
  color: #461d58;
  font-family: 'Filson Pro Bold';
}

.callout a {
  color: #461d58;
}

.orderProcessingLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 40%;
  left: 45%;
}

.giftCheckboxWrapper {
    margin-top: 20px;
    margin-bottom: 16px;
}

.clearImg {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  cursor: pointer;
  position: relative;
  top: 6px;
}

.pageContainer > h3 {
  /* font-family: 'Congenial Black';
  font-size: 32px;
  line-height: 1.13;
  color: #461d58;
  position: relative;
  margin: 0; */
}

.pageContainer > h3.pointer {
  cursor: pointer;
}

.pageContainer > h3 > span > img {
  /* color: #461d58;
  height: 24px;
  position: absolute;
  left: -30px;
  top: 7px; */
}

.pageContainer > .reviewOrderSubtitle {
  /* font-size: 14px;
  color: #461d58;
  font-weight: bold; */
}

.inputLoaderContainer {
  margin-right: 10px;
}

.loaderWrapper {
  height: 400px;
}

#deliveryAddress {
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  color: #461d58;
  margin: 12px 0 35px;
}

/* COLUMN STYLES */

.columnsContainer {
  /* display: flex;
  justify-content: space-between; */
}
div.columnsContainer {
  /* overflow: visible; */
}

.columnsContainer > div {
  /* width: 33%; */
}

/* DETAILS STYLES */

.detailsContainer {
  /* margin-right: 20px; */
}

.detailsSection > h3,
.discountContainer > h3,
.detailsSection > div >h3 {
  /* font-family: 'Filson Pro Bold';
  font-size: 20px;
  line-height: 1.33;
  color: #461d58;
  border-bottom: 3px solid #bc9459;
  margin-top: 30px;
  margin-bottom: 0;
  padding-bottom: 5px; */
}

.inputContainer {
  
}

.inputContainer > div:first-of-type,
.secondaryAddressContainer .secondaryInputWrapper > div:first-of-type {
  
}

.floatRight {
  /* float: right; */
}

.floatLeft {
  /* float: left; */
}

.minHeight {
  /* min-height: 190px; */
}
/* SELECTION STYLES */

.mainContainer {
  margin-top: 5px;
  border-radius: 8px;
  border: solid 1px #d5b88a;
  background-color: #fffdfb !important;
}

.selectionContainer {
  background-color: #fbf7f1;
  border-radius: 8px;
  margin-bottom: 12px;
}

.selectionContainer,
.itemDetailsContainer {
  width: 100%;
  display: flex;
}

.sectionHeader,
.sectionSubHeader {
  /* color: #966826; */
}

.sectionHeader {
  font-size: 18px;
  margin: 0;
  margin-bottom: 13px;
  line-height: 1.33;
}

.checkoutSectionHeader {
  color: #461d58;
}

/* DROPDOOWN HEADER ROW */
.dropDownRow {
  display: flex;
}

.dropDownRow > div:first-of-type {
  margin-right: 24px;
}

.sectionSubHeader {
  font-size: 14px;
  margin-bottom: 6px;
}

.selectionContainer {
  padding: 10px 16px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.itemDetailsContainer > img {
  margin-right: 10px;
  position: relative;
  top: -8px;
}

.itemDetailsContainer > div > p,
.itemDetailsContainer > div > h4 {
  margin: 0;
}

.itemDetailsContainer > div > p {
  font-size: 12px;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
  color: #966826;
}

.itemDetailsContainer > div > h4 {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  line-height: 1.33;
  color: #461d58;
}

.selectionButton {
  width: 100%;
  margin: 64px 0 15px;
  height: 57px;
}

.selectionMinor,
.selectionAction {
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #ce4396;
  font-size: 12px;
}

.selectionMinor {
  display: block;
  margin-top: -8px;
  margin-bottom: 10px;
}

.selectionAction {
  cursor: pointer;
  text-decoration: underline;
}

#editAddress {
  display: flex;
  justify-content: flex-end;
}

/* DISCOUNT STYLES */

.discountContainer {
  margin-top: 30px;
}

.disableInput {
  /* background-color: #fcf7ef !important; */
}

.disableInput > div > button {
  /* background-color: #dab783 !important; */
  /* color: #9e660e !important; */
  max-height: 30px;
  padding: 0;
}

/* COST STYLES */

.editButton {
  width: 120px;
  display: inline-block;
  float: right;
}

.editButton div {
  box-shadow: none;
}

.editButton p {
  width: 120px;
  margin: 2px 0 !important;
  font-size: 15px;
  text-align: center;
}

.profile__optIn {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 20px;
}

.profile__optIn > div {
  margin: 0;
  font-family: 'Filson Pro Regular';
  font-size: 14px;
  font-weight: bold;
}

.profile__optIn > div > p {
  margin: 0;
  padding: 0;
}

.profile__optIn > div > p > a {
  margin: 0;
  color: #1B5FC1 !important;
  font-family: 'Filson Pro Regular';
  font-size: 14px;
  font-weight: bold;
}
@media (min-width: 900px) {
  .costDetails {
    /* margin: 63px 0 0 0; */
  }
}

.costDetails > h3 {
  font-size: 16px;
  font-family: 'Filson Pro Bold';
  font-weight: 700;
  line-height: 20px;
  /* color: #461d58; */
  /* background-color: #f6eddf; */
}

.costDetails .editButton > span {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Filson Pro Bold';
  font-weight: 700;
  cursor: pointer;
  color: #983992;
  text-align: right;
  float: right;
}

.totalsContainer {
  position: relative;
}

.lineCost {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 10px;
}

.lineCost > p {
  margin: 0 5px 1px 0;
}

.lineCost > p:last-child {
  font-family: 'Filson Pro Bold';
}

.breakdownItem {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 10px;
  margin-left: 15px;
}

.breakdownItem > div > p {
  margin: 0 5px 5px 0;
}

.breakdownItem > div > p:last-child {
  font-family: 'Filson Pro Regular';
}

.products {
  font-family: 'Filson Pro Regular';
}

.totalsSection {
  /* background-color: #f6eddf; */
}

.totalsSection:last-of-type {
  margin-bottom: 0;
}

#total {
  padding-bottom: 15px;
}

#total > div {
  font-family: 'Filson Pro Bold';
}

/* ACTIONS SECTION */
.actionsSection > h3, .cancelPolicy > h3 {
  margin: 0;
  font-family: 'Filson Pro Bold';
  font-weight: 425;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
}

.actionsSection > p, .cancelPolicy > p {
  font-size: 12px;
  margin: 0;
  font-family: 'Filson Pro Regular';
  line-height: 1.33;
}
.actionsSection a{
  color: #E01766 !important;
}

.actionsSection > div {
  margin: 15px 15px 30px;
}

.actionsSection > .buttonContainer {
  width: auto !important;
}

.floatButtonContainer {
  display: none;
  text-align: center;
  z-index: 11;
}

.hideWebsiteButton {
  display: block;
  text-align: center;
  z-index: 99999;
}

.cancelPolicy {
  margin-bottom: 5px !important;
}

/* RADIO OPTIONS STYLES */

.optionsContainer > div {
  font-family: 'Filson Pro Regular' !important;
  justify-content: space-between;
}

.optionsContainer {
  display: flex;
  margin-top: 10px;
}

.optionsContainer > div {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  /* border: solid 1px #d5b88a;
  background-color: #fffdfb; */
  margin-right: 6px;
  display: flex;
  /* color: #461d58; */
  justify-content: center !important;
  align-items: center;
}

.optionsContainer > div:last-of-type {
  height: 58px;
  margin-right: 0;
}

.chosen {
  /* border: 1px solid #f3c244 !important;
  background-color: #f3c244 !important; */
}

.labelHeader {
  margin-top: 30px !important;
}

/* SECONDARY RENDERING STYLES */
.secondaryAddressContainer {
  position: relative;
}

.contactInfoContainer {
  width: 100%;
}

.createContactLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 40%;
  left: 45%;
}

.paymentsLoaderWrapper {
  position: absolute;
  z-index: 120 !important;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
}

.secondaryAddressContainer > form > p,
.secondaryAddressContainer > form > span {
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #461d58;
  margin: 0;
}

.secondaryInputWrapper {
  font-size: 12px;
}

.secondaryAddressContainer .secondaryInputWrapper {
  width: 100%;
}

.secondaryAddressContainer .secondaryInputWrapper > div > input,
.secondaryInputRow,
.secondaryInputWrapper > div > input {
  
}

.secondaryAddressContainer .secondaryInputWrapper > div:first-of-type {

}

.secondaryAddressContainer .secondaryInputRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -7px;
}

.secondaryInputRow > div {
  width: 50%;
}

.secondaryAddressContainer .secondaryInputRow > div:first-of-type {
  margin-right: 20px;
}

.secondaryButtonContainer {
  margin-top: 60px;
  margin-bottom: 10px;
  width: 100%;
}

.secondaryAddressContainer #deleteOption {
  display: block;
  text-align: center;
  margin: 50px auto 15px;
  cursor: pointer;
}

.secondaryAddressContainer .selectionMinor {
  font-size: 12px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #ce4396;
}

.zipContainer {
  margin-top: -6px;
}

.billingZipContainer {
  margin-top: -10px;
}

.pickupContactsContainer {
  margin-bottom: 0;
  margin-top: 30px;
}

.returnLineCostDetails summary {
  display: flex;
  align-items: center;
  color: #966826;
  cursor: pointer;
  outline: none;
  line-height: 1.5;
  padding: 10px 10px;
}

.returnLineCostDetails > div {
  font-size: 14px;
  padding-left: 25px;
}

.returnLineCostDetails > div > p {
  font-family: 'Filson Pro Regular' !important;
}

.returnLineCostDetails > div:nth-of-type(1) {
  margin-top: 5px;
}

.returnLineCostDetails summary p {
  margin: 0;
}

.returnLineCostDetails summary > p:last-child {
  flex-grow: 1;
  text-align: right;
  margin: 0 5px 5px 0;
}
.shippingAddressContent{
  position: relative;
  width: 100%;
}
.shippingAddressLoader{
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
}

.deliveryNotesSubTitle {
  width: 150px;
  white-space: pre;
}
.extraTaxSection {
  background-color: #f6eddf;
  font-family: 'Filson Pro Bold';
  margin-top: -15px;
  padding-bottom: 15px;
}
/* DESKTOP CAROUSEL STYLES */

.carouselWrapper {
  margin-top: 15px;
}

.carouselHeader > p {
  margin: 0;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #966826;
  position: relative;
  top: 20px;
}

.carouselDots {
  top: 35px;
  left: 75px;
  z-index: 10000;
  display: flex;
  align-items: center;
  position: relative;
}

.carouselDots > img {
  display: none;
}

.carouselDots > .dot {
  cursor: pointer;
  width: 7px;
  height: 7px;
  background-color: rgba(213, 184, 137, 0.8);
  border-radius: 100px;
  margin-right: 10px;
}

.carouselDots > .activeDot {
  background-color: #f3c244 !important;
}

.carouselSlide {
  display: flex;
  align-items: center;
  width: 90%;
  padding: 50px 0px;
}

.carouselSlide > div {
  margin: 0 !important;
  margin-right: 64px;
}

.carouselSlide > div:last-child {
  margin-right: 0 !important;
}

.arrow {
  position: relative;
  top: 10px;
  cursor: pointer !important;
  transform: rotate(180deg);
}

.arrowRight {
  transform: rotate(360deg);
}

@media (max-width: 1300px) {
  .pageWrapper {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .pageWrapper {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .detailsContainer {
    /* margin-right: 0; */
  }

  /* COLUMN STYLES */
  .columnsContainer {
    /* flex-direction: column; */
  }

  .columnsContainer > div {
    /* width: 100%; */
  }

  .pageContainer > h3 {
    /* margin: 0;
    margin-bottom: 10px; */
  }

  .dropDownRow {
    flex-direction: column;
  }

  .dropDownRow > div:first-of-type {
    margin-bottom: 10px;
  }

  .pageContainer > h3 > span > img {
    /* position: relative;
    left: 0;
    top: 2px;
    margin-right: 15px; */
  }

  .totalsContainer {
    border: none;
    padding: 0;
  }

  .floatButtonContainer {
    margin-top: 40px !important;
    position: sticky;
    margin-top: 0;
    bottom: 20px;
    display: block;
  }

  .buttonContainer {
    margin-top: 40px !important;
    position: sticky;
    bottom: 20px;
  }

  .hideMobileButton {
    
  }
  
  .hideWebsiteButton {
    display: none;
  }
}

@media (max-width:455px){
  .secondaryInputRow{
    flex-wrap:wrap;
    justify-content: flex-start !important;
  }

  .secondaryInputRow > div {
    width: 100%;
  }
}
