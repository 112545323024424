.pageContainerContent > img {
  position: fixed;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
}
.loaderBlock {
  height: 430px;
}

.floatButtonContainer {
  position: fixed;
  margin-top: 50px;
  bottom: 20px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: none;
  z-index: 99999;
}


@media (max-width: 700px) {

  .floatButtonContainer {
    display: block;
  }
}