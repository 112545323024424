.smileRatingContainer {
    display: flex;
    align-items: center;
    font-family: 'Filson Pro Bold';
    padding: 15px 0;
}
.smileRatingContainer > div {
    padding-right: 10px;
}
.smileRatingContainer > div:last-of-type {
    padding-right: 0;
}
.smileRatingContainer > div > img {
    width: 42px;
    cursor: pointer;
}

.smileRatingWidgetContainer .submitButton {
    width: 100%;
    margin: 20px 0;
}

.smileRatingWidgetContainer .commentInputBlock > label {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0;
    font-family: 'Filson Pro Bold';
}

.smileRatingWidgetContainer .commentInputBlock > textarea {
    display: block;
    width: 100%;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 5px 10px;
    min-height: 100px;
    font-family: 'Filson Pro Regular';
}

.smileRatingWidgetContainer .commentInputBlock > textarea::placeholder {
    font-size: 14px;
    line-height: 18px;
    color: rgb(70, 29, 88);
    opacity: .8;
    font-family: 'Filson Pro Regular';
}
.tagSelector {
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-family: 'Filson Pro Regular';
    border-bottom: 1px solid #E5E5E5;
}
.errorMessage {
    color: red;
    font-family: 'Filson Pro Regular';
    font-size: 14px;
    line-height: 18px;
    padding: 5px 0;
}
.loaderContainer {
    padding: 20px 0;
}
