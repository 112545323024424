
/* For some reason the first class of this file does not compile in production builds. This .thisisunused class is a hack
to escape that. Possibly a naming error somewhere?? */
.thisisunused {
  display: block;
}

.newsLetterContainerSuccess {
  display: block;
  margin-top: 10px;
  font-family: 'Filson Pro Bold';
  color: #ce4396;
}

.callOutContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 47px;
}

.callOutWrapper {
  height: 300px;
  background-image:url("./imgs/shipping-banner.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shippingCallOutWrapper {
  background-image: url('./imgs/shipping-banner.png');
}

.leftChildWrapper {
  margin-top: 40px;
}

.leftChildWrapper > div > h1 {
  font-family: 'Congenial Black';
  font-size: 42px;
  color: white;
  margin-bottom: 14px;
}

.leftChildWrapper > div > p {
  font-family: 'Filson Pro Regular';
  font-size: 18px;
  line-height: 1.33;
  color: #d5b88a;
}

.leftChildWrapper > div > div > button {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  line-height: 1.33;
  width: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
}

.leftChildWrapper > div > div > button:last-of-type {
  color: #fffdfb;
  width: auto;
  padding: 0;
}

.leftChildWrapper > div > div > button:first-of-type {
  padding: 10px 14px;
  background-color: #f3c244;
  border-radius: 22.5px;
  font-size: 18px;
  margin-right: 16px;
  color: #1a0021;
}


/* NEWS LETTER STYLES */

.callOutWrapperNewsLetter {
  background-image: url('./imgs/newsletter-bg.png');
}

.newsLetterContainer {
  position: relative;
}

.newsLetterContainer > h1 {
  font-family: 'Congenial Black';
  color: #fffdfb;
  font-size: 42px;
  line-height: 1.14;
  margin-bottom: 22px;
}

.newsLetterContainer > div {
  width: 100%;
}

.newsLetterContainer > div > div {
  width: 540px;
  z-index: 100;
}

.newsLetterContainer > div > div > img {
  display: none;
}

.newsLetterContainer > div > div > input {
  z-index: 2;
  margin-left: 20px;
}

.newsLetterContainer > img {
  position: absolute;
  bottom: -69px;
  right: 0;
  height: 300px;
}

/* ISDOWNLOAD STYLES */

.downloadsContainer, .downloadsContainer > a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.downloadsContainer > a {
  color: #fffdfb;
  font-family: 'Filson Pro Bold';
  text-decoration: none;
  height: 50px;
  margin-right: 35px;
  width: 180px !important;
}

.downloadsContainer > a > img {
  height: 52px;
}

/* CONDITIONAL RENDERING STYLES */
.newsLetterContainerError > img {
  bottom: -57px !important;
}

#downloadAssetContainer > img {
  position: relative;
  top: -50px;
  right: 100px;
  height: 400px;
}

@media (max-width: 1100px) {
  #downloadAssetContainer > img {
    right: 50px;
  }
}

@media (max-width: 1000px) {
  #downloadAssetContainer > img {
    right: 25px;
  }
}

@media (max-width:800px) {

  #downloadAssetContainer {
    display: flex;
    justify-content: center;
  }

  #downloadAssetContainer > img {
    top: 0;
    margin-top: 50px;
  }

  .callOutWrapperDownload > .callOutContainer > .leftChildWrapper {
    position: relative;
    width: 90%;
  }

  .downloadsContainer {
    margin-top: 30px;
  }

  .downloadsContainer > a {
    width: 200px;
  }

  /* REGULAR CALLOUT STYLES */


  .callOutWrapperDefault {
    padding-top: 50px;
    height: auto;
    background-image: url('./imgs/default-bg-mobile.png');
  }

  .callOutContainer {
    flex-direction: column;
    align-items: normal;
  }

  .leftChildWrapper {
    width: 80%;
  }

  .leftChildWrapper > div > h1 {
    margin-bottom: 8px;
  }

  /* NEWSLETTER STYLES */

  .newsLetterContainer > div > div, .newsLetterContainer {
    width: 100%
  }

  .newsLetterContainer {
    padding: 0 28px;
  }

  .newsLetterContainer > div > div > input {
    margin-left: 10px;
  }

  .newsLetterContainer > h1 {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1.14;
  }

  .newsLetterContainer > img {
    bottom: -91px;
  }
  
  /* CONDITIONAL RENDERING STYLES */
  .newsLetterContainerError > img {
    bottom: -76px !important;
  }

}

@media (max-width: 500px) {

  .callOutWrapperDownload > .callOutContainer > .leftChildWrapper {
    width: 100%;
    top: 160px;
  }
  
  .leftChildWrapper {
    width: 90%;
  }

  .leftChildWrapper > div > h1 {
    font-family: 'Congenial Black';
    font-size: 36px;
    color: white;
    margin-bottom: 14px;
  }

  .leftChildWrapper > div > div > button:first-of-type {
    padding: 10px 14px;
    font-size: 16px;
    margin-right: 10px;
    color: #1a0021;
  }

  .leftChildWrapper > div > div > button:last-of-type {
    font-size: 16px;
  }

  .downloadsContainer {
    margin-bottom: 30px;
  }
  .downloadsContainer > a {
    margin-right: 10px;
  }
  .downloadsContainer > a > img {
    height: auto;
    width: 100%;
  }

  .callOutWrapperDownload {
    background-image: url('./imgs/download-mobile-bg.png') !important;
    height: 700px !important;
  }
  #downloadAssetContainer {
    display: none;
  }

}

@media (max-width: 400px) {
  .newsLetterContainer {
    padding: 0 20px;
  }
  .callOutContainer {
    padding: 0 15px;
  }
  .shippingCallOutWrapper {
    padding:0 15px;
  }
}

@media (max-width: 330px) {
  .newsLetterContainer {
    padding: 0 2px;
  }
}
