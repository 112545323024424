.productCardWrapper {
  margin-bottom: 125px;
  width: 100%;
  position: relative;
}

.productCardLocked  * {
  pointer-events: none;
}

.productCardOverlay,
.productCardOverlaySmall {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(188, 151, 54, 0.7);
  color: white;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding: 20px;
  border-radius: 22px;
  cursor: pointer;
  background-image: url('./imgs/cookiemagic@2x.png');
  background-repeat: no-repeat;
  background-size: 30%;
  opacity: 0.3;
  /* background-position: 90% 10%; */
  pointer-events: initial;
}

.productCardOverlaySmall {
  border-radius: 8px;
}

.productCardOverlayAlternate {
  /* background-position: 90% 90%; */
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

@media (max-width: 700px) {
  .productCardWrapper {
    margin: 0;
  }
}

@media (max-width: 400px) {
  .productCardWrapper {
    margin: 0;
    padding: 0;
  }
}

.widgetWrapper {
  width: 460px;
  margin: 0 auto;
  padding: 57px 0 30px;
}

.beBackSoon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 75px;
  width: 75px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
