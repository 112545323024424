.doughProFreeCookie {
  background-image: url('./imgs/bg1.png');
  background-size: cover;
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: auto;
  padding-bottom: 400px;
}

.doughProFreeCookieActivated {
  padding-bottom: 100px;
}

.loader {
  padding: 50px 0;
}

.chocolateChunkImg {
  position: absolute;
  bottom: 0;
  height: 700px;
  width: 100%;
  background-size: cover;
  background-position: 52% 350px;
  background-repeat: no-repeat;
  z-index: 1;
}

.buttonContainer {
  width: 110px !important;
  height: 40px;
  box-shadow: 2px 3px 0 1px #BC945A;
}

.logo {
  display: flex;
  justify-content: center;
}

.freeDoughDesktopBody {
  display: flex;
  justify-content: center;
}
.desktopLogo {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.desktopLogo img {
  width: 600px;
}

.freeCookieDesktopBody{
  display:flex;
  width:75%;
  margin-top:5%;
  margin-bottom:3%;
}

.freeCookieDesktopBody h1{
  font-family: Congenial Black;
  color: #fff;
  font-size: 38px;
}
.freeCookieDescItem {
  display: flex;
  align-items: center;
}

.freeCookieDescItem p {
  font-size: 17px;
  color: #fff;
  font-family: Filson Pro Bold;
}

.starLeft {
margin-right:15px;
}

.starLeft img {
  width: 20px;
}

.freeCookieDescription {
  padding-right:30px;
  width: 33%;
}

.freeCookiePurchase p{
  padding-top:10px;
  font-family: Filson Pro Regular;
  color: #fff;
  font-size: 12px;
}
.freeCookieChocChunk {
  width: 67%;
}
.freeCookieChocChunk img {
  width: 85%;
  transform: rotate(280deg);
}

.freeCookieDesktopButtonContainer {
  margin-top:15px;
  width: 130px;
  height: 42px;
  box-shadow: 2px 3px 0 1px #BC945A;
}

.freeCookieDesktopButtonContainer p{
  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
}
.logo img {
  width: 600px;
  max-width: 100%;
  max-height: 196px;
  margin: 0 auto;
  align-self: center;
}

.doughProFreeCookie h2 {
  font-family: 'Congenial Black';
  color: #461d58;
  text-align: center;
}

.doughProFreeCookie p {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
}

@media only screen and (max-width: 1600px){
  .freeCookieChocChunk{
    width:64%;
  }
  .freeCookieDescription{
    width: 36%;
  }
}

@media only screen and (max-width: 1450px){
  .freeCookieChocChunk{
    width:60%;
  }
  .freeCookieDescription{
    width: 40%;
  }
}

@media only screen and (max-width: 1100px){
  .freeCookieDesktopBody{
    width:85%;
    margin-bottom:5%;
  }

  .freeCookieChocChunk{
    width:55%;
  }
  .freeCookieDescription{
    width: 45%;
  }
}

@media only screen and (max-width: 900px){
  .freeCookieDesktopBody{
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 820px){
  .freeCookieDesktopBody h1 {
    font-size:35px
  }
}

@media only screen and (max-width: 700px) {
  .logo {
    padding: 25px 0;
  }

  .logo img {
    max-width: 300px;
    width: 100%;
  }
}

/* Activation Screen */
.activationScreen {
  padding: 15px 0;
}

.activationScreenContent {
  padding: 0 15px;
}

.activationScreenContent > p {
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
}

.activationScreen h3,
.countdownScreenContent h3,
.expirationScreenContent h3 {
  font-family: 'Congenial Black';
  color: #ffffff;
  text-align: center;
  font-size: 30px;
  line-height: 1.5;
}

.activationScreenActions {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.activationScreenActions .buttonContainer {
  margin: 0 15px;
}

.activationScreenActions button[disabled]{
  background-color: #d5b88a;
  box-shadow: none !important;
  color: #966826;
}

/* Countdown Screen */
.countdownScreenActive {
  font-family: 'Congenial Black';
  font-size: 16px;
  text-align: center;
  padding: 10px 0;
}

.countdownScreenContent {
  padding: 0 15px;
}

.countdownScreenContent h3  {
  margin: 0;
}

.countdownScreenContent p {
  color: #ffffff;
  line-height: 1.5;
}

.countdownScreenCookieTimer {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 30px auto;
}

.countdownScreenCookieTimer img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 100%;
}

.countdownScreenCookieTimerClock {
  position: absolute;
  right: 10px;
  top: 10px;
}

.countdownScreenCookieTimerClock span {
  font-family: 'Congenial Black';
  font-size: 70px;
  color: white;
}

.countdownScreenCookieTimerClock > div:first-child {
  margin-bottom: 0;
}

.countdownScreenCookieTimerClock > div:last-child {
  font-size: 16px;
  text-transform: uppercase;
  text-align: right;
  color: #ffffff;
}

/* Expiration Screen */

.expirationScreenExpired {
  font-family: 'Congenial Black';
  font-size: 24px;
  color: #ff0000;
  text-align: center;
  padding: 10px 0;
}

.expirationScreenContent {
  padding: 0 15px;
}

.expirationScreenContent h3  {
  margin: 0;
}

.expirationScreenContent p {
  color: #ffffff;
  line-height: 1.5;
}
