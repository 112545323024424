.switchContainer {
  /* border: 1px solid #E01766; */
  width: 59px;
  height: 28px;
  border-radius: 22.5px;
  cursor: pointer;
  display: inline-block;
  padding: 0px;
  background-color: #745881 !important;
}

.switchContainerOn {
  background-color: #461D58 !important;
}

.switchContainer > .switchToggle {
  border: 1px solid #461D58;
}

.switchToggle {
  background-color:#FFF;
  width: 27px;
  height: 27px;
  left: 0;
  border-radius: 100px;
  position: relative;
  transition: left .2s ease-in-out;
}

.switchOn {
  background: #FFF;
}

.switchOn {
  left: 30px;
}

.switchContainerDisabled {
  opacity: .5;
}
