.categoryFilterWrapper {
  /* width: 1300px; */
}

.categoryFilterContainer {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.category {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  padding: 15px 5px 13px 5px;
}

.category > img {
  height: 25px;
  margin-right: 6px;
}

.category > p {
  margin: 0;
  font-size: 18px;
}

.customIcon > img {
  height: 20px;
}


/* ACTION STYLES */

.isChosen {
  border-bottom: 3px solid #F6F3F7;
  border-radius: 0;  
}

.isChosen > p {
  
}

@media (max-width: 900px) {
  .categoryFilterWrapper {
    margin: 0 auto;
  }
  .category > p{
    text-align: center;
  }

  .customIcon > img {
    position: relative;
    top: 3px;
    height: 20px;
  }
  
  .customIcon > p {
    /* margin-top: 15px !important; */
  }
  
  .category {
    flex-direction: column;
    height: 52px;
  }

  /* .category > p {
    margin-top: 5px;
    font-size: 14px;
  } */

  /* MOBILE DARK THEME */
  .mobileDarkTheme  > .categoryFilterContainer {
    border-radius: 0;
  }

  .mobileDarkTheme > .categoryFilterContainer > .category {
    margin: 12px 0;
    height: 52px;
  }

  .mobileDarkTheme > .categoryFilterContainer > .category > p {
    /* color: #fffdfb; */
  }

}

@media (max-width:768px){
  .categoryFilterWrapper{
    width:max-content;
  }

  .categoryFilterContainer{
    padding-left:10px;
    justify-content:flex-start;
  }

  .category > p{
    text-align:center;
  }
}
