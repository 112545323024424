.errorMessage {
    color: #d21c38;
    font-family: 'Filson Pro Bold';
}

.cmsContentEditModalContent {
    padding: 35px;
    width: 100%;
}

.cmsContentEditModalContent > h3 {
    font-family: 'Filson Pro Bold';
    color: #461d58;
    font-size: 21px;
}

.cmsContentEditModalActions {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: -36px;
}

.cmsContentEditModalChildren > button {
    width: 120px;
}

.cmsContentEditModalActions > button {
    margin-top: 50px;
    margin-left: 10px;
    width: auto !important;
    padding: 10px 14px;
    font-size: 16px;
    font-family: 'Filson Pro Bold';
    background-color: #f3c244;
    color: #461d58;
}

.cmsContentEditModalContentChildrenItems {
    height: 200px;
    overflow-y: scroll;
}

.cmsContentEditModalContentChildrenItem {
    > div:last-child {
        display: flex;

        button {
            width: 80px;
            font-size: 14px;
            line-height: 1;
        }
    }
}