.productsHeaderWrapper {
  background: url('./imgs/promotional_bg.png');
  background-size: cover;
  height: 100%;

}

.productsHeaderWrapperImg {
  background-image: url('./imgs/waves.png');
  background-size: unset;
  background-repeat: repeat;
}

.productsHeaderContainer {
  display: block;
  padding: 0;
  margin-bottom: 1px;
}

.productHeaderBanner {
  position: relative;
  min-height: 64px;
  width: 100%;
}

.mobileAddressFinder {
  cursor: pointer;
  display: none;
  padding: 28px;
}

.mobileAddressFinder > p {
  margin: 0;
  font-size: 14px;
  font-family: 'Filson Pro Regular';
  line-height: 1.43;
  color: #1a0021;
}

.mobileAddressFinder > h3 {
  margin: 0;
  font-size: 18px;
  font-family: 'Filson Pro Bold';
  line-height: 1.33;
  color: #461d58;
}

.mobileAddressFinder > h3 > span > img {
  height: 8px;
  margin-left: 5px;
}

.widgetWrapper {
  margin-top: 35px;
  display: none;
}

.categoriesWrapper {
  overflow-x: scroll;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  /* box-shadow: 0 2px 10px 0 rgba(213, 184, 138, 0.3); */
}

.categoriesWrapper::-webkit-scrollbar {
  display:none
}

/* PROMOTIONAL STYLES */

.productHeaderBannerPromotional {
  background-color: #ce4396;
  color: #fffdfb;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.rotateDown > img {
  transform: rotate(360deg);
}
.dropDownIcon > img {
  transform: rotate(-90deg);
}
.productHeaderBannerPromotional > p {
  margin: 0;
  align-self: center;
  font-family: 'Filson Pro Bold';
  font-size: 14px;
}

.productHeaderBannerPromotional > p > a {
  color: inherit;
  font-size: 12px;
  margin-left: 10px;
}

#promoAsset {
  height: 345px;
  position: relative;
}

#promoBannerAsset {
  position: absolute;
  right: 0;
  height: inherit
}

@media (max-width: 900px) {

  /* ORDER AGAIN STYLES */
  .productsHeaderWrapperOrderAgain {
    height: 700px;
  }

  .productsHeaderContainer {
    padding-top: 25px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .mobileAddressFinder {
    display: block;
  }

  .widgetWrapper {
    position: absolute;
    z-index: 99999;
    display: block;
    max-width:414px;
    box-sizing: border-box;
    box-shadow: 0 7px 13px 10px rgba(56, 56, 56, 0.10);
    background-color: #fffdfb;
    border-radius: 20px;
    padding: 25px 0;
    margin: 28px 5px 0 28px;

  }

  /* PROMOTIONAL STYLES */
  #promoAsset {
    justify-self: flex-end;
    left: 30px;
    margin-bottom: 30px;
    top: 0;
  }
}



@media (max-width: 500px) {

  /* ORDER AGAIN STYLES */
  .productsHeaderWrapperOrderAgain {
    height: 625px;
  }
  

  .productsHeaderContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }
  
  /* PROMOTIONAL STYLES */

  #promoAsset {
    height: 275px;
  }

  .productHeaderBannerPromotional {
    height: 80px;
  }

  .productHeaderBannerPromotional > p {
    padding: 20px;
  }

  #promoBannerAsset {
    position: relative;
  }
}


.heroBannerContainer {
  display: block;
  width: 100%;
  /* background-color: #461d58; */
  min-height: 450px;
}

@media (max-width: 450px) {
  /* ORDER AGAIN STYLES */
  .productsHeaderWrapperOrderAgain {
    height: 540px;
  }
  .productsHeaderContainer {
    padding-bottom: 0;
  }
}

@media (min-width:901px) and (max-width: 1037px) {
  #promoAsset {
    top: 50px;
  }
}
