.productImage>p>img {
    width: 380px;
    height: 324px;
    object-fit: contain;
}

@media only screen and (max-width: 425px) {
    .productImage>p>img {
        width: 136px;
        height: 136px;
        object-fit: contain;
    }
}