.referralCodeContainer {
    margin-bottom: 30px;
}

.referralCodeContainer > div > h4 {
    font-family: 'Filson Pro Bold';
    font-size: 18px;
}

.referralCodeContainer > div > p {
    font-family: 'Filson Pro Regular';
    margin: 0 0 30px 0;
    font-size: 14px;
    line-height: 16px;
    font-weight: 425;
}
.referralCodeBlock {
    border: 1px solid;
    border-radius: 20px;
}
.referralCode {
    display: flex;
    margin: 20px;
    align-items: center;
}
.referralCode .referralCodeValue {
    width: auto;
    flex-grow: 1;
}
.referralCode .referralCodeButton {
    width: auto;
}
.referralCodeButtonIcon {
    transform: scaleX(-1);
}
.referralCode .referralCodeButton i {
    margin-right: 5px;
}

@media (max-width: 800px) {
    .referralCode {
        display: block;
        margin: 20px;
        align-items: center;
    }
    .referralCode .referralCodeButton {
        width: 100%;
    }
    .referralCodeButton > div {
        width: 100%;
    }
    .referralCodeValue {
        width: 100%;
        padding: 10px 0;
    }
}