.pageBarcodeContainer {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageBarcodeContainer > div {
  text-align: center;
  padding: 30px 0 10px 0;
  width: 100%;
}

.pageBarcodeContainer > div > svg {
  width: 225px !important;
}

.pageBarcodeContainer > div > p {
  font-family: 'Filson Pro Bold';
  font-size: 14px;
  margin: 15px 0 0;
}

.pageDetailsContainer {
  width: 100%;
  margin: 100px auto;
}

.pageDetailsSection {
  margin-bottom: 20px;
}

.pageDetailsSection > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageDetailsSection > div > h4 {
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  margin-bottom: 10px;
}

.pageDetailsSection > div > a, .pageDetailsSection > .inputRow > div > a {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Filson Pro bold';
  font-weight: 700;
  cursor: pointer;
  color: #983992;
}

.pageDetailsSection > p {
  width: 100%;
  font-family: 'Filson Pro Regular';
  font-size: 12px;
  line-height: 14px;
}

.pageDetailsSection > p > p > a {
  color: #E01766 !important;
}

.pageDetailsSection > .inputRow {
  margin-bottom: -10px !important;
}

.pageDetailsSection > .inputRow > div {
  width: 50%;
  position: relative;
}

.pageDetailsSection > .inputRow > div > a {
  position: absolute;
  top: auto !important;
  right: 0;
}

.pageDetailsSection > .inputRow > div > div {
  width: 100%;
}

.pageDetailsSection > .inputRow > div:last-child {
  margin-left: 15px;
}

.pageDetailsContainer > .inputRow > a {
  display: block;
  position: absolute !important;
}

.settingsContainer {
  width: 100%;
  align-items: center;
  display: flex;
}

.settingsContainer > div:first-child > h3 {
  font-family: 'Filson Pro Bold';
}

.settingsContainer > div > h3 > a {
  color: #461d58;
  font-family: 'Sofia Pro Soft Bold';
  cursor: pointer;
}

.settingsContainer > div:first-child > p {
  margin: 0;
  font-family: 'Filson Pro Regular';
  font-size: 14px;
}

.settingsContainer > div:last-child {
  position: relative;
}

.profile__optIn {
  width: 100%;
  align-items: center;
  display: flex;
  margin-top: 20px;
}

.profile__optIn > div {
  margin: 0;
  font-family: 'Filson Pro Regular';
  font-size: 14px;
  font-weight: bold;
}

.profile__optIn > div > p {
  margin: 0;
  padding: 0;
}

.profile__optIn > div > p > a {
  margin: 0;
  color: #1B5FC1;
  font-family: 'Filson Pro Regular';
  font-size: 14px;
  font-weight: bold;
}

.heroButtonContainer {
  width: 140px;
  height: 48px;
}

.pageActionsContainer {
  width: 100%;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.logoutButtonContainer {
  width: 358px;
  height: 60px;
  margin: 20px auto;
}

.pageActionsContainer > p {
  margin: 70px 0 90px;
  font-family: 'Filson Pro Regular';
  cursor: pointer;
  line-height: 20px;
  font-size: 16px;
  font-weight: 425;
  color: #983992 !important;
}

.loaderWrapper {
  height: 60vh;
  width: 100%;
}

.rewardWrapper {
  top: -170px !important;
}
.error {
  display: block;
  margin-top: 30px;
  font-family: 'Filson Pro Bold';
  color: #d21c38;
  text-align: center;

}

/* Profile opt-in */
.optInContainer > h3 {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 16px;
  line-height: 20px;
}
.optInContainer > div {
  justify-content: left;
}

.optInOptions{
  display: block!important;
  nargin-bottom: 40px;
}

.optInOptions > div {
  margin-bottom: 5px;
}

.optInOptions > div > div:first-child {
  margin-right: 15px;
}

.optInOptions > div > div > img {
  vertical-align: text-top;
}

.optInOptions > div > span {
  font-family: "Filson Pro Regular";
  color: #461D58;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;  
  vertical-align: top;
}

.optInOptions > p {
  margin: -7px 0 5px 50px;
  color: #745881;
  font-family: 'Filson Pro soft Regular';
  font-size: 16px;
  line-height: 20px;
  font-weight: 425;
}

.disclaimerNotes {
  font-family: 'Filson Pro Regular';
  font-size: 12px;
  color: #745881;
  padding-bottom: 10px;
}

.disclaimerSMSNotes {
  font-family: 'Filson Pro Regular';
  font-size: 12px;
  color: #745881;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .pageDetailsContainer {
    width: 80%;
  }
  .optInContainer > h3 {
    width: 38%;
  }
}


@media (max-width: 1000px) {
  .pageDetailsContainer {
    width: 90%;
  }
  .optInContainer > h3 {
    width: 22%;
  }
}

@media (max-width: 900px) {
  .pageWrapper {
    position: relative;
    top: -10px;
  }

  .rewardWrapper {
    top: -115px !important;
  }
  .optInContainer > h3 {
    width: 28%;
  }
}

@media (max-width: 800px) {

  .pageDetailsSection > .inputRow > div {
    width: 100%;
  }

  .pageDetailsSection > .inputRow > div:last-child {
    margin-left: 0;
  }

  .pageDetailsSection > .inputRow {
    display: block;
  }

  .settingsContainer {
    width: 100%;
  }

  .settingsContainer > div:first-child > h3 {
    font-size: 16px;
  }

  .settingsContainer > div:first-child > p {
    font-size: 12px;
  }

  .heroButtonContainer {
    width: 140px !important
  }

  .logoutButtonContainer {
    width: 90% !important;
  }

  .pageActionsContainer > p {
    font-size: 14px;
  }
  .optInContainer > h3 {
    width: 85%;
  }
}

@media (max-width: 400px)
{
  .rewardWrapper {
    top: -100px !important;
  }
}
