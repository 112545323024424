.orderTotalsWidgetContainer {
    position: relative;
    box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.18);

    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 12px;
    margin: 24px 0;

    color: #461D58;

    font-family: "Filson Pro Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 425;
    line-height: 20px;
}

.orderTotalItem {
    display: flex;
    padding: 20px 0;
}

.orderTotalItem .orderTotalIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderTotalItem .orderTotalIcon img {
    width: 24px;
}
.orderTotalItem .orderTotalIconBig img {
    width: 40px;
}

.orderTotalItem.orderTotalsWidgetHeader {
    padding-bottom: 20px;
    padding-top: 0;
}

.orderTotalItem.orderTotalsWidgetHeader .orderTotalContentTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.orderTotalItem .orderTotalContentContainer {
    padding: 0 10px;
}
.orderTotalItem .orderTotalContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.borderBottom {
    border-bottom: 1px solid #E5E5E5;
}
.totalValueItem {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 14px;
    line-height: 16px;
}
.totalValueItem.totalItemTotal {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #E5E5E5;
}
.totalValueItem .orderTotalValuePrice {
    text-align: right;
}
.card {
    align-items: center;
}
.card .orderTotalContentContainer {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    flex: 1;
}
.card .cardInfo {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    flex: 0 0 auto;
}