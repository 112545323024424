.modalWrapper {
  z-index: 99999999999999;
  margin: 0;
  padding: 0;
  background-color: rgba(26,0,3,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
}

.modalContainer {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fffdfb;
  box-shadow:  8px 5px 0 1px #bc945a;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
}

.modalContainer > div {
  max-width: 100%;
  width: 100%;
}

.modalContainer > img {
  cursor: pointer;
  position: absolute;
  height: 12px;
  top: 20px;
  right: 20px;
}

.defaultModal {
  padding: 35px 85px 25px 45px;
}

.defaultModal > h3 {
  font-family: 'Filson Pro Bold';
  color: #461d58;
  font-size: 21px;
}

.defaultModal > p {
  font-family: 'Filson Pro Regular';
  color: #966826;
  font-size: 14px;
}

.defaultModal > div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.defaultModal > div > button {
  margin-top: 50px;
  margin-left: 10px;
  width: auto !important;
  padding: 10px 14px;
  font-size: 16px;
  font-family: 'Filson Pro Bold';
  background-color: #f3c244;
  color: #461d58;
}

@media (max-width: 700px) {
  .modalContainer {
    width: 85%;
    height: auto;
    margin: 0 auto;
  }

  .defaultModal > div {
    right: -40px;
  }

  .defaultModal {
    padding-right: 60px;
    padding-left: 25px;
  }

  .defaultModal > div > button {
    margin-top: 20px;
    padding: 10px 15px;
  }

  .defaultModal > div > button:focus {
    outline: none;
  }
}

@media (max-width: 400px) {
  .defaultModal {
    padding-right: 20px;
    padding-left: 20px;
  }
  .defaultModal > div {
    right: 0;
  }

  .modalContainer {
    width: 90%;
    top: 50%
  }
}
.error {
  color: #d21c38;
}

.inputStyles > div:first-child {
  border: 1px solid #d5b88a !important;
}
.selectStyles > div:first-of-type > div:first-child::before {
  content: 'Code' !important;
}
