.paymentWidget h3 {
    font-family: 'Filson Pro Bold';
    font-size: 20px;
    line-height: 1.33;
    color: #461d58;
    border-bottom: 3px solid #bc9459;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 5px;
}

.paymentMethodGroupContainer {
    /* border-bottom: solid 1px #ba935a; */
    /* background-color: #fbf5ee; */
    padding: 16px 24px;
}

.paymentsSubTitle {
    font-family: "Filson Pro Bold";
    font-size: 16px;
    line-height: 1.5;
    color: #461d58;
    margin: 0;
}

.paymentsLoaderWrapper {
    position: absolute;
    z-index: 120 !important;
    top: 50%;
    left: 0;
    right: 0;
  }

/* Grid */
.container {
    width: 100%;
    margin: 0 auto;
  }

.column {
    flex-basis: 100%;
  }

  @media screen and (min-width: 800px) {
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .column {
      flex: 1;
    }
    ._25 {
      flex: 2.5;
    }
    ._5 {
      flex: 5;
    }
  }
  /* Style */


  .column {
    padding: 15px;
    border: 1px solid #666;
    margin: 5px 0;
    background: #ffffff;
  }


  /* RADIO OPTIONS STYLES */

  .optionsContainer {
   display: flex;
   flex-wrap: wrap;
  }

  .optionsContainer [hidden]{
    display:none !important;
  }

  .optionsContainer > div {
    font-family: 'Filson Pro Regular';
    justify-content: space-between;
    cursor: pointer;
    border-radius: 40px;
    background-color: #fffdfb;
    color: #461d58;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    min-width: 108px;
    height: 40px;
    width: 100%;
    margin-bottom:8px;
  }

  .optionsContainer > .option {
    /* border: solid 1px #d5b88a; */
  }

  .optionsContainer > .optionEmbeddedHtml{
    border: none;
}

  .optionsContainer > .chosen {
    border: 1px solid #f3c244 !important;
    background-color: #f3c244 !important;
  }

  .optionsContainer img {
      height: 20px;

  }

  .optionsContainer label {
    margin-left: 8px;

}


.optionsContainer2 {
    display: flex;
    align-items:center;
    flex-flow: wrap;
    margin-bottom:16px;
   }

   .optionsContainer2 > div {
     font-family: 'Filson Pro Regular';
     font-weight: 425;
     justify-content: space-between;
     cursor: pointer;
     border-radius: 40px;
     justify-content: left;
     align-items: flex-start;
     text-align: left;
     display: flex;
     flex-direction: row;
     margin-bottom:16px;
     padding: 12px;
     min-width: 100%;
     height: 44px;
   }

   .optionsContainer2 .chosen {
     /* border: 1px solid #f3c244 !important; */
     /* background-color: #f3c244 !important; */
   }

   .optionsContainer2 img {
       height: 20px;
       margin-right:8px;

   }

 .optionsContainer2 > .option {
    /* border: solid 1px #d5b88a; */
  }

  .optionsContainer2 > .optionEmbeddedHtml{
    border: none;
    background: none;

}
  .optionsContainer2 > .chosen {
    /* border: 1px solid #f3c244 !important;
    background-color: #f3c244 !important; */
  }



 .footerActions {
    font-size: 12px;
    line-height: 1.33;
    font-family: 'Filson Pro Bold';
    text-decoration: underline;
    cursor: pointer;
    color: #ce4396;
    text-align: right;
    padding: 10px 0 10px 0;
 }

 .footerActions.footerActionLeft {
    text-align: left;
 }

 .edit {
    font-family: "Filson Pro Bold";
    color: #966826;
    position: relative;
    margin-top: 4px;
    position: absolute;
    right: 50px;
    display: none;
  }


div > p.errorMessage {
  color: #d21c38;
  font-family: "Filson Pro Bold";
  text-align: center;
}

