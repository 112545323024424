.orderReviewWidgetWrapper {
  /* margin-bottom: 40px; */
}

.orderReviewWidgetContainer {
  /* 
  border: solid 1px #ba935a;
  background-color: #fffdfb;
  box-shadow:  10px 10px 0 1px #bc945a;
  padding: 29px 46px; */
}

/* SHARED STYLES */
.widgetHeader, .widgetFooter { 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widgetHeader > span, .widgetFooter > div:last-of-type > span {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Filson Pro bold';
  font-weight: 700;
  cursor: pointer;
  color: #983992;
}

/* WIDGET HEADER STYLES */
.widgetHeader > p {
  margin: 0;
  font-size: 24px;
  font-family: 'Filson Pro Bold';
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.3px;
}

.giftBoxContainer {
  margin-top: 8px;
}

/* WIDGET BODY STYILES */
.widgetBody {
  /* margin: 20px 0 80px; */
}

.widgetBody > p {
  font-family: 'Filson Pro Regular';
  /* color: #966826;
  margin: 0;
  cursor: pointer;
  margin-left: 25px; */
}

/* WIDGET FOOTER STYLES */
.widgetFooter > div:first-of-type {
  display: flex;
  align-items: center;
}

.widgetFooter > div:first-of-type > span > img {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.widgetFooter > div:first-of-type > span {
  font-family: 'Filson Pro Regular';
  font-weight: 425;
  font-size: 16px;
  line-height: 20px;
} 

.widgetFooter > div:last-of-type > h3 {
  font-size: 24px;
  font-family: 'Filson Pro Bold';
  margin: 0;
  font-weight: normal;
  line-height: 1.17;
  color: #461d58;
  margin-bottom: 5px;
}

.widgetFooter > div:last-of-type {
  display: flex;
  flex-direction: column;
  font-family: 'Filson Pro Bold';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.widgetFooter > div:last-of-type > h3 > span {
  margin: 0 10px;
}

.widgetFooter > div:last-of-type > a {
  text-align: right;
}

@media (max-width: 800px) {
  .orderReviewWidgetContainer {
    padding: 21px;
  }

  /* WIDGET BODY STYLES */
  .widgetBody {
    /* margin-top: 10px; */
  }

  .giftBoxContainer {
    margin-bottom: 33px;
  }

  /* WIDGET FOOTER STYLES */
  .widgetFooter > div:last-of-type > h3 {
    font-size: 18px;
  }

  .widgetFooter {
    align-items: flex-end;
  }

  .widgetFooter > div:last-of-type > a {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .widgetHeader > p {
    font-size: 20px;
  }
}
