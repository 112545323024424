.dropoffImageContainer {
    margin-top: 24px;
}
.dropoffImageContainer .dropoffImageTitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.3px;
    text-align: center;
    padding: 10px 0;
}

.dropoffImageContainer .dropoffImage {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
