.pageWrapper {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.reCaptchaContent{
  margin: 5px 0;
}

.pageContainer {
  width: 50%;
  position: relative;
  top: -100px
}

.pageContainer > h3 {
  font-family: 'Congenial Black';
  font-size: 32px;
  line-height: 1.13;
  margin: 0;
  margin-bottom: 20px;
  color: #461d58;
}

.pageContainer > .inputWrapper {
  width: 100%;
}

.pageContainer > .inputWrapper > div > input {
  width: 70% !important;
}

.pageContainer > .inputWrapper > div > span {
  left: 0;
}

.pageContainer > a {
  font-family: 'Filson Pro Bold';
  font-size: 12px;
  line-height: 1.33;
  color: #ce4396;
}

/* CARDS STYLES */

.cardsWrapper {
  margin-top: 35px;
  position: absolute;
  max-height: 275px;
  width: 100%;
}

.cardContainer, .cardContainer > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardContainer {
  padding: 5px 22px;
  border-radius: 8px;
  border: solid 1px #d5b88a;
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 10px;
}

.cardContainer > p, .cardContainer > div > p:last-of-type {
  font-family: 'Filson Pro Bold';
  color: #461d58;
}

.cardContainer > div > p:first-of-type {
  font-family: 'Filson Pro Regular';
  line-height: 1.43;
  color: #966826;
  margin-right: 13px;
}

/* ERROR STATES */
.error {
  color: #d21c38;
  font-family: 'Filson Pro Bold';
}

@media (max-width: 1250px) {
  .pageContainer {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  .pageContainer {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .pageWrapper {
    margin-top: 60px;
    align-items: flex-start;
  }
  .pageContainer {
    top: 0;
    width: 80%;
  }
}

@media (max-width: 500px) {
  .pageContainer {
    width: 90%;
  }
  .cardsWrapper {
    max-height: 450px;
  }
  .pageContainer > h3 {
    width: 60%;
  }  
}


@media (max-width: 400px) {
  .pageContainer {
    width: 90%;
  }
  .cardsWrapper {
    max-height: 450px;
  }
  .pageContainer > h3 {
    width: 60%;
  }  
}

@media (max-width: 350px) {
  .pageContainer > h3 {
    width: 70%;
  }  
}
